<div
  class="app-aside-item"
  (click)="onClick($event)"
  (keydown.enter)="onEnter($event)"
  [class.app-aside-item--checked]="isChecked"
  [class.app-aside-item--collapsed]="isCollapsed"
>
  <qima-icon
    class="app-aside-item__icon"
    [qimaIconName]="iconName"
  ></qima-icon>
  <span
    *ngIf="!isCollapsed || isMobile"
    class="app-aside-item__label"
    >{{ label }}</span
  >
  <qima-dot
    *ngIf="!isCollapsed && !isMobile"
    class="app-aside-item__dot"
    [style.background-color]="qimaColor.cherry.CHERRY_500"
    [qimaDotSize]="qimaDot.dotSize.SMALL"
  ></qima-dot>
</div>
