<div class="payment-dialog-footer">
  <div class="payment-dialog-footer__description">
    <div>{{ 'PAYMENTS.PAYMENT_AGREE_DESCRIPTION' | translate }}</div>
    <div
      class="link"
      (click)="onTermsAndConditionsClick()"
      (keydown.enter)="onTermsAndConditionsClick()"
    >
      {{ 'PAYMENTS.TERMS_CONDITIONS' | translate | qimaAutolinker }}
    </div>
  </div>
  <div class="payment-dialog-footer__visa-secure">
    <img
      alt="visa secure code"
      [src]="visaSecureCode"
    />
  </div>
  <div class="payment-dialog-footer__description">
    <div>{{ 'PAYMENTS.PAYPAL_ACCOUNT' | translate }}</div>
    <div
      class="link"
      (click)="onPaypalClick()"
      (keydown.enter)="onPaypalClick()"
    >
      {{ 'PAYMENTS.PAY_THROUGH_PAYPAL' | translate | qimaAutolinker }}
    </div>
  </div>
  <div
    *ngIf="isAlipayEnabled"
    class="payment-dialog-footer__description"
  >
    <div>{{ 'PAYMENTS.ALIPAY_ACCOUNT_HOLDERS' | translate }}</div>
    <div class="link">{{ 'PAYMENTS.PAY_THROUGH_ALIPAY_HONG_KONG' | translate | qimaAutolinker }}</div>
  </div>
</div>
