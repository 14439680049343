import { BookFromPreviousOrderModule } from '@aca-new/app/pages/bookings/pages/previous-order-item/components/book-from-previous-order/book-from-previous-order.module';
import { PreviousOrderItemComponent } from '@aca-new/app/pages/bookings/pages/previous-order-item/previous-order-item.component';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { AppServiceTypeFullNameModule } from '@aca-new/app/shared/pipes/service-type-full-name/service-type-full-name.module';
import { ServiceTypeIceCubeStyleModule } from '@aca-new/app/shared/pipes/service-type-ice-cube-style/service-type-ice-cube-style.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaAutoTooltipDirectiveModule, QimaIceCubeModule, QimaSpinnerModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [PreviousOrderItemComponent],
  imports: [
    CommonModule,
    TranslateModule,
    QimaIceCubeModule,
    ServiceTypeIceCubeStyleModule,
    QimaTooltipDirectiveModule,
    QimaSpinnerModule,
    QimaAutoTooltipDirectiveModule,
    BookFromPreviousOrderModule,
    AppServiceTypeFullNameModule,
    TrackByUuidDirective,
  ],
  exports: [PreviousOrderItemComponent],
})
export class PreviousOrderItemModule {}
