<div class="app-previous-order-item__book-previous-order-title">
  {{ 'APP_ASIDE.ASIDE_MENU.BOOK_FROM_PREVIOUS' | translate }}
</div>
<ng-container *ngIf="previousOrders.length">
  <div
    *ngFor="let item of previousOrders | slice: 0 : 2; let index = index"
    appNgForTrackByUuid
    class="app-previous-order-item"
    (click)="onPreviousOrderItemClick(item)"
    [class.app-previous-order-item__book-previous-order-item]="!item.canActivate"
  >
    <qima-ice-cube
      [qimaTooltipText]="item.iceCubeLabel | serviceTypeFullName | translate"
      qimaTooltip
      [qimaIceCubeLabel]="item.iceCubeLabel"
      [qimaIceCubeStyle]="item.iceCubeLabel | serviceTypeIceCubeStyle"
      [qimaIceCubeSize]="iceCubeSize"
    ></qima-ice-cube>
    <div class="app-previous-order-item__order">
      <ng-container *ngTemplateOutlet="product"></ng-container>
      <span class="app-previous-order-item__order-date">{{ item.orderDate }}</span>
    </div>

    <ng-template #product>
      <div
        *ngIf="item.productNames.length === 1"
        class="app-previous-order-item__order__product-name"
        qimaAutoTooltip
        [qimaAutoTooltipStyle]="qimaTooltip.style.DARK"
        [qimaAutoTooltipPosition]="qimaTooltip.position.TOP_LEFT"
        [qimaAutoTooltipText]="item.productNames[0]"
        [qimaAutoTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
        [qimaAutoTooltipHasArrow]="true"
      >
        {{ item.productNames[0] }}
      </div>
      <div
        *ngIf="item.productNames.length > 1"
        class="product-names"
        qimaTooltip
        [qimaTooltipStyle]="qimaTooltip.style.DARK"
        [qimaTooltipPosition]="qimaTooltip.position.TOP_LEFT"
        [qimaTooltipTemplate]="productNamesTooltip"
        [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
        [qimaTooltipHasArrow]="true"
      >
        {{ 'APP_ASIDE.ASIDE_MENU.PRODUCTS' | translate: { account: item.productNames.length } }}
      </div>
    </ng-template>

    <ng-template #productNamesTooltip>
      <div
        *ngFor="let productName of item.productNames"
        appNgForTrackByUuid
      >
        <ng-container *ngIf="productName !== ''">{{ productName }}</ng-container>
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="previousOrders.length > 2"
    class="app-previous-order-item__view-more"
    (click)="onViewMoreClick()"
  >
    {{ 'TABLE.VIEW_MORE' | translate }}
  </div>
</ng-container>
<ng-container *ngIf="!isDataLoaded">
  <qima-spinner
    class="qima-mt-xl"
    [style.color]="qimaColor.pool.POOL_500"
    [style.justify-content]="'center'"
    [qimaSpinnerUnit]="spinnerService.unit.PIXEL"
    [qimaSpinnerSize]="26"
  ></qima-spinner>
</ng-container>
