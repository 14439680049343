import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeResourceUrl, SafeValue } from '@angular/platform-browser';

/**
 * Sanitize HTML style script url or resourceUrl
 */
@Pipe({
  name: 'byPassSecurityTrust',
})
export class ByPassSecurityTrustPipe implements PipeTransform {
  public constructor(protected _sanitizer: DomSanitizer) {}

  public transform(value: string, type: SecurityContext, isBypass: boolean = false): SafeHtml | SafeStyle | SafeScript | SafeResourceUrl | SafeValue | null {
    switch (type) {
      case SecurityContext.HTML:
        return isBypass ? this._sanitizer.bypassSecurityTrustHtml(value) : this._sanitizer.sanitize(type, value);
      case SecurityContext.STYLE:
        return isBypass ? this._sanitizer.bypassSecurityTrustStyle(value) : this._sanitizer.sanitize(type, value);
      case SecurityContext.SCRIPT:
        return isBypass ? this._sanitizer.bypassSecurityTrustScript(value) : this._sanitizer.sanitize(type, value);
      case SecurityContext.URL:
        return isBypass ? this._sanitizer.bypassSecurityTrustUrl(value) : this._sanitizer.sanitize(type, value);
      case SecurityContext.RESOURCE_URL:
        return isBypass ? this._sanitizer.bypassSecurityTrustResourceUrl(value) : this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
      default:
        return isBypass ? this._sanitizer.bypassSecurityTrustUrl(value) : this._sanitizer.sanitize(SecurityContext.URL, value);
    }
  }
}
