import { FEATURES } from '../../../constants/features.contants';
import { IReportApprovalCategory } from '@aca-new/app/pages/reports/shared/components/report-decision/shared/models/interfaces/report-approval-category.interface';
import { IReportRejectionCategory } from '@aca-new/app/pages/reports/shared/components/report-decision/shared/models/interfaces/report-rejection-category.interface';
import { IPublicProductType, IQualityManual, IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { ICustomAQL } from '@aca-new/app/shared/interfaces/aql-and-sampling-size.interface';
import { IAuditBookingMenusInvisibility } from '@aca-new/app/shared/interfaces/audit-booking-menus-invisibilty.interface';
import { EBrokerType } from '@aca-new/app/shared/models/enums/broker-type.enum';
import { EBusinessUnit } from '@aca-new/app/shared/models/enums/business-unit.enum';
import { ECertificateType } from '@aca-new/app/shared/models/enums/certificate-type.enum';
import { ELcFieldType } from '@aca-new/app/shared/models/enums/lc-field-type.enum';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { IOrder } from '@aca-new/app/shared/models/interfaces/order.interface';
import { ICustomizedProductType } from '@aca-new/app/shared/models/interfaces/product-tree.interface';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppUserSettingService {
  private _userData: IUser = this._appAuthenticationService.getCurrentUser();

  public constructor(private readonly _appAuthenticationService: AppAuthenticationService) {
    this._appAuthenticationService.currentUserSubject$.pipe(untilDestroyed(this)).subscribe((user): void => {
      this._userData = user;
    });
  }

  public getDivisions(): ISelect[] {
    const { productDivisions } = this._userData.preferences.booking;
    const divisions: ISelect[] = [];

    if (this._userData.preferences.booking.showProductDivision) {
      productDivisions.split(';').forEach((item: string): void => {
        divisions.push({
          label: item,
          value: item,
        });
      });
    }

    return divisions;
  }

  public isSubAccount(): boolean {
    return !this._userData.company?.superMaster?.superMaster && this._userData.company?.type === 'subordinate';
  }

  public getUseQuickFormByDefault(): boolean {
    return this._userData.preferences.booking.useQuickFormByDefault;
  }

  public getUseCustomizedProductType(): boolean {
    return this._userData.preferences.booking.preferredProductFamilies.useCustomizedProductType;
  }

  public getClientCanApproveRejectIndividualProductReferences(): boolean {
    return this._userData.preferences.booking.multiReference.clientCanApproveRejectIndividualProductReferences;
  }

  public getIsPoMandatory(): boolean {
    return this._userData.preferences.booking.isPoMandatory;
  }

  public getShouldSendRefSampleToFactory(): boolean {
    return this._userData.preferences.booking.shouldSendRefSampleToFactory;
  }

  public getNumberOfPcsPerRef(): number {
    return this._userData.preferences?.booking.multiReference.numberOfPcsPerRef || 999999999;
  }

  public getNumberOfRefPerReport(): number {
    return this._userData.preferences.booking.multiReference.numberOfRefPerReport;
  }

  public getNumberOfRefPerProduct(): number {
    return this._userData.preferences.booking.multiReference.numberOfRefPerProduct;
  }

  public getIsAskNumberOfReferences(): boolean {
    return this._userData.preferences.booking.multiReference.askNumberOfReferences;
  }

  public getBookOrdersWithMultipleFactories(): boolean {
    return this._userData.preferences.booking.bookOrdersWithMultipleFactories;
  }

  public getLimitSupplierInformation(): boolean {
    return this._userData.company.limitSupplierInformation;
  }

  public getCanSeeAllSuppliersAndFactories(): boolean {
    return this._userData.company.canSeeAllSuppliersAndFactories;
  }

  public getGuidedInspectionEnabled(): boolean {
    return this._userData.preferences?.booking.guidedInspectionEnabled;
  }

  public getSampleFee(): number {
    return this._userData.rate.sampleFee;
  }

  public getAllowCustomizedFinishedPackedSeparately(): boolean {
    return this._userData.preferences.booking.allowCustomizedFinishedPackedSeparately;
  }

  public getAllowEditFinishedPacked(): boolean {
    return this._userData.preferences.booking.allowEditFinishedPacked;
  }

  public getPsiMinReportsSizePerBooking(): number {
    return parseInt(this._userData.preferences.booking.minReportsSizePerBooking, 10);
  }

  public getPsiMinSampleSizePerBooking(): number {
    return parseInt(this._userData.preferences.booking.minSampleSizePerBooking, 10);
  }

  public getMinQuantityToBeReady(index: number): number {
    return this._userData.preferences.booking.minQuantityToBeReady[index].minQty;
  }

  public getMinPackedPctToBeReady(index: number): number {
    return this._userData.preferences.booking.minPackedPctToBeReady[index].minPct;
  }

  public getAutoSendIC(): boolean {
    return this._userData.preferences.report.autoSendIC;
  }

  public getReportRejectionCategories(): IReportRejectionCategory[] {
    return this._userData.preferences.report.rejectCategories;
  }

  public getReportApprovalCategories(): IReportApprovalCategory[] {
    return this._userData.preferences.report.approveCategories;
  }

  public getEnableApprovalReason(): boolean {
    return this._userData.company.enableApprovalReason;
  }

  public getInteractiveReportEnabled(): boolean {
    return this._userData.preferences.report.interactiveReportEnabled;
  }

  public getEnableReportActionButtons(): boolean {
    return (!this.isSubAccount() || this._userData.company.extraAccess?.canSeeReportActionButtons !== false) && !this._userData.features.hideReportDecisionButtons;
  }

  public getReportActionButtonsVisible(): boolean {
    let isVisible: boolean = false;

    if (this.isSubAccount() && !this._userData.company.extraAccess?.canSeeReportActionButtons && this._userData.company.extraAccess?.canSeeReportsPage) {
      isVisible = true;
    }

    isVisible = isVisible || this._userData.features.hideReportDecisionButtons;

    return isVisible;
  }

  public getEnableReportsPage(): boolean {
    return !this.isSubAccount() || !!this._userData.company.extraAccess?.canSeeReportsPage;
  }

  public getUseCustomAQL(): boolean {
    return this._userData.preferences.booking.aqlAndSamplingSize.useCustomAQL;
  }

  public getAQLCanModify(): boolean {
    return this._userData.preferences.booking.aqlAndSamplingSize.canModify;
  }

  public getCanChangeAqlPerReference(): boolean {
    return this._userData.preferences.booking.aqlAndSamplingSize.canChangeAqlPerReference;
  }

  public getCanChangeSampleLevelPerReference(): boolean {
    return this._userData.preferences.booking.aqlAndSamplingSize.canChangeSampleLevelPerReference;
  }

  public getCustomFourthDefect(): string | null {
    return this._userData.preferences?.booking.customizedDefectLevel;
  }

  public getCustomAQL(): ICustomAQL {
    return this._userData.preferences.booking.aqlAndSamplingSize.customAQL;
  }

  public getCustomDefaultSampleLevel(): string {
    return this._userData.preferences.booking.aqlAndSamplingSize.customDefaultSampleLevel;
  }

  public getEyeWearDefaultSampleLevel(): string {
    return this._userData.preferences.booking.aqlAndSamplingSize.eyewearDefaultSampleLevel || 'AQL_II';
  }

  public getMeasurementSampleLevel(): string {
    return this._userData.preferences.booking.aqlAndSamplingSize.measurementSampleLevel;
  }

  public isRefRequired(serviceType: EServiceType): boolean {
    return this._userData.preferences?.booking.yourRefMandatoryInDupro && serviceType === EServiceType.DUPRO;
  }

  public isSkuRequired(): boolean {
    return this._userData.preferences?.booking.isSkuMandatory;
  }

  public getOnlinePaymentType(): string {
    return this._userData.payment.onlinePaymentType;
  }

  public getCharge(): string {
    return this._userData.payment.charge;
  }

  public getAlipayEnabled(): boolean {
    const { payment } = this._userData;
    let isAlipayEnabled = payment.enableAlipay;

    if (!['HKD', 'CNY'].includes(payment.invoiceCurrency)) {
      isAlipayEnabled = false;
    }

    return isAlipayEnabled;
  }

  public isLcFieldsVisible(type: ELcFieldType): boolean {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();
    let isShow: boolean = true;
    const certificateType: number = +userData.preferences.report.certificateType;

    if (!userData.preferences.report.showICField && userData.company.type !== 'subordinate' && type === ELcFieldType.BOOKING) {
      isShow = false;
    } else if (
      type === ELcFieldType.BOOKING &&
      [ECertificateType.STANDARD, ECertificateType.AVON_US].indexOf(certificateType) > -1 &&
      userData.company.extraAccess &&
      userData.company.extraAccess.canSeeReportActionButtons &&
      userData.company.type === 'subordinate'
    ) {
      isShow = false;
    } else if (
      type === ELcFieldType.REPORT &&
      [ECertificateType.STANDARD, ECertificateType.AVON_US].indexOf(certificateType) > -1 &&
      userData.company.extraAccess &&
      userData.company.extraAccess.canSeeReportActionButtons &&
      userData.company.type === 'subordinate'
    ) {
      isShow = true;
    } else if (
      type === ELcFieldType.BOOKING &&
      [ECertificateType.STANDARD, ECertificateType.AVON_US].indexOf(certificateType) > -1 &&
      !userData.company.extraAccess?.canSeeReportActionButtons &&
      userData.company.type === 'subordinate'
    ) {
      isShow = true;
    } else if (
      [ECertificateType.CERTIFICATE_FOR_DELIVERY, ECertificateType.FOSCHINI, ECertificateType.WOOLWORTH, ECertificateType.BEST_LESS, ECertificateType.DUNELM_MILL].indexOf(
        certificateType
      ) > -1
    ) {
      isShow = false;
    }

    return isShow;
  }

  public getEnableDownloadZipButton(): boolean {
    return this._userData.preferences.report?.enableDownloadAllPicturesButton || false;
  }

  public getRequireDropTesting(): boolean {
    return this._userData.preferences.booking.requireDropTesting;
  }

  public getEnableCap(): boolean {
    return this._userData?.company?.enableCap ?? false;
  }

  public getEnableSts(): boolean {
    return this._userData?.company.enableSts ?? false;
  }

  public getQualityManual(): IQualityManual {
    return this._userData.preferences.booking.qualityManual;
  }

  public getIsSuperMaster(): boolean {
    return this._userData.company?.superMaster.superMaster && this._userData.company?.superMaster.masterCompanies.length > 0;
  }

  public getPublicProductTypeList(): IPublicProductType[] {
    return this._userData.preferences?.booking.preferredProductFamilies.publicProductTypeList;
  }

  public getCustomizedProductTypeList(): ICustomizedProductType[] {
    return this._userData.preferences?.booking.preferredProductFamilies.customizedProductTypeList;
  }

  public getIsBookNowVisible(): boolean {
    if (this.getIsViewReportsOnly()) {
      return false;
    }

    return !(this._userData.features.viewReportsOnly || this._userData.company.brokerType === EBrokerType.BROKER);
  }

  public getEnableSupplierTab(): boolean {
    return this._userData.company.superMaster.enableSupplierTab;
  }

  public getAccessDashboardOnly(): boolean {
    return this._userData.company.superMaster.accessDashboardOnly;
  }

  public getCanCancelOrderButton(): boolean {
    return this._userData.company.extraAccess?.canCancelOrderButton ?? false;
  }

  public getHideCcFields(): boolean {
    return this._userData.hideCcFields;
  }

  public getCanSeeCcOptionInBookingForm(): boolean {
    let isVisible: boolean = false;

    if (this.isSubAccount() && !this._userData.company.extraAccess?.canSeeCcOptionInBookingForm) {
      isVisible = true;
    }

    return isVisible;
  }

  public getIsAbleToEditSupplierFactoryIdentification(): boolean {
    return !this.isSubAccount() || (this._userData.company.extraAccess?.ableToEditSupplierFactoryIdentification ?? false);
  }

  public getIsSupplierIdentificationCompulsory(): boolean {
    return this.isSubAccount() && (this._userData.company.extraAccess?.supplierIdentificationComulsory ?? false);
  }

  public getIsViewReportsOnly(): boolean {
    return this._userData.features.viewReportsOnly;
  }

  public getIsShowFactoryDetailsToMaster(order: IOrder): boolean {
    let isSupplierVisible: boolean = true;

    if (this._userData.company?.id !== order.orderGeneralInfo.companyId) {
      isSupplierVisible = this._userData.preferences?.booking.showFactoryDetailsToMaster;
    }

    return isSupplierVisible;
  }

  public getCanEditReInspectionFields(): boolean {
    return this._userData.company?.extraAccess?.canEditReinspectionFields ?? true;
  }

  public getIsAllowInputChinese(): boolean {
    return this._userData.company.allowInputChinese;
  }

  public getIsFirstInspectionVisible(): boolean {
    return this._userData.company.extraAccess ? this._userData.company.extraAccess.ableToBookFirstOrder : true;
  }

  public getIsQimaProduce(): boolean {
    return this._userData.businessUnit === EBusinessUnit.QIMA_PRODUCE;
  }

  public getIsQimaWQS(): boolean {
    return this._userData.businessUnit === EBusinessUnit.WQS;
  }

  public getIsDisableLtInquiry(): boolean {
    return !!this._userData.preferences?.booking?.disableLtInquiry;
  }

  public getIsWqsEnabled(): boolean {
    return !!this._userData.preferences?.booking?.enableWqsBooking;
  }

  public getEnableUseNetwork(): boolean {
    return this._userData.features?.enabledFeatures ? this._userData.features?.enabledFeatures[FEATURES.useNetwork] != null : false;
  }

  public getEnableFoodCertification(): boolean {
    return this._userData.features?.enabledFeatures ? this._userData.features?.enabledFeatures[FEATURES.foodCertification] != null : false;
  }

  public getAuditBookingMenusInvisibility(): QimaOptionalType<IAuditBookingMenusInvisibility> {
    return this._userData.preferences?.booking?.hideAuditBookingMenus;
  }

  public isEntityManagementEnabled(): boolean {
    return this._userData.preferences.booking.entityManagementEnabled;
  }
}
// test
