import { ENVIRONMENT } from '@aca-new/environments/environment';

declare let ENV_TYPE: 'dev' | 'pp' | 'staging' | 'prod';
declare let SERVER_URL_CONFIG: string;
declare let SERVER_URL_CONFIG_CN: string;

export const SERVER_URL = location.origin.includes('qima.cn') ? SERVER_URL_CONFIG_CN : SERVER_URL_CONFIG;

export const { MARKETING_WEBSITE_URL, MARKETING_WEBSITE_APP_LINK, SAMPLING_LEVELS_URL, REFERENCE_SAMPLES, GOOGLE_MAP_URL, CONDITIONS_URL, TABLEAU_URL, INTERNAL_DOMAINS } =
  ENVIRONMENT[ENV_TYPE];
