import { Injectable } from '@angular/core';
import { EQimaInputBorder, EQimaInputSize } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaInputService {
  public readonly inputSize = EQimaInputSize;
  public readonly inputBorder = EQimaInputBorder;
}
