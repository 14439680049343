export enum EServiceType {
  CLC = 'CLC',
  CSM = 'CSM',
  CTPAT = 'C-TPAT',
  DR = 'DR',
  DUPRO = 'DUPRO',
  EA = 'EA',
  ENVA = 'ENVA',
  GGAP = 'GGAP',
  GHP = 'GHP',
  GMARK = 'GMARK',
  GMP = 'GMP',
  ILI = 'ILI',
  IPC = 'IPC',
  LT = 'LT',
  MA = 'MA',
  PEO = 'PEO',
  PM = 'PM',
  PSI = 'PSI',
  SABER = 'SABER',
  SAQ = 'SAQ',
  SASO = 'SASO',
  SASOREGISTRATION = 'saso-registration',
  SC = 'SC',
  SP = 'SP',
  SR = 'SR',
  STRA = 'STRA',
  STS = 'STS',
  TSM = 'TSM',
  WCP = 'WCP',
  WQS = 'WQS',
}
