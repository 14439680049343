import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  /**
   * @description
   * The title of the dialog
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input('dialogHeaderTitle')
  public title: QimaOptionalType<string> = undefined;

  @Output('dialogHeaderClose')
  public readonly closeClick: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    public readonly qimaIcon: QimaIconService,
    public readonly qimaButton: QimaButtonService
  ) {}

  public onClick(): void {
    this._emitClick();
  }

  private _emitClick(): void {
    this.closeClick.emit();
  }
}
