import { Injectable } from '@angular/core';
import { EQimaPosition, EQimaTooltipStrategy, EQimaTooltipStyle } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaTooltipService {
  public readonly position = EQimaPosition;
  public readonly strategy = EQimaTooltipStrategy;
  public readonly style = EQimaTooltipStyle;
}
