<div class="app-book-from-previous-order">
  <app-dialog-header (dialogHeaderClose)="close()">
    <div class="app-book-from-previous-order__title">
      {{ 'APP_ASIDE.BOOK_FORM_PREVIOUS_ORDER.TITLE' | translate }}
    </div>
  </app-dialog-header>
  <div class="app-book-from-previous-order__step qima-mt-s">{{ 'APP_ASIDE.BOOK_FORM_PREVIOUS_ORDER.STEP1' | translate }}</div>
  <ng-container
    class="table-toolbar-search-without-border"
    *ngTemplateOutlet="tableToolbarSearchWithoutBorder"
  ></ng-container>
  <div class="app-book-from-previous-order__table">
    <app-data-table
      dataTableEmptyMessage="COMMON.NO_DATA"
      (dataTablePaginationChange)="onPaginationChange($event)"
      (dataTableRowClick)="onTableRowClick($event)"
      (dataTablePageSizeChange)="onPageSizeChange($event)"
      (dataTableTableBackendSort)="onSortClick($event)"
      [dataTableUrl]="dataTableUrl"
      [dataTableActivePage]="activePage"
      [dataTableHeaders]="tableHeaders"
      [dataTablePageSizes]="pageSizes"
      [dataTableTransformTableCellMap]="transformIceCube"
      [dataTableIsPaginationVisible]="true"
      [dataTableHasSelectedBackgroundColor]="true"
    >
      <ng-container
        class="table-toolbar-top-actions"
        *ngTemplateOutlet="tableToolbarTopActions"
      ></ng-container>
    </app-data-table>
  </div>
  <!--TODO-->
  <!-- step only show businessSolution === PSI-->
  <div *ngIf="isStep2Visible">
    <div class="app-book-from-previous-order__step qima-mt-l">{{ 'APP_ASIDE.BOOK_FORM_PREVIOUS_ORDER.STEP2' | translate }}</div>
    <div class="app-book-from-previous-order__service-type">{{ 'APP_ASIDE.BOOK_FORM_PREVIOUS_ORDER.SERVICE_TYPE' | translate }}</div>
    <qima-input-select
      class="app-book-from-previous-order__select-service-types"
      [(ngModel)]="serviceType"
      [qimaInputSelectHasClearButton]="false"
      [qimaInputSelectSize]="qimaInput.inputSize.LARGE"
      [qimaInputSelectHasValidators]="false"
    >
      <qima-menu>
        <qima-menu-item-text
          *ngFor="let item of serviceTypes"
          appNgForTrackByUuid
          [qimaMenuItemTextLabel]="item.label | translate"
          [qimaMenuItemTextValue]="item.value"
        ></qima-menu-item-text>
      </qima-menu>
    </qima-input-select>
  </div>

  <div
    class="app-book-from-previous-order__footer"
    [ngClass]="{ 'qima-mt-l': !selectedRow }"
  >
    <qima-button
      class="qima-mr-s"
      [qimaButtonLabel]="'SELECT_SUPPLIER.CANCEL' | translate"
      [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
      [qimaButtonSize]="qimaButton.buttonSize.LARGE"
      (qimaButtonClick)="close()"
    ></qima-button>
    <qima-button
      class="app-book-from-previous-order__footer__select-button"
      [qimaButtonLabel]="'SELECT_SUPPLIER.SELECT' | translate"
      [qimaButtonIsDisabled]="!selectedRow"
      [qimaButtonSize]="qimaButton.buttonSize.LARGE"
      (qimaButtonClick)="onSelectClick()"
    ></qima-button>
  </div>
</div>

<ng-template #tableToolbarTopActions>
  <app-business-solution-select (businessSolutionChange)="onBusinessSolutionChange($event)"></app-business-solution-select>
</ng-template>
<ng-template #tableToolbarSearchWithoutBorder>
  <qima-input-text
    class="app-book-from-previous-order__search-input"
    [(ngModel)]="queryParams.keyword"
    [qimaInputTextPlaceholder]="'COMMON.SEARCH' | translate"
    [qimaInputTextBorder]="qimaInput.inputBorder.NONE"
    [qimaInputTextIconLeftName]="qimaIcon.iconName.SEARCH"
    [qimaInputTextSize]="qimaInput.inputSize.MEDIUM"
    (keydown.enter)="onSearchEnter()"
    (qimaInputTextClearButtonClick)="onSearchClear()"
  ></qima-input-text>
</ng-template>
