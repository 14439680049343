import { ESuperMasterSelectorType } from '@aca-new/app/shared/components/data-table/shared/components/super-master-selector/shared/models/enums/super-master-selector-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SuperMasterSelectorService {
  public constructor(private readonly _storageService: StorageService) {}

  public transformUrl(currentUrls: string, superMasterUserId: string, userId?: string): string {
    userId = userId || (this._storageService.getItem(EStorageKeys.USER_ID) ?? '');

    if (superMasterUserId === ESuperMasterSelectorType.ALL) {
      return currentUrls + (currentUrls.includes('?') ? '&all=true' : '?all=true');
    }

    if (superMasterUserId && superMasterUserId !== userId) {
      return currentUrls.replace(userId, `${userId}-${superMasterUserId}`);
    }

    return currentUrls;
  }
}
