import { Injectable } from '@angular/core';
import { EQimaThreeSquaresLoaderStyle, EQimaThreeSquaresLoaderUnit } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaThreeSquaresLoaderService {
  public readonly unit = EQimaThreeSquaresLoaderUnit;
  public readonly style = EQimaThreeSquaresLoaderStyle;
}
