import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { IDialogDelete } from '@aca-new/app/shared/components/modal/shared/components/dialog-delete/shared/interfaces/dialog-delete.interface';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteComponent {
  public constructor(
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public readonly dialogData: IDialogDelete,
    public readonly qimaButton: QimaButtonService
  ) {}

  public close(): void {
    this._dialogRef.close(false);
  }

  public delete(): void {
    this._dialogRef.close(true);
  }
}
