import { INpsSurvey } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/models/interfaces/nps-survey.interface';
import { NpsSurveyService } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/services/nps-survey.service';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaInputService } from '@aca-new/app/shared/services/exported-services/qima-input/qima-input.service';
import { QimaInputRadioGroupService } from '@aca-new/app/shared/services/exported-services/qima-input-radio-group/qima-input-radio-group.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AppSnackbarService } from '@aca-new/app/shared/services/modal-services/app-snackbar/app-snackbar.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { EQimaSnackbarType, QimaOptionalType } from '@qima/ngx-qima';
import { shuffle } from 'lodash/index';
import moment from 'moment-timezone';

interface INpsSurveyDialog {
  readonly npsFrom: string;
  readonly orderNo: string;
  readonly serviceType: string;
}

@UntilDestroy()
@Component({
  selector: 'app-nps-survey',
  templateUrl: './nps-survey.component.html',
  styleUrls: ['./nps-survey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [NpsSurveyService],
})
export class NpsSurveyComponent {
  public feedback: string = '';
  public ratingScore: number | undefined = undefined;
  public ratingScores: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public lowRatingReason: QimaOptionalType<number> = undefined;
  public lowRatingReasons: number[] = shuffle([1, 2, 3, 4, 5]).concat([6]);

  public constructor(
    private readonly _dialogRef: DialogRef<void>,
    private readonly _appSnackbarService: AppSnackbarService,
    private readonly _npsSurveyService: NpsSurveyService,
    private readonly _overlayService: AppOverlayService,
    private readonly _translateService: TranslateService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    @Inject(DIALOG_DATA) public readonly dialogData: INpsSurveyDialog,
    public readonly qimaInputRadioGroup: QimaInputRadioGroupService,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaInput: QimaInputService
  ) {}

  public onCloseClick(): void {
    this._dialogRef.close();
  }

  public onSubmitClick(): void {
    if (this.ratingScore !== undefined && this.ratingScore < 7 && !this.lowRatingReason) {
      this._appSnackbarService.showSnackbar('NPS_SURVEY.NO_SELECT_ERROR', EQimaSnackbarType.ERROR);

      return;
    }

    const enMoment = moment().locale('en');
    const userData: IUser = this._appAuthenticationService.getCurrentUser();
    const npsSurvey: INpsSurvey = {
      npsFrom: this.dialogData.npsFrom || 'myQIMA',
      accountManager: userData.sic,
      companyName: userData.company.name,
      country: userData.company.country,
      date: enMoment.format('YYYY-MMM-DD'),
      likeDegree: `${this.ratingScore}`,
      loginName: userData.login,
      orderNo: this.dialogData.orderNo,
      suggestion: this.feedback,
      serviceType: this.dialogData.serviceType.toLocaleUpperCase(),
      week: enMoment.format('w'),
      work:
        this.ratingScore !== undefined && this.ratingScore < 7 && this.lowRatingReason
          ? this._translateService.instant(`NPS_SURVEY.LOW_RATING.REASON_${this.lowRatingReason}`)
          : '00 - No selection',
      year: enMoment.format('YYYY'),
    };

    this._saveNpsSurvey(npsSurvey);
  }

  public onLowRatingReasonClick(lowRatingReason: number): void {
    this.lowRatingReason = lowRatingReason;
  }

  private _closeDialog(): void {
    this._overlayService.updateIsLoading(false);
    this._appSnackbarService.showSnackbar('NPS_SURVEY.SURVEY_SUBMITTED', EQimaSnackbarType.SUCCESS);
    this._dialogRef.close();
  }

  private _saveNpsSurvey(npsSurvey: INpsSurvey): void {
    this._overlayService.updateIsLoading(true);
    // TODO: optimize this code
    this._npsSurveyService
      .saveSurvey$(npsSurvey)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (): void => {
          this._closeDialog();
        },
        error: (): void => {
          this._closeDialog();
        },
      });
  }
}
