import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { IMessageEventHandler, IPostMessageData } from '@aca-new/app/shared/interfaces/post-message.interface';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppPostMessageService {
  private readonly _handlers = new Map<string, IMessageEventHandler>();

  public constructor(@Inject(WINDOW) private readonly _window: IMyWindow) {
    this._window.addEventListener('message', (event: MessageEvent): void => {
      if (event.origin.includes('qima.com') || event.origin.includes('qima.cn') || event.origin.includes('localhost') || event.origin.includes('qima.dev')) {
        this._onMessageReceived(event);
      }
    });
  }

  public post(targetWindow: Window, msg: IPostMessageData, targetOrigin: string): void {
    targetWindow.postMessage(msg, targetOrigin);
  }

  public addHandler(key: string, handler: IMessageEventHandler): void {
    this._handlers.set(key, handler);
  }

  public removeHandler(key: string): void {
    this._handlers.delete(key);
  }

  private _onMessageReceived(event: MessageEvent): void {
    this._handlers.forEach((handler: IMessageEventHandler): void => {
      handler(event);
    });
  }
}
