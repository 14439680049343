import { EMarketingCategoryKey } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/enums/marketling-category-key.enum';
import { IMarketingListDom } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/interfaces/marketing-list.interface';
import { IMarketingSelect } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/interfaces/marketing-select.interface';
import { IWelcomeAccountInfo } from '@aca-new/app/pages/home/pages/welcome-account-info/shared/interfaces/welcome-account-info.interface';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { UserService } from '@aca-new/app/pages/user/shared/service/user.service';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { ControlsOf, FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertXML } from 'simple-xml-to-json';

@Injectable()
export class WelcomeAccountInfoService {
  public constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _httpService: HttpService,
    private readonly _userService: UserService,
    private readonly _acaMessageService: AcaMessageService,
    private readonly _storageService: StorageService
  ) {}

  public buildWelcomeAccountInfoForm(user: IUser): FormGroup<ControlsOf<IWelcomeAccountInfo>> {
    return this._formBuilder.group<ControlsOf<IWelcomeAccountInfo>>({
      // TODO: add validator for emails
      billingEmail: this._formBuilder.control<string>(user.contacts.billing.email ?? '', [Validators.required]),
      billingFamilyName: this._formBuilder.control<string>(user.contacts.billing.familyName ?? '', Validators.required),
      billingGivenName: this._formBuilder.control<string>(user.contacts.billing.givenName ?? '', Validators.required),
      companyAddress: this._formBuilder.control<string>(user.company.address ?? '', Validators.required),
      companyCity: this._formBuilder.control<string>(user.company.city, Validators.required),
      companyCountry: this._formBuilder.control<string>('', Validators.required),
      companyState: this._formBuilder.control<string>(user.company.state ?? '', Validators.required),
      hearFrom: this._formBuilder.control<string>('', Validators.required),
      hearFromSub: this._formBuilder.control<string>(''),
      hearFromSubSub: this._formBuilder.control<string>(''),
      userPosition: this._formBuilder.control<string>(user.contacts.main.position ?? '', Validators.required),
    });
  }

  public getSelectsByKey(data: IMarketingListDom, key: QimaOptionalType<EMarketingCategoryKey>): IMarketingSelect[] {
    if (!key) {
      return [];
    }

    const category = data.document.children[0].en.children.find((item): boolean => !!item[key]);

    if (!category) {
      return [];
    }

    const isWhereHear = key === EMarketingCategoryKey.WHERE_HEAR;
    const isTradeShows = key === EMarketingCategoryKey.TRADE_SHOWS;

    return category[key].children.map((way): IMarketingSelect => {
      return way.option
        ? {
            label: isWhereHear ? way.option.value : way.option.content,
            location: way.option.location,
            name: way.option.name,
            submenu: way.option.submenu,
            value: isTradeShows ? way.option.content : way.option.value,
          }
        : {
            label: way.country.content,
            value: way.country.content,
            code: way.country.code,
          };
    });
  }

  public getMarketingList$(): Observable<IMarketingListDom> {
    return this._httpService.httpClient
      .get(`${SERVER_URL}/aca-server/getMarketingList`, {
        observe: 'body',
        responseType: 'text',
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'text/plain',
        },
      })
      .pipe(
        map((data): IMarketingListDom => {
          return convertXML(data);
        })
      );
  }

  public updateContactDetails$(data: IWelcomeAccountInfo): Observable<IUser | null> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.put<IHttpResponseBody<IUser>>(`${SERVER_URL}/user/v2/${userId}/detail`, data, { observe: 'body' }).pipe(
      map((response: Readonly<IHttpResponseBody<IUser>>): IUser | null => {
        if (response.content) {
          this._userService.updateUserDataSession(response.content);
          this._acaMessageService.postMessageToAca({
            type: EPostMessageType.UPDATE_USER_DATA,
            data: { userData: response.content },
          });
        }

        return response.content || null;
      })
    );
  }
}
