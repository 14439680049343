import { AppModule } from './app/app.module';
import { ENVIRONMENT } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

declare let ENV_TYPE: 'dev' | 'pp' | 'prod';

if (ENVIRONMENT[ENV_TYPE].production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err): void => {
    // Exceptionally allow a console call
    // eslint-disable-next-line no-restricted-globals
    console.error(err);
  });
