import { BusinessSolutionSelectComponent } from '@aca-new/app/shared/components/business-solution-select/business-solution-select.component';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaInputSelectModule, QimaMenuItemTextModule, QimaMenuModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [BusinessSolutionSelectComponent],
  exports: [BusinessSolutionSelectComponent],
  imports: [QimaMenuItemTextModule, TranslateModule, FormsModule, QimaMenuModule, QimaInputSelectModule, CommonModule, TrackByUuidDirective],
})
export class BusinessSolutionSelectModule {}
