import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Directive({
  selector: '[appNgForTrackByUuid]',
  standalone: true,
})
export class TrackByUuidDirective<T> {
  public constructor(@Host() private readonly _ngFor: NgForOf<T>) {
    this._ngFor.ngForTrackBy = (_index: Readonly<number>): string => uuidv4();
  }
}
