<div class="app-empty-content">
  <img
    alt="image"
    [src]="image"
  />
  <span
    *ngIf="message"
    class="app-empty-content__container"
  >
    <span class="app-empty-content__container__message">{{ message | translate }}</span></span
  >
</div>
