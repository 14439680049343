<div class="payment-method">
  <app-dialog-header (dialogHeaderClose)="close()">
    <div class="payment-method__title">
      {{ 'PAYMENTS.PAYMENT_METHOD' | translate }}
    </div>
  </app-dialog-header>

  <qima-divider
    class="qima-mt-l qima-mb-l"
    [qimaDividerOrientation]="qimaDivider.orientation.HORIZONTAL"
  ></qima-divider>
  <div id="dropin"></div>
</div>
