import { EPlatformIdEnum } from '@aca-new/app/shared/models/platform.models';
import { BrowserWindowRef } from '@aca-new/app/shared/refs/browser-window.ref';
import { isPlatformBrowser } from '@angular/common';

/**
 * @description
 * The factory to create the definitive window object depending on the platform
 * Some platforms are not a browser and don't have a window object
 * This code intend to make the window universal to avoid error throwing
 * @param {BrowserWindowRef} browserWindowRef A class storing possibly the real reference to access the window object
 * @param {EPlatformIdEnum} platformId The current platform which change based on where the code is executed
 * @returns {Window | Record<string, void>} The window object or an empty object depending on the platform
 */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: EPlatformIdEnum): Window | Record<string, void> {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }

  return {};
}
