import { IDraft } from '@aca-new/app/pages/book/shared/models/interfaces/draft.interface';
import { BookFromPreviousOrderComponent } from '@aca-new/app/pages/bookings/pages/previous-order-item/components/book-from-previous-order/book-from-previous-order.component';
import { IBookFormPreviousOrder } from '@aca-new/app/pages/bookings/pages/previous-order-item/components/book-from-previous-order/shared/models/interfaces/book-form-previous-order.interface';
import { IPreviousOrders } from '@aca-new/app/pages/bookings/shared/models/interfaces/bookings.interface';
import { BookingsService } from '@aca-new/app/pages/bookings/shared/services/bookings.service';
import { IPreviousOrder } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-menu/share/models/interfaces/previous-order.interface';
import { AsideService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.service';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogInProgressComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-in-progress/dialog-in-progress.component';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { SUPPORT_SERVICE_TYPES } from '@aca-new/app/shared/constants/service-type.constants';
import { EBusinessSolution } from '@aca-new/app/shared/models/enums/business-solution.enum';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { QimaColorService } from '@aca-new/app/shared/services/exported-services/qima-color/qima-color.service';
import { QimaSpinnerService } from '@aca-new/app/shared/services/exported-services/qima-spinner/qima-spinner.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppTimeService } from '@aca-new/app/shared/services/time-services/app-time.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaIceCubeSize, QimaOptionalType } from '@qima/ngx-qima';
import { sortBy } from 'lodash/index';
import { forkJoin } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-previous-order-item',
  templateUrl: './previous-order-item.component.html',
  styleUrls: ['./previous-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PreviousOrderItemComponent implements OnInit {
  public iceCubeSize: EQimaIceCubeSize = EQimaIceCubeSize.SMALL;
  public previousOrders: IPreviousOrder[] = [];
  public isDataLoaded: boolean = false;
  public constructor(
    public readonly qimaTooltip: QimaTooltipService,
    public readonly spinnerService: QimaSpinnerService,
    public readonly qimaColor: QimaColorService,
    private readonly _acaMessageService: AcaMessageService,
    private readonly _bookingsService: BookingsService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _router: Router,
    private readonly _appTimeService: AppTimeService,
    private readonly _overlayService: AppOverlayService,
    private readonly _dialog: DialogService,
    private readonly _asideService: AsideService,
    private readonly _appUserSettingService: AppUserSettingService
  ) {}

  public ngOnInit(): void {
    this._initData();
  }

  public onPreviousOrderItemClick(item: IPreviousOrder): void {
    if (this._acaMessageService.isAcaFrameAvailable) {
      this._asideService.updateAsideMenuVisible(false);
      this._acaMessageService.postMessageToAca({
        type: EPostMessageType.BOOK_FROM_PREVIOUS_ORDER,
        data: { value: item.originData, serviceType: item.iceCubeLabel },
      });

      return;
    }

    if (!SUPPORT_SERVICE_TYPES.includes(item.iceCubeLabel)) {
      this._dialog.open(DialogInProgressComponent);

      return;
    }

    this._bookFromPreviousOrder(item.originData.orderId, item.iceCubeLabel.toLocaleLowerCase());
  }

  public onViewMoreClick(): void {
    if (this._acaMessageService.isAcaFrameAvailable) {
      this._asideService.updateAsideMenuVisible(false);

      // Router to completed bookings page if current page is home page
      if (this._router.url.includes('/home')) {
        this._acaMessageService.informAcaPathChange('/bookings/completed');
      }

      this._acaMessageService.postMessageToAca({
        type: EPostMessageType.VIEW_MORE_PREVIOUS_ORDERS,
      });

      return;
    }

    const dialogRef: DialogRef<QimaOptionalType<IBookFormPreviousOrder>> = this._dialog.open(BookFromPreviousOrderComponent);

    dialogRef
      .afterClosed$()
      .pipe(untilDestroyed(this))
      .subscribe((response: QimaOptionalType<IBookFormPreviousOrder>): void => {
        if (!response) {
          return;
        }

        this._bookFromPreviousOrder(response.orderId, response.serviceType);
      });
  }

  private _bookFromPreviousOrder(orderId: string, serviceType: string): void {
    this._overlayService.updateIsLoading(true);
    this._bookingsService
      .createDraftFromPreviousOrder$(orderId, serviceType)
      .pipe(untilDestroyed(this))
      .subscribe((response: IDraft | null): void => {
        this._asideService.updateAsideMenuVisible(false);
        this._overlayService.updateIsLoading(false);

        if (!response) {
          return;
        }

        void this._router.navigateByUrl('/', { skipLocationChange: true }).then((canLeave: boolean): void => {
          if (canLeave) {
            void this._router.navigate([`/book-inspection/${serviceType}/${response.draftId}`]);
          }
        });
        this._changeDetectorRef.markForCheck();
      });
  }

  private _initData(): void {
    this.isDataLoaded = false;
    forkJoin([
      this._bookingsService.getPreviousOrders$(`${SERVER_URL}/user/{userId}/psi-orders?page-size=10&page=1&sortType=desc&sortField=bookingDate`),
      this._bookingsService.getPreviousOrders$(`${SERVER_URL}/user/{userId}/audit-orders?service-type=${encodeURIComponent('ma,ea,stra,ctpat,enva,dr')}&page-size=10&page=1`),
      this._bookingsService.getPreviousOrders$(`${SERVER_URL}/user/{userId}/lt/orders?pageSize=10&orderStatus=${encodeURIComponent('20,21,22,23,24,25,26,28')}&pageNumber=1`),
      this._bookingsService.getPreviousOrders$(`${SERVER_URL}/user/{userId}/wqs/orders?pageSize=10&status=${encodeURIComponent('220')}&pageNumber=1`),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((response): void => {
        const thirdIndexOrders = response[3].map((order): IPreviousOrder => {
          return {
            iceCubeLabel: 'WQS',
            productNames: [order.refNumber],
            orderDate: `${order.auditDateFrom}`,
            originData: order,
            canActivate: true,
          };
        });
        let orders: IPreviousOrder[] = this._displayResult(response[0], EBusinessSolution.INSPECTIONS).concat(
          this._displayResult(response[1], EBusinessSolution.AUDITS),
          this._displayResult(response[2], EBusinessSolution.LAB_TESTING)
        );

        if (this._appUserSettingService.getEnableFoodCertification()) {
          orders = orders.concat(thirdIndexOrders);
        }

        orders = sortBy(orders, (item): Date | number => {
          return item.originData.bookingDate ? this._appTimeService.convertStringToSortableDate(item.originData.bookingDate) : Infinity;
        });
        orders.reverse();
        this.previousOrders = orders;
        this.isDataLoaded = true;
        this._changeDetectorRef.markForCheck();
      });
  }

  private _displayResult(orders: IPreviousOrders[], businessSolution: EBusinessSolution): IPreviousOrder[] {
    return orders.map((item: IPreviousOrders): IPreviousOrder => {
      const serviceDate: string = businessSolution === EBusinessSolution.LAB_TESTING ? item.testStartDate : item.serviceDate ?? '';
      const productName: string = businessSolution === EBusinessSolution.AUDITS ? item.supplierName ?? '' : item.productNames;
      let productNames: string[] = [];

      item.serviceDate = serviceDate ? this._appTimeService.capitalizeDateString(serviceDate) : '';

      if (productNames) {
        productNames = businessSolution === EBusinessSolution.INSPECTIONS ? productName.split('###') : productName.split(';');
      }

      return {
        iceCubeLabel: item.serviceTypeText,
        productNames,
        orderDate: serviceDate,
        originData: item,
        canActivate: SUPPORT_SERVICE_TYPES.includes(item.serviceTypeText),
      };
    });
  }
}
