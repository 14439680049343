import { IFile } from '@aca-new/app/pages/book/pages/confirmation/shared/components/confirmation-order-summary/shared/interfaces/file.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IAwsUrlListResponse } from '@aca-new/app/shared/interfaces/aws-url-list-response.interface';
import { IBase64Image, IFileResponse } from '@aca-new/app/shared/interfaces/file-response.interface';
import { EDocType } from '@aca-new/app/shared/models/enums/doc-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { HttpResponseBodyNullableType } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { HttpService, SkipInterceptorService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { Injectable } from '@angular/core';
import { IQimaFile } from '@qima/ngx-qima';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppFileService {
  public constructor(
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService,
    private readonly _skipInterceptorService: SkipInterceptorService
  ) {}

  public getFiles$(sourceId: string, docType: EDocType): Observable<IFile[]> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url = `${SERVER_URL}/user/${userId}/files/${sourceId}?docType=${docType}`;

    return this._httpService.httpClient.get<IFile[]>(url, { observe: 'body' }).pipe(map((response: QimaNullableType<IFile[]>): IFile[] => response ?? []));
  }

  public uploadFiles$(sourceId: string, docType: EDocType, uploadData: IQimaFile): Observable<QimaNullableType<IFileResponse>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url = `${SERVER_URL}/user/${userId}/doc-type/${docType}/source/${sourceId}/file`;
    const formData: FormData = new FormData();

    formData.append('file', uploadData);

    return this._httpService.httpClient
      .post<QimaNullableType<IFileResponse>>(url, formData, { observe: 'body' })
      .pipe(map((response: QimaNullableType<IFileResponse>): QimaNullableType<IFileResponse> => response));
  }

  public deleteFile$(fileId: string): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url = `${SERVER_URL}/user/${userId}/file/${fileId}`;

    return this._httpService.httpClient.delete<void>(url, { observe: 'body' }).pipe(map((response: void): void => response));
  }

  public getAwsLink$(productIds: string[]): Observable<HttpResponseBodyNullableType<IAwsUrlListResponse>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url = `${SERVER_URL}/user/${userId}/getAwsUrlList?fileType=REPORT_IMAGE_ZIP&productIds=${productIds.join(',')}`;

    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<IAwsUrlListResponse>>(url, { observe: 'body' })
      .pipe(map((response: HttpResponseBodyNullableType<IAwsUrlListResponse>): HttpResponseBodyNullableType<IAwsUrlListResponse> => response));
  }

  public getBase64Image$(fileId: string): Observable<QimaNullableType<IBase64Image>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url: string = `${SERVER_URL}/user/${userId}/file/${fileId}/base64`;

    return this._httpService.httpClient
      .get<QimaNullableType<IBase64Image>>(url, { observe: 'body' })
      .pipe(map((response: QimaNullableType<IBase64Image>): QimaNullableType<IBase64Image> => response));
  }

  public getBase64ImageWithEncodedId$(fileId: string): Observable<QimaNullableType<IBase64Image>> {
    const url: string = `${SERVER_URL}/image-viewer?imageId=${fileId}`;

    return this._skipInterceptorService.httpClient
      .get<QimaNullableType<IBase64Image>>(url, { observe: 'body' })
      .pipe(map((response: QimaNullableType<IBase64Image>): QimaNullableType<IBase64Image> => response));
  }
}
