import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { QIMA_EMAIL_REGEXP } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

export class EmailsValidators {
  public static emails: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let email;

    if (typeof control.value === 'string') {
      email = control.value?.split(';').filter((email: Readonly<string>): boolean => {
        return !QIMA_EMAIL_REGEXP.test(email);
      });
    } else {
      email = control.value?.filter((email: Readonly<string>): boolean => {
        return !QIMA_EMAIL_REGEXP.test(email);
      });
    }

    if (isEmpty(email) || isEmpty(control.value)) {
      return null;
    }

    return { email };
  };
}
