import { HeaderService } from '@aca-new/app/pages/main/shared/components/header/shared/service/header.service';
import { ILanguage, LANGUAGES } from '@aca-new/app/shared/constants/languages.constants';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { QimaColorService } from '@aca-new/app/shared/services/exported-services/qima-color/qima-color.service';
import { QimaDotService } from '@aca-new/app/shared/services/exported-services/qima-dot/qima-dot.service';
import { QimaShadowService } from '@aca-new/app/shared/services/exported-services/qima-shadow/qima-shadow.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import localForage from 'localforage';

@UntilDestroy()
@Component({
  selector: 'app-translate-menu',
  templateUrl: './translate-menu.component.html',
  styleUrls: ['./translate-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateMenuComponent implements OnInit {
  public translateMenuItems: ILanguage[] = LANGUAGES;
  public activeIndex: number = this.translateMenuItems.findIndex((item): boolean => item.value === this._translateService.currentLang);

  public constructor(
    private readonly _acaMessageService: AcaMessageService,
    private readonly _translateService: TranslateService,
    private readonly _headerService: HeaderService,
    private readonly _router: Router,
    public readonly shadowService: QimaShadowService,
    public readonly qimaDot: QimaDotService,
    public readonly qimaColor: QimaColorService
  ) {}

  public ngOnInit(): void {
    this._watch();
  }

  public onTranslateMenuItemClick(index: number): void {
    this._onItemClick(index);
  }

  public onTranslateMenuItemEnter(index: number): void {
    this._onItemClick(index);
  }

  private _watch(): void {
    this._headerService.activeLanguageIndex$.pipe(untilDestroyed(this)).subscribe((index: number): void => {
      this.activeIndex = index;
    });
  }

  private _onItemClick(index: number): void {
    const language = this.translateMenuItems[index].value;

    if (!this._acaMessageService.isMyQimaPageAvailable(this._router.url)) {
      this._acaMessageService.postMessageToAca({
        type: EPostMessageType.LANGUAGE_CHANGE,
        data: { language },
      });
    }

    void localForage.setItem(EStorageKeys.LOCALE_LANGUAGE, language);

    this._updateActiveLanguageIndex(index);
    this._translateService.use(language).pipe(untilDestroyed(this)).subscribe();
    this._closeMenu();
  }

  private _updateActiveLanguageIndex(index: number): void {
    this._headerService.updateActiveLanguageIndex(index);
  }

  private _closeMenu(): void {
    this._headerService.updateIsTranslateMenuVisible(false);
  }
}
