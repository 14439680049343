import { Injectable } from '@angular/core';
import { EQimaDividerOrientation, EQimaDividerStyle } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaDividerService {
  public readonly orientation = EQimaDividerOrientation;
  public readonly style = EQimaDividerStyle;
}
