<div class="dialog-common">
  <app-dialog-header
    [dialogHeaderTitle]="title"
    (dialogHeaderClose)="close()"
  ></app-dialog-header>
  <ng-content select=".content"></ng-content>
  <ng-content select=".foot"></ng-content>
  <ng-content select=".content-export"></ng-content>
  <ng-content select=".foot-export"></ng-content>
  <ng-content select=".content-delete"></ng-content>
  <ng-content select=".foot-delete"></ng-content>
  <ng-content select=".content-confirm"></ng-content>
  <ng-content select=".foot-confirm"></ng-content>
  <ng-content select=".content-manage-status"></ng-content>
  <ng-content select=".foot-manage-status"></ng-content>
</div>
