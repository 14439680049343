import { Injectable } from '@angular/core';
import { EQimaInputGroupPlacement, EQimaInputRadioGroupPlacement } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaInputRadioGroupService {
  public readonly placement = EQimaInputRadioGroupPlacement;
  public readonly groupPlacement = EQimaInputGroupPlacement;
}
