import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { SOLUTION_TYPE } from '@aca-new/app/shared/constants/solution-type.constants';
import { EQimaTableHeadCellSort } from '@qima/ngx-qima';

export const BOOK_FROM_PREVIOUS_ORDER_HEADERS: Record<string, ITableHeader[]> = {
  [SOLUTION_TYPE.inspections]: [
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'serviceType',
      iceCubeKey: 'serviceTypeText',
      label: 'TABLE.TYPE',
      type: ETableDataType.ICE_CUBE,
      width: '70',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'bookingDate',
      label: 'TABLE.SERVICE_DATE',
      type: ETableDataType.STRING,
      width: '124',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'productNames',
      label: 'TABLE.PRODUCT_NAME',
      type: ETableDataType.MULTIPLE_LABEL_CELL,
      cellTransform(cellData: ITableBodyCell): void {
        cellData.label = cellData.label?.replace(/\//g, '###');
        cellData.value = 'TABLE.PRODUCTS';
      },
      width: '164',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'poNumbers',
      label: 'TABLE.PO_NO',
      type: ETableDataType.MULTIPLE_LABEL_CELL,
      cellTransform(cellData: ITableBodyCell): void {
        cellData.value = 'TABLE.PO_NOS';
      },
      width: '108',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'refNumber',
      label: 'TABLE.BOOKING_REF_NO',
      type: ETableDataType.STRING,
      width: '131',
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'supplierName',
      label: 'TABLE.SUPPLIER',
      type: ETableDataType.STRING,
      width: '123',
    },
  ],
};
