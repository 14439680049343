<div class="user-guide">
  <app-dialog-common dialogCommonTitle="USER_GUIDE.TITLE">
    <div class="content">
      <div class="user-guide__description">
        {{ 'USER_GUIDE.DESCRIPTION' | translate }}
      </div>

      <!-- TODO refactor after use the real video -->
      <div class="user-guide__video"></div>

      <qima-button
        [qimaButtonLabel]="'USER_GUIDE.DOWNLOAD_PDF' | translate"
        (qimaButtonClick)="downloadPdf()"
      ></qima-button>
    </div>
    <div class="foot">
      <qima-button
        [qimaButtonLabel]="'USER_GUIDE.GOT_IT' | translate"
        (qimaButtonClick)="close()"
      ></qima-button>
    </div>
  </app-dialog-common>
</div>
