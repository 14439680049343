export enum EPostMessageType {
  ACA_FRAME_RENDERED = 'acaFrameRendered',
  BOOK_FROM_PREVIOUS_ORDER = 'bookFromPreviousOrder',
  BUSINESS_SOLUTION_CHANGE = 'businessSolutionChange',
  COMPANY_LOGO_CHANGE = 'companyLogoChange',
  CREATE_CAP = 'createCAP',
  CUSTOME_PAYMENT_TOKEN = 'customPaymentTokenKey',
  LANGUAGE_CHANGE = 'languageChange',
  LOAD = 'load',
  PATH_CHANGE = 'pathChange',
  PAYMENT_MODAL_SHOW = 'paymentModalShow',
  RE_ORDER = 'reOrder',
  RELOAD = 'reload',
  ROUTE_CHANGE = 'routeChange',
  UPDATE_USER_DATA = 'updateUserData',
  VIEW_CAP = 'ViewCAP',
  VIEW_MORE_PREVIOUS_ORDERS = 'viewMorePreviousOrders',
  VIEW_ORDER = 'viewOrder',
  VIEW_ORDER_FILES = 'viewOrderFiles',
  VIEW_ORDER_ON_CALENDAR = 'viewOrderOnCalendar',
  VIEW_ORDER_ON_HOMEPAGE = 'viewOrderOnHomePage',
  VIEW_REPORT = 'viewReport',
}
