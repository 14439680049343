import { IBusinessSolution } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-menu/share/models/interfaces/business-solution.interface';
import { IServiceType } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-menu/share/models/interfaces/service-type.interface';
import { IDynamicBooking, IDynamicBookingMenus } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/dynamic-booking-menus.interface';
import { AsideService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.service';
import { DialogInProgressComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-in-progress/dialog-in-progress.component';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { EBusinessSolution } from '@aca-new/app/shared/models/enums/business-solution.enum';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { QimaDividerService } from '@aca-new/app/shared/services/exported-services/qima-divider/qima-divider.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaButtonIconStyle, EQimaButtonSize, EQimaDividerOrientation, EQimaDividerStyle, EQimaIconName, QimaOptionalType } from '@qima/ngx-qima';
import { has } from 'lodash/index';

@UntilDestroy()
@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideMenuComponent implements OnInit, OnChanges {
  /**
   * @description
   * Show aside menu or not
   * @type {boolean}
   * @default false
   */
  @Input('asideMenuIsVisible')
  public isVisible: boolean = false;

  /**
   * @description
   * Dynamic booking menus
   * @type {IDynamicBookingMenus}
   * @default {}
   */
  @Input('asideMenuDynamicBookingMenus')
  public dynamicBookingMenus: IDynamicBookingMenus = {};

  public isAcaFrameAvailable: boolean = this._acaMessageService.isAcaFrameAvailable;
  public isExpanded: boolean = false;
  public isAsideCollapsed: boolean = false;
  public serviceIcon: string = IMAGES.upcomingIcon;
  public title: string = 'APP_BOOK_SUB_MENU.TITLE';
  public closeButtonIconStyle: EQimaButtonIconStyle = EQimaButtonIconStyle.TERTIARY;
  public closeIconName: EQimaIconName = EQimaIconName.CLOSE;
  public closeButtonSize: EQimaButtonSize = EQimaButtonSize.SMALL;
  public dividerOrientation = EQimaDividerOrientation.HORIZONTAL;

  public businessSolutions: IBusinessSolution[] = [
    {
      id: EBusinessSolution.INSPECTIONS,
      label: 'APP_ASIDE.ASIDE_MENU.PRODUCT_INSPECTIONS',
      isVisible: !this._appUserSettingService.getIsWqsEnabled(),
      serviceTypes: [
        {
          id: EServiceType.PSI,
          abbreviation: 'PSI',
          label: 'APP_ASIDE.ASIDE_MENU.PRE_SHIPMENT_INSPECTION',
          path: '/book-inspection/psi/null',
        },
        {
          id: EServiceType.PEO,
          abbreviation: 'PEO',
          label: 'APP_ASIDE.ASIDE_MENU.PRE_CUSTOMER_CLEARANCE_INSPECTION',
          path: '/book-inspection/peo/null',
        },
        {
          id: EServiceType.CLC,
          abbreviation: 'CLC',
          label: 'APP_ASIDE.ASIDE_MENU.CONTAINER_LOADING_CHECK',
          path: '/book-inspection/clc/null',
        },
        {
          id: EServiceType.DUPRO,
          abbreviation: 'DUPRO',
          label: 'APP_ASIDE.ASIDE_MENU.DURING_PRODUCTION_INSPECTION',
          path: '/book-inspection/dupro/null',
          isVisible: !this._appUserSettingService.getIsQimaWQS(),
        },
        {
          id: EServiceType.SP,
          abbreviation: 'SC',
          label: 'APP_ASIDE.ASIDE_MENU.SAMPLE_COLLECTION',
          path: '/book-inspection/sp/null',
        },
        {
          id: EServiceType.PM,
          abbreviation: 'PM',
          label: 'APP_ASIDE.ASIDE_MENU.PRODUCTION_MONITORING',
          path: '/book-inspection/pm/null',
          isVisible: !this._appUserSettingService.getIsQimaWQS(),
        },
        {
          id: EServiceType.IPC,
          abbreviation: 'IPC',
          label: 'APP_ASIDE.ASIDE_MENU.INITIAL_PRODUCTION_CHECK',
          path: '/book-inspection/ipc/null',
          isVisible: !this._appUserSettingService.getIsQimaWQS(),
        },
        {
          id: EServiceType.SR,
          abbreviation: 'SR',
          label: 'APP_ASIDE.ASIDE_MENU.SAMPLE_REVIEW',
          path: '/book-inspection/sr/null',
        },
      ],
    },
    {
      id: EBusinessSolution.LAB_TESTING,
      label: 'APP_ASIDE.ASIDE_MENU.TESTING',
      isVisible: !this._appUserSettingService.getIsWqsEnabled(),
      serviceTypes: [
        {
          id: EServiceType.LT,
          abbreviation: 'LT',
          label: 'APP_ASIDE.ASIDE_MENU.BOOK_LAB_TEST',
          path: this._appUserSettingService.getIsDisableLtInquiry() ? '/book-lab-test' : '/book-lt',
        },
      ],
    },
    {
      id: EBusinessSolution.CERTIFICATIONS,
      label: 'APP_ASIDE.ASIDE_MENU.CERTIFICATIONS_AND_VOC',
      isVisible: !this._appUserSettingService.getIsWqsEnabled(),
      serviceTypes: [
        {
          id: EServiceType.SABER,
          abbreviation: 'SABER',
          label: 'APP_ASIDE.ASIDE_MENU.SABER_CERTIFICATION',
          path: '/book-certification/saber',
        },
        {
          id: EServiceType.GMARK,
          abbreviation: 'GMARK',
          label: 'APP_ASIDE.ASIDE_MENU.G_MARK',
          path: '/book-certification/g-mark',
        },
        {
          id: EServiceType.SASOREGISTRATION,
          abbreviation: 'SASO',
          label: 'APP_ASIDE.ASIDE_MENU.SASO_REGISTRATION',
          path: '/book-certification/saso-registration',
        },
      ],
    },
    {
      id: EBusinessSolution.AUDITS,
      label: 'APP_ASIDE.ASIDE_MENU.SUPPLIER_AUDITS',
      serviceTypes: [
        {
          id: EServiceType.EA,
          abbreviation: 'EA',
          label: 'APP_ASIDE.ASIDE_MENU.ETHICAL_AUDIT',
          path: '/book-audit/ea',
        },
        {
          id: EServiceType.MA,
          abbreviation: 'MA',
          label: 'APP_ASIDE.ASIDE_MENU.MANUFACTURING',
          path: '/book-audit/ma',
          isVisible: !this._appUserSettingService.getIsWqsEnabled(),
        },
        {
          id: EServiceType.DR,
          abbreviation: 'DR',
          label: 'APP_ASIDE.ASIDE_MENU.DESKTOP_REVIEW',
          isVisible: !this._appUserSettingService.getIsWqsEnabled(),
          path: '/book-audit/dr',
        },
        {
          id: EServiceType.CTPAT,
          abbreviation: 'CTPAT',
          label: 'APP_ASIDE.ASIDE_MENU.C_TPAT',
          path: '/book-audit/ctpat',
          isVisible: !this._appUserSettingService.getIsQimaWQS() && !this._appUserSettingService.getIsWqsEnabled(),
        },
        {
          id: EServiceType.STRA,
          abbreviation: 'STRA',
          label: 'APP_ASIDE.ASIDE_MENU.STRUCTURAL_AUDIT',
          path: '/book-audit/stra',
          isVisible: !this._appUserSettingService.getIsQimaWQS() && !this._appUserSettingService.getIsWqsEnabled(),
        },
        {
          id: EServiceType.ENVA,
          abbreviation: 'ENVA',
          label: 'APP_ASIDE.ASIDE_MENU.ENVIRONMENTAL_AUDIT',
          path: '/book-audit/enva',
          isVisible: !this._appUserSettingService.getIsWqsEnabled(),
        },
        {
          id: EServiceType.GMP,
          abbreviation: 'GMP',
          label: 'APP_ASIDE.ASIDE_MENU.GOOD_MANUFACTURING',
          path: '/book-audit/gmp',
          isVisible: !this._appUserSettingService.getAuditBookingMenusInvisibility()?.GMP && !this._appUserSettingService.getIsWqsEnabled(),
        },
        {
          id: EServiceType.GHP,
          abbreviation: 'GHP',
          label: 'APP_ASIDE.ASIDE_MENU.GOOD_HYGIENE',
          path: '/book-audit/ghp',
          isVisible: !this._appUserSettingService.getAuditBookingMenusInvisibility()?.GHP && !this._appUserSettingService.getIsWqsEnabled(),
        },
      ],
    },
    {
      id: EBusinessSolution.WQS,
      label: 'APP_ASIDE.ASIDE_MENU.WQS',
      isVisible: this._appUserSettingService.getIsWqsEnabled() && this.isAcaFrameAvailable,
      serviceTypes: [
        {
          id: EServiceType.GGAP,
          abbreviation: 'GGAP',
          label: 'APP_ASIDE.ASIDE_MENU.GGAP',
          path: '/book-wqs/ggap',
          isVisible: this._appUserSettingService.getIsWqsEnabled() && this.isAcaFrameAvailable,
        },
      ],
    },
  ];

  public serviceTypesActive: IServiceType[] = [];
  public asideMenuBusinessSolutionIndex: QimaOptionalType<number> = undefined;
  public asideMenuServiceTypeIndex: QimaOptionalType<number> = undefined;
  public dividerStyle: EQimaDividerStyle = EQimaDividerStyle.PEARL;

  public constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _asideService: AsideService,
    private readonly _router: Router,
    private readonly _dialog: DialogService,
    private readonly _acaMessageService: AcaMessageService,
    public readonly qimaDivider: QimaDividerService
  ) {}

  public ngOnInit(): void {
    this._watch();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isAcaFrameAvailable) {
      this._onDynamicBookingMenusChange(changes);
    }
  }

  public onCloseClick(): void {
    this._closeAsideMenu();
  }

  public onAsideMenuBusinessSolutionClick(index: Readonly<number>): void {
    this.asideMenuBusinessSolutionIndex = index;
    this.serviceTypesActive = this.businessSolutions[index].serviceTypes.filter((item: IServiceType): boolean => item.isVisible !== false);
    this.isExpanded = true;
  }

  public onAsideMenuServiceTypeClick(index: Readonly<number>, item: Readonly<IServiceType>): void {
    this.asideMenuServiceTypeIndex = index;

    if (item.path) {
      this._asideService.updateAsideMenuVisible(false);

      if (this.isAcaFrameAvailable) {
        this._acaMessageService.informAcaPathChange(item.path);

        return;
      }

      // Render the component after the route change
      // for example: from /book-inspection/psi/xxx to book-inspection/psi/null
      void this._router.navigateByUrl('/', { skipLocationChange: true }).then((canLeave: boolean): void => {
        if (canLeave) {
          void this._router.navigate([item.path]);
        }
      });
    } else {
      this._dialog.open(DialogInProgressComponent);
    }
  }

  private _watch(): void {
    this._asideService.isAsideCollapsed$.pipe(untilDestroyed(this)).subscribe((isAsideCollapsed: boolean): void => {
      this.isAsideCollapsed = isAsideCollapsed;
      this._changeDetectorRef.markForCheck();
    });
  }

  private _closeAsideMenu(): void {
    this._asideService.updateAsideMenuVisible(false);
  }

  private _onDynamicBookingMenusChange(changes: SimpleChanges): void {
    if (has(changes, 'dynamicBookingMenus') && changes.dynamicBookingMenus.currentValue !== changes.dynamicBookingMenus.previousValue) {
      if (this.isAcaFrameAvailable) {
        this._updateBusinessSolutions();
      }
    }
  }

  private _updateBusinessSolutions(): void {
    const keys = Object.keys(this.dynamicBookingMenus);

    this.businessSolutions = this.businessSolutions.filter((item: IBusinessSolution): boolean => !keys.includes(item.label) && item.isVisible !== false);
    keys.forEach((key: string): void => {
      const serviceTypes: IServiceType[] = [];

      this.dynamicBookingMenus[key].forEach((menu: IDynamicBooking): void => {
        serviceTypes.push({
          id: menu.serviceSimpleName.toLocaleLowerCase() as EServiceType,
          // If the backend changes CSM to WCP, please update the following code
          label: `${menu.serviceName}`,
          path: `/booking/${key.toLocaleLowerCase()}/${menu.serviceSimpleName.toLocaleLowerCase()}`,
          abbreviation: menu.serviceSimpleName === 'CSM' ? 'WCP' : menu.serviceSimpleName,
        });
      });

      if (!this._appUserSettingService.getEnableFoodCertification() && this.isAcaFrameAvailable) {
        this.businessSolutions.push({
          id: key as EBusinessSolution,
          label: key,
          serviceTypes,
        });
      }
    });
    this._changeDetectorRef.markForCheck();
  }
}
