import { MainComponent } from '@aca-new/app/pages/main/main.component';
import { AsideModule } from '@aca-new/app/pages/main/shared/components/aside/aside.module';
import { HeaderModule } from '@aca-new/app/pages/main/shared/components/header/header.module';
import { OverlayModule } from '@aca-new/app/shared/components/overlay/overlay.module';
import { AppCommonPipeModule } from '@aca-new/app/shared/pipes/app-common-pipe.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MainComponent],
  imports: [HeaderModule, AsideModule, RouterModule, OverlayModule, CommonModule, AppCommonPipeModule],
})
export class MainModule {}
