export enum EServiceTypeNumber {
  CERTIFICATION = '70',
  CLC = '4',
  CTPAT = '51',
  DR = '12',
  DUPRO = '3',
  EA = '9',
  ENVA = '10',
  FLT = '40',
  FPSI = '20',
  GFSI = '32',
  GHP = '30',
  GMP = '31',
  IPC = '2',
  LT = '11',
  MA = '5',
  PEO = '7',
  PM = '6',
  PSI = '1',
  QIMAONE = '200',
  SASO = '60',
  SC = '21',
  SR = '8',
  STRA = '91',
  TRAINING = '80',
}
