<div
  *ngIf="cellData && !multipleLines.length"
  class="multiple-label-cell"
  qimaAutoTooltip
  qimaAutoTooltipHasArrow
  [qimaAutoTooltipText]="cellData.label"
>
  <span>{{ cellData.label || '---' }}</span>
</div>

<div
  *ngIf="cellData && multipleLines.length"
  class="multiple-label-cell"
>
  <div
    class="multiple-label-cell__label"
    qimaTooltip
    [qimaTooltipStyle]="qimaTooltip.style.DARK"
    [qimaTooltipPosition]="qimaTooltip.position.TOP_LEFT"
    [qimaTooltipTemplate]="lineTooltip"
    [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
    [qimaTooltipHasArrow]="true"
  >
    {{ cellData.value || '' | translate: { account: multipleLines.length } }}
  </div>
</div>

<ng-template #lineTooltip>
  <div
    *ngFor="let line of multipleLines"
    appNgForTrackByUuid
  >
    {{ line }}
  </div>
</ng-template>
