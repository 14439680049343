<div
  class="app-book-now"
  qimaTooltip
  [qimaTooltipText]="'APP_ASIDE.BOOK_NOW' | translate"
  [qimaTooltipIsVisible]="isCollapsed"
  [qimaTooltipHasArrow]="true"
  [qimaTooltipPosition]="tooltipPosition"
  (click)="onClick($event)"
  (keydown.enter)="onEnter($event)"
  [class.app-book-now--checked]="isChecked"
>
  <div
    class="app-book-now__container"
    [class.app-book-now__container--collapsed]="isCollapsed"
  >
    <qima-icon
      class="app-book-now__container__icon"
      [qimaIconName]="qimaIcon.iconName.INSPECT"
    ></qima-icon>
    <span
      *ngIf="!isCollapsed"
      class="label"
      >{{ 'APP_ASIDE.BOOK_NOW' | translate }}</span
    >
    <qima-icon
      *ngIf="!isCollapsed"
      [qimaIconName]="qimaIcon.iconName.CHEVRON_RIGHT"
      [qimaIconSize]="qimaIcon.sizeSmall"
    ></qima-icon>
  </div>
</div>
