export enum ECertificateType {
  STANDARD = 0,
  CERTIFICATE_FOR_DELIVERY = 1,
  FOSCHINI = 2,
  WOOLWORTH = 3,
  AVON_US = 4,
  BEST_LESS = 5,
  DUNELM_MILL = 6,
  OFFICE_DEPOT = 7,
  ACE = 8,
}
