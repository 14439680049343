import { NpsSurveyComponent } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/nps-survey.component';
import { ENpsSurveySource } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/enums/nps-survey-source.enum';
import { NpsSurveyService } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/services/nps-survey.service';
import { PaymentFailComponent } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/components/payment-fail/payment-fail.component';
import { PaymentSuccessComponent } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/components/payment-success/payment-success.component';
import { IPaymentData } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-data.interface';
import { IPaymentDialogData } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-dialog-data.interface';
import {
  IPaymentMethod,
  IPaymentMethodBody,
} from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-method.interface';
import { IPaymentProcessData } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-process-data.interface';
import {
  IPaymentOnSubmitDropin,
  IPaymentStateData,
  IPaymentSubmitData,
} from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-submit-data.interface';
import { IPaymentPay } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/payment.interface';
import { PaymentService } from '@aca-new/app/pages/payments/pages/payments-content/shared/services/payment/payment.service';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EPaymentType } from '@aca-new/app/shared/models/enums/payment-type.enum';
import { EServiceTypeNumber } from '@aca-new/app/shared/models/enums/service-type-number.enum';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { HttpResponseBodyNullableType } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { QimaDividerService } from '@aca-new/app/shared/services/exported-services/qima-divider/qima-divider.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import AdyenCheckout from '@adyen/adyen-web';
import { ChangeDetectionStrategy, Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { invert } from 'lodash/index';

@UntilDestroy()
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaymentService],
})
export class PaymentMethodComponent implements OnInit {
  private _paymentType: EPaymentType | undefined = undefined;
  private readonly _orderId: string | undefined = this.orderData.commonPaymentOrderId;
  private readonly _bu: string | undefined = this.orderData.commonPaymentBu;

  public constructor(
    public readonly qimaDivider: QimaDividerService,
    private readonly _router: Router,
    private readonly _dialogRef: DialogRef<void>,
    private readonly _paymentService: PaymentService,
    private readonly _dialog: DialogService,
    private readonly _storageService: StorageService,
    private readonly _ngZone: NgZone,
    private readonly _npsSurveyService: NpsSurveyService,
    @Inject(DIALOG_DATA) public readonly orderData: IPaymentDialogData,
    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {}

  public ngOnInit(): void {
    this._initData();
  }

  public close(): void {
    this._dialogRef.close();
  }

  private _openSuccessDialog(): void {
    const ids: string[] = [];
    let { orderNo } = this.orderData;

    if (this.orderData.serviceType !== EServiceType.LT) {
      this.orderData.orderFees.forEach((item): void => {
        if (item.orderId && item.adyenRefNumber) {
          orderNo = orderNo ? orderNo : item.adyenRefNumber; // fetch the first one to track .
          ids.push(item.adyenRefNumber);
        }
      });
    }

    this._ngZone.run((): void => {
      this._dialog.open(PaymentSuccessComponent, { data: ids.join(';') });
    });

    this.close();

    // if orderNo is empty,it's trigger by aca payment dialog and tansform data to myqima and so it can use the aca's NPS
    // else it's trigger by myqima's payment module
    if (orderNo) {
      this._npsSurveyService
        .getIsSurveyVisible$()
        .pipe(untilDestroyed(this))
        .subscribe((canOpen: boolean): void => {
          if (!canOpen) {
            return;
          }

          this._dialog.open(NpsSurveyComponent, {
            data: { orderNo, serviceType: invert(EServiceTypeNumber)[this.orderData.serviceType] || this.orderData.serviceType, npsFrom: ENpsSurveySource.PAY_SUCCESS },
            stopCloseWhenClickBackdrop: true,
          });
        });
    }
    // open survey
  }

  private _processPayment(paymentData: IPaymentProcessData): void {
    const { amount, paymentMethod, type, orderFees, dropin } = paymentData;
    const body: IPaymentData = {
      userId: this._storageService.getItem(EStorageKeys.USER_ID),
      orderType: type,
      paymentRequest: {
        amount,
        paymentMethod,
      },
      paymentPaidBean: {
        orders: orderFees,
        paymentGateway: 'Adyen',
        status: 'Completed',
      },
    };

    if (paymentMethod.type === 'alipay') {
      // TODO  need to add handle-alipay-redirect page
      body.paymentRequest.returnUrl = `${this._window.location.origin}/payments/handle-alipay-redirect`;
    }

    this._markAdyenPay(body, dropin);
  }

  private _getAdyenPaymentMethods(requestData: IPaymentMethodBody): void {
    this._paymentService
      .getAdyenPaymentMethods$(requestData, this._paymentType)
      .pipe(untilDestroyed(this))
      .subscribe((data: HttpResponseBodyNullableType<IPaymentMethod>): void => {
        if (!data?.content || !data?.content.originKey) {
          return;
        }

        const checkout = new AdyenCheckout({
          locale: 'en_US',
          openFirstPaymentMethod: false,
          environment: data.content.environment,
          originKey: data.content.originKey,
          paymentMethodsResponse: { paymentMethods: data.content.paymentMethods.paymentMethods },
          amount: requestData.amount,
        });
        const paymentService = this._paymentService;

        checkout
          .create('dropin', {
            onSubmit(state: IPaymentStateData, dropin: IPaymentOnSubmitDropin): void {
              paymentService.paymentSubmitData$.next({ state, dropin });
            },
          })
          .mount('#dropin');
      });
  }

  private _markAdyenPay(body: IPaymentData, dropin: IPaymentOnSubmitDropin): void {
    this._paymentService
      .payment$(body, this._paymentType)
      .pipe(untilDestroyed(this))
      .subscribe((data: HttpResponseBodyNullableType<IPaymentPay>): void => {
        if (!data?.content || data?.content.refusalReason || !data?.content.resultCode) {
          this._ngZone.run((): void => {
            this._dialog.open(PaymentFailComponent);
          });

          return;
        }

        if (data?.content.resultCode === 'RedirectShopper') {
          dropin.handleAction(data?.content.action);
        } else {
          if (this._paymentType === EPaymentType.COMMON_PAYMENT && this._orderId) {
            void this._router.navigate([`/pay/${this._bu}/${this._orderId}/result`]);
            this.close();
          } else {
            this._openSuccessDialog();
          }
        }
      });
  }

  private _initData(): void {
    const { paymentType, merchantAccount, currency, totalFees } = this.orderData;

    this._paymentType = paymentType;
    const requestData: IPaymentMethodBody = {
      amount: {
        currency: currency || 'USD',
        value: totalFees * 100,
      },
      channel: 'Web',
      merchantAccount,
    };

    this._paymentService.paymentSubmitData$.pipe(untilDestroyed(this)).subscribe((data: IPaymentSubmitData): void => {
      let type: string = EPaymentType.GENERAL;

      if (paymentType === EPaymentType.COMMON_PAYMENT) {
        type = EPaymentType.COMMON_PAYMENT;
      }

      if (this.orderData.serviceType === EServiceType.LT) {
        type = EPaymentType.LT;
      }

      const processData: IPaymentProcessData = {
        paymentMethod: data.state.data.paymentMethod,
        amount: requestData.amount,
        orderFees: this.orderData.orderFees,
        dropin: data.dropin,
        type,
      };

      this._processPayment(processData);
    });

    this._getAdyenPaymentMethods(requestData);
  }
}
