import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {
  public intercept<T>(request: Readonly<HttpRequest<T>>, next: Readonly<HttpHandler>): Observable<HttpEvent<T>> {
    /**
     * @description
     * An Angular HTTP Interceptor that can intercept HTTP requests and optionally alter the response.
     * It provides a way to inject custom functionality (in this case, mocking response data) into the Http handling process.
     * @example
     * if (request.url.includes('/demo')) {
     *   return of(
     *     new HttpResponse({
     *       status: 200,
     *       body: {
     *         message: null,
     *         // content: {} response data
     *       },
     *     })
     *   ) as Observable<HttpEvent<T>>;
     * }
     */
    return next.handle(request);
  }
}
