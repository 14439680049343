import { AI_API_ACCESS_TOKEN, AI_API_CALLER_IDS } from '@aca-new/app/shared/constants/ai-api-access-token.constants';
import { EPaymentType } from '@aca-new/app/shared/models/enums/payment-type.enum';
import { HttpHeaders } from '@angular/common/http';

export const PAYMENT_HEADERS_MAP: Record<string, HttpHeaders> = {
  [EPaymentType.COMMON_PAYMENT]: new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'ai-api-access-token': AI_API_ACCESS_TOKEN,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'ai-api-caller-id': AI_API_CALLER_IDS.COMMON_PAYMENT,
  }),
};
