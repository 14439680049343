import { IScriptAttributes } from '@aca-new/app/shared/interfaces/script-attributes.interface';
import { EQimaMimeType } from '@qima/ngx-qima';

export abstract class ScriptLoaderService {
  protected _data: IScriptAttributes = { src: '' };
  protected _isLoaded: boolean = false;

  protected _loadScript(): Promise<void> {
    return new Promise((resolve, reject): void => {
      const script = document.createElement('script');

      script.src = this._data.src;
      script.type = this._data.type ?? EQimaMimeType.TEXT_JS;
      script.id = this._data.id ?? '';
      script.async = this._data.async ?? false;
      script.defer = this._data.defer ?? false;
      script.onload = (): void => {
        this._isLoaded = true;
        resolve();
      };

      script.onerror = (error: Event | string): void => reject(new Error(`Script load error for ${this._data.src}: ${error?.toString()}`));
      document.body.appendChild(script);
    });
  }
}
