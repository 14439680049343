import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public constructor(public readonly httpClient: HttpClient) {}
}

@Injectable({
  providedIn: 'root',
})
export class SkipInterceptorService {
  public readonly httpClient: HttpClient = new HttpClient(this._handler);

  public constructor(private readonly _handler: HttpBackend) {}
}
