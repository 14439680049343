import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { has } from 'lodash/index';

@Component({
  selector: 'app-multiple-label-cell',
  templateUrl: './multiple-label-cell.component.html',
  styleUrls: ['./multiple-label-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleLabelCellComponent implements OnChanges {
  /**
   * @description
   * the data of the cell
   * @type {ITableBodyCell}
   * @default undefined
   */
  @Input('multipleLabelCellCellData')
  public cellData: QimaOptionalType<ITableBodyCell> = undefined;

  public multipleLines: string[] = [];

  public constructor(public readonly qimaTooltip: QimaTooltipService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (has(changes, 'cellData') && changes.cellData.currentValue !== changes.cellData.previousValue) {
      this._initData();
    }
  }

  private _initData(): void {
    this.multipleLines = [];

    if (this.cellData?.label?.includes('###')) {
      this.multipleLines = this.cellData.label.split('###').filter((item: string): boolean => item !== '');
    }
  }
}
