/* eslint-disable no-magic-numbers */
export const TABLE_PAGINATION = {
  pageSize5: 5,
  pageSize10: 10,
  pageSize20: 20,
  pageSize25: 25,
  pageSize50: 50,
  pageSize100: 100,
  pageSize150: 150,
  pageSize200: 200,
  pageSize250: 250,
};
