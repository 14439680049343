<div class="nps-survey">
  <app-dialog-header
    [dialogHeaderTitle]="'NPS_SURVEY.MODAL_TITLE'"
    (dialogHeaderClose)="onCloseClick()"
  >
  </app-dialog-header>

  <div class="nps-survey__header">{{ 'NPS_SURVEY.RATING' | translate }}</div>

  <qima-input-radio-group
    data-cy="nps-survey"
    [(qimaInputRadioGroupValue)]="ratingScore"
    [qimaInputRadioGroupPlacement]="qimaInputRadioGroup.placement.HORIZONTAL"
  >
    <qima-input-group
      style="justify-items: center"
      [class.qima-mr-xl]="!last"
      [qimaInputGroupPlacement]="qimaInputRadioGroup.groupPlacement.VERTICAL"
      *ngFor="let item of ratingScores; let last = last"
      appNgForTrackByUuid
    >
      <qima-input-radio [qimaInputRadioValue]="item"></qima-input-radio>
      <qima-input-label [qimaInputLabel]="item + ''"></qima-input-label>
    </qima-input-group>
  </qima-input-radio-group>

  <div class="nps-survey__ratings-labels">
    <div>{{ 'NPS_SURVEY.RATINGS_LABELS.NOT_LIKELY' | translate }}</div>
    <!--    <div>{{ 'NPS_SURVEY.RATINGS_LABELS.NEUTRAL' | translate }}</div>-->
    <div>{{ 'NPS_SURVEY.RATINGS_LABELS.EXTREMELY_LIKELY' | translate }}</div>
  </div>

  <ng-container *ngIf="ratingScore !== undefined && ratingScore < 7">
    <div class="nps-survey__header">{{ 'NPS_SURVEY.LOW_RATING_LABEL' | translate }}</div>
    <div class="nps-survey__low-rating">
      <div
        class="nps-survey__low-rating__card"
        [class.nps-survey__low-rating__card--active]="lowRatingReason === item"
        *ngFor="let item of lowRatingReasons"
        appNgForTrackByUuid
        (click)="onLowRatingReasonClick(item)"
      >
        {{ 'NPS_SURVEY.LOW_RATING.REASON_' + item | translate }}
      </div>
    </div>
  </ng-container>

  <div class="nps-survey__header">{{ 'NPS_SURVEY.FEEDBACK' | translate }}</div>

  <qima-input-textarea
    class="nps-survey__textarea"
    [(ngModel)]="feedback"
    [qimaInputTextareaSize]="qimaInput.inputSize.LARGE"
    [qimaInputTextareaHasValidators]="true"
    [qimaInputTextareaMaxLength]="3600"
    [qimaInputTextareaPlaceholder]="'NPS_SURVEY.PLACEHOLDER' | translate"
  ></qima-input-textarea>

  <div class="nps-survey__footer">
    <div class="nps-survey__footer__buttons">
      <qima-button
        class="qima-mr-s"
        [qimaButtonLabel]="'COMMON.NOT_NOW' | translate"
        [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        (qimaButtonClick)="onCloseClick()"
      ></qima-button>
      <qima-button
        class="submit"
        [qimaButtonLabel]="'COMMON.SUBMIT' | translate"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonIsDisabled]="ratingScore === undefined"
        (qimaButtonClick)="onSubmitClick()"
      ></qima-button>
    </div>
  </div>
</div>
