import { Injectable } from '@angular/core';
import { EQimaColorBrand, EQimaColorCherry, EQimaColorDecorative, EQimaColorKiwi, EQimaColorLemon, EQimaColorNeutral, EQimaColorOrange, EQimaColorPool } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaColorService {
  public readonly brand = EQimaColorBrand;
  public readonly cherry = EQimaColorCherry;
  public readonly decorative = EQimaColorDecorative;
  public readonly kiwi = EQimaColorKiwi;
  public readonly lemon = EQimaColorLemon;
  public readonly neutral = EQimaColorNeutral;
  public readonly orange = EQimaColorOrange;
  public readonly pool = EQimaColorPool;
}
