<div class="payment-success">
  <app-dialog-header (dialogHeaderClose)="close()"> </app-dialog-header>

  <div class="payment-success__container">
    <span class="payment-success__container__title">{{ 'PAYMENTS.PAYMENT_SUCCESS' | translate }}</span>
    <span class="payment-success__container__result">{{ 'PAYMENTS.THANKS_PAYMENT' | translate }}</span>

    <!-- TODO need to a new logic to deal with orderID's display -->
    <span
      *ngIf="false"
      class="payment-success__container__result"
      >{{ 'PAYMENTS.PAYMENT_LIST' | translate: { id: ids } }}</span
    >
  </div>

  <qima-button
    class="qima-mt-l"
    [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
    [qimaButtonLabel]="'PAYMENTS.OK' | translate"
    [qimaButtonSize]="qimaButton.buttonSize.LARGE"
    (qimaButtonClick)="close()"
  ></qima-button>
</div>
