import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EQimaBadgeStyle } from '@qima/ngx-qima';
import { isString } from 'lodash/index';

@Injectable({
  providedIn: 'root',
})
export class BadgeStyleService {
  public constructor(private readonly _translateService: TranslateService) {}

  public getBadgeConfig(result: string | number, type: string): string {
    // TODO: the label won't change after language change
    let label: string = this._translateService.instant('REPORTS.VIEW_REPORT.NA');
    let style: EQimaBadgeStyle = EQimaBadgeStyle.SMOKE;

    if (isString(result)) {
      switch (result.toUpperCase()) {
        case 'PASSED':
        case 'PASS':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.PASS');
          style = EQimaBadgeStyle.KIWI_LIGHT;
          break;
        case 'APPROVED':
          label = this._translateService.instant('SUPPLIER_DOCUMENTS.RESULTS.APPROVED');
          style = EQimaBadgeStyle.KIWI_LIGHT;
          break;
        case 'FAILED':
        case 'FAIL':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.FAIL');
          style = EQimaBadgeStyle.CHERRY;
          break;
        case 'REJECTED':
          label = this._translateService.instant('SUPPLIER_DOCUMENTS.RESULTS.REJECTED');
          style = EQimaBadgeStyle.CHERRY;
          break;
        case 'PENDING':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.PENDING');
          style = EQimaBadgeStyle.SEAL;
          break;
        case 'EXEMPTED':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.EXEMPTED');
          style = EQimaBadgeStyle.SEAL;
          break;
        case 'FINISHED':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.FINISHED');
          style = EQimaBadgeStyle.KIWI_LIGHT;
          break;
        case 'PASS WITH INFORMATION':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.PASS_WITH_INFORMATION');
          style = EQimaBadgeStyle.KIWI_LIGHT;
          break;
        case 'FAIL WITH INFORMATION':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.FAIL_WITH_INFORMATION');
          style = EQimaBadgeStyle.CHERRY;
          break;
        case 'INCONCLUSIVE':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.INCONCLUSIVE');
          style = EQimaBadgeStyle.CHERRY;
          break;
        case 'INCONCLUSIVE WITH INFORMATION':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.INCONCLUSIVE_WITH_INFORMATION');
          break;
        case 'INFORMATION ONLY':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.INFORMATION_ONLY');
          break;
        case 'FAIL-ACCEPTED':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.FAIL_ACCEPTED');
          break;
        case 'CONDITIONAL PASS':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.CONDITIONAL_PASS');
          style = EQimaBadgeStyle.KIWI_LIGHT;
          break;
        case 'MARGINAL':
          label = this._translateService.instant('REPORTS.VIEW_REPORT.MARGINAL');
          break;
      }
    }

    return type === 'label' ? label : style;
  }
}
