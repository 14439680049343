import { INTERNAL_DOMAINS } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class DomainGuard implements CanActivate {
  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _router: Router
  ) {}

  public canActivate(): boolean | Promise<boolean> {
    const domain = this._window.location.hostname;

    if (!INTERNAL_DOMAINS.some((item: string): boolean => domain.includes(item))) {
      void this._router.navigate(['/404']);

      return false;
    }

    return true;
  }
}
