<div
  class="app-form-group"
  [class.app-form-group--vertical]="orientation === appForm.formItemOrientation.VERTICAL"
  [class.qima-mb-l]="hasDistance"
>
  <div
    class="app-form-group__label"
    [style.width.px]="labelWidth"
  >
    <ng-content select=".form-group-label"></ng-content>
  </div>
  <div
    class="app-form-group__input"
    [style.width.px]="inputWidth"
    [ngClass]="['app-form-group__input--size-' + size]"
  >
    <div class="app-form-group__input__content">
      <ng-content select=".form-group-input"></ng-content>
      <app-form-validation-message
        [formValidationMessageControl]="control"
        [formValidationFormGroup]="formGroup"
        [formValidationMessages]="validationMessages"
      ></app-form-validation-message>
    </div>
  </div>
</div>
