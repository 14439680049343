import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { EQimaIceCubeStyle } from '@qima/ngx-qima';

@Pipe({
  name: 'serviceTypeIceCubeStyle',
  pure: true,
})
@Injectable({
  providedIn: 'root',
})
export class ServiceTypeIceCubeStylePipe implements PipeTransform {
  public transform(label: string): EQimaIceCubeStyle {
    const iceCubeStyles: Record<string, EQimaIceCubeStyle> = {
      [EServiceType.PSI]: EQimaIceCubeStyle.LEMON,
      [EServiceType.DUPRO]: EQimaIceCubeStyle.MANDARIN,
      [EServiceType.IPC]: EQimaIceCubeStyle.ORANGE,
      [EServiceType.CLC]: EQimaIceCubeStyle.MELON,
      [EServiceType.PEO]: EQimaIceCubeStyle.PEACH,
      [EServiceType.PM]: EQimaIceCubeStyle.GUAVA,
      [EServiceType.SR]: EQimaIceCubeStyle.GRAPES,
      [EServiceType.SC]: EQimaIceCubeStyle.BEETROOT,
      [EServiceType.MA]: EQimaIceCubeStyle.AVOCADO,
      [EServiceType.EA]: EQimaIceCubeStyle.FOREST,
      [EServiceType.ENVA]: EQimaIceCubeStyle.OLIVE,
      [EServiceType.STRA]: EQimaIceCubeStyle.WATERLILY,
      [EServiceType.CTPAT]: EQimaIceCubeStyle.ELDERBERRY,
      [EServiceType.DR]: EQimaIceCubeStyle.BLUEBERRY,
      [EServiceType.GMP]: EQimaIceCubeStyle.AUBERGINE,
      [EServiceType.GHP]: EQimaIceCubeStyle.PLUM,
      [EServiceType.SABER]: EQimaIceCubeStyle.BEETROOT,
      [EServiceType.GMARK]: EQimaIceCubeStyle.CABBAGE,
      [EServiceType.SASO]: EQimaIceCubeStyle.GRAPES,
      [EServiceType.ILI]: EQimaIceCubeStyle.MELON,
      [EServiceType.LT]: EQimaIceCubeStyle.LEMON_900,
      [EServiceType.STS]: EQimaIceCubeStyle.BEETROOT,
      [EServiceType.TSM]: EQimaIceCubeStyle.BEETROOT,
      [EServiceType.SAQ]: EQimaIceCubeStyle.CHERRY,
      [EServiceType.CSM]: EQimaIceCubeStyle.KIWI,
      [EServiceType.WCP]: EQimaIceCubeStyle.KIWI,
      [EServiceType.WQS]: EQimaIceCubeStyle.WATERLILY,

      // eslint-disable-next-line @typescript-eslint/naming-convention
      CTPAT: EQimaIceCubeStyle.ELDERBERRY,
    };

    return iceCubeStyles[label.toUpperCase()] || EQimaIceCubeStyle.SMOKE;
  }
}
