import { DataTableComponent } from '@aca-new/app/shared/components/data-table/data-table.component';
import { SuperMasterSelectorModule } from '@aca-new/app/shared/components/data-table/shared/components/super-master-selector/super-master-selector.module';
import { SectionTitleModule } from '@aca-new/app/shared/components/section-title/section-title.module';
import { TableModule } from '@aca-new/app/shared/components/table/table.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaDividerModule, QimaIconModule, QimaInputSelectModule, QimaMenuItemTextModule, QimaMenuModule, QimaPaginationModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [DataTableComponent],
  imports: [
    QimaDividerModule,
    QimaIconModule,
    FormsModule,
    TableModule,
    CommonModule,
    QimaMenuModule,
    TranslateModule,
    QimaInputSelectModule,
    QimaMenuItemTextModule,
    SectionTitleModule,
    QimaPaginationModule,
    SuperMasterSelectorModule,
  ],
  exports: [DataTableComponent],
})
export class DataTableModule {}
