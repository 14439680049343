import { ELoadingType } from '@aca-new/app/shared/models/enums/loading-type.enum';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppOverlayService {
  public readonly isLoading$: Subject<boolean> = new Subject<boolean>();
  public readonly loadingType = ELoadingType;

  public updateIsLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }
}
