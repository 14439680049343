import { BookFromPreviousOrderComponent } from '@aca-new/app/pages/bookings/pages/previous-order-item/components/book-from-previous-order/book-from-previous-order.component';
import { BusinessSolutionSelectModule } from '@aca-new/app/shared/components/business-solution-select/business-solution-select.module';
import { DataTableModule } from '@aca-new/app/shared/components/data-table/data-table.module';
import { DialogHeaderModule } from '@aca-new/app/shared/components/modal/shared/components/dialog-header/dialog-header.module';
import { TableModule } from '@aca-new/app/shared/components/table/table.module';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaButtonModule, QimaDividerModule, QimaInputSelectModule, QimaInputTextModule, QimaMenuItemTextModule, QimaMenuModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [BookFromPreviousOrderComponent],
  imports: [
    DialogHeaderModule,
    QimaInputTextModule,
    TranslateModule,
    FormsModule,
    QimaButtonModule,
    QimaDividerModule,
    TableModule,
    DataTableModule,
    CommonModule,
    QimaInputSelectModule,
    QimaMenuItemTextModule,
    QimaMenuModule,
    BusinessSolutionSelectModule,
    TrackByUuidDirective,
  ],
  exports: [BookFromPreviousOrderComponent],
})
export class BookFromPreviousOrderModule {}
