import { IPasswordRequest, IPasswordResponse } from '@aca-new/app/pages/account/shared/components/access-credentials/shared/models/interfaces/access-credentials.interface';
import { IBookingPreferencesRequest } from '@aca-new/app/pages/account/shared/components/booking-references/shared/models/interfaces/booking-preferences.interface';
import {
  ICompanyInformationForm,
  ICompanyLogoRequest,
} from '@aca-new/app/pages/account/shared/components/company-information/shared/models/interfaces/company-information.interface';
import { IContactDetailsRequest } from '@aca-new/app/pages/account/shared/components/contact-details/shared/models/interfaces/contact-details.interface';
import { IAccountMenuItem } from '@aca-new/app/pages/account/shared/models/interfaces/account-menu-item.interface';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IFileResponse } from '@aca-new/app/shared/interfaces/file-response.interface';
import { EBrokerType } from '@aca-new/app/shared/models/enums/broker-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountService {
  public constructor(
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService
  ) {}

  public geAccountMenuItems(): IAccountMenuItem[] {
    const user = this._appAuthenticationService.getCurrentUser();
    const isBroker = user.company?.brokerType === EBrokerType.BROKER;
    const isProduce = this._appUserSettingService.getIsQimaProduce();

    return [
      {
        label: 'ACCOUNT.MENU.ACCESS_CREDENTIALS',
        path: '/account/access-credentials',
      },
      {
        label: 'ACCOUNT.MENU.COMPANY_INFORMATION',
        path: '/account/company-information',
        isInvisible: isProduce || isBroker,
      },
      {
        label: 'ACCOUNT.MENU.CONTACT_DETAILS',
        path: '/account/contact-details',
        isInvisible: isProduce,
      },
      {
        label: 'ACCOUNT.MENU.BOOKINGS_PREFERENCES',
        path: '/account/booking-preferences',
        isInvisible: isProduce || isBroker,
      },
      {
        label: 'ACCOUNT.MENU.PRODUCT_CATEGORIES',
        path: '/account/product-categories',
        isInvisible: isProduce || isBroker,
      },
      {
        label: 'ACCOUNT.MENU.MY_DOCUMENTS',
        path: '/account/my-documents',
        isInvisible: isProduce,
      },
      {
        label: 'ACCOUNT.MENU.MARKETING_PREFERENCES',
        path: '/account/marketing-preferences',
        isInvisible: isProduce || isBroker,
      },
      {
        label: 'ACCOUNT.MENU.DELETE_ACCOUNT',
        path: '/account/delete-account',
        isInvisible: isProduce || isBroker,
      },
    ];
  }

  public getStatesByCountryName$(countryName: string): Observable<QimaNullableType<ISelect[]>> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<ISelect[]>>>(`${SERVER_URL}/parameter/v2/statesByCountryName/${countryName}`, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<ISelect[]>>>>): QimaNullableType<ISelect[]> => response?.body?.content || null));
  }

  public updateCompany$(companyInformation: ICompanyInformationForm): Observable<IUser> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .put<IUser>(
        `${SERVER_URL}/user/${userId}/company`,
        {
          ...companyInformation,
        },
        { observe: 'body' }
      )
      .pipe(map((response: IUser): IUser => response));
  }

  public updateBookingPreference$(bookingPreferences: IBookingPreferencesRequest): Observable<IUser> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .put<IUser>(
        `${SERVER_URL}/user/${userId}/preference/booking`,
        {
          ...bookingPreferences,
        },
        { observe: 'body' }
      )
      .pipe(map((response: IUser): IUser => response));
  }

  public updateCompanyLogo$(data: ICompanyLogoRequest): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<void>(
        `${SERVER_URL}/user/${userId}/company/${data.companyId}/logo`,
        {
          ...data,
        },
        { observe: 'body' }
      )
      .pipe(map((response: void): void => response));
  }

  public updateEmail$(email: string): Observable<IHttpResponseBody<undefined>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.put<IHttpResponseBody<undefined>>(
      `${SERVER_URL}/user/v2/${userId}/basic-info`,
      {
        personalEmail: email,
      },
      { observe: 'body' }
    );
  }

  public updatePassword$(passwordRequest: IPasswordRequest): Observable<IPasswordResponse> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.put<IPasswordResponse>(
      `${SERVER_URL}/user/${userId}/password`,
      {
        ...passwordRequest,
      },
      { observe: 'body' }
    );
  }

  public updateContactDetails$(contactDetails: IContactDetailsRequest): Observable<QimaNullableType<IUser>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .put<IHttpResponseBody<QimaNullableType<IUser>>>(
        `${SERVER_URL}/user/v2/${userId}/contact-info?saveBillingContactUpdateTime=false`,
        { ...contactDetails },
        {
          observe: 'response',
        }
      )
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IUser>>>>): QimaNullableType<IUser> => response?.body?.content || null));
  }

  public updateProductsCategories$(productFamilyIds: Readonly<string[]>): Observable<QimaNullableType<IUser>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .put<IHttpResponseBody<QimaNullableType<IUser>>>(`${SERVER_URL}/user/${userId}/preference/booking/preferred-product-families/`, productFamilyIds, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IUser>>>>): QimaNullableType<IUser> => response?.body?.content || null));
  }

  public getMyDocuments$(): Observable<QimaNullableType<IFileResponse[]>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<IFileResponse[]>(`${SERVER_URL}/user/${userId}/shared-files`, {
        observe: 'body',
      })
      .pipe(map((response: IFileResponse[]): QimaNullableType<IFileResponse[]> => response));
  }
}
