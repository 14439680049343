import { logrocketEnvironmentIdInitFactory } from '@aca-new/app/shared/factories/logrocket-environment-id-init.factory';
import { LogrocketInitService } from '@aca-new/app/shared/services/third-party-services/logrocket-init/logrocket-init.service';
import { APP_BOOTSTRAP_LISTENER, FactoryProvider } from '@angular/core';

export const LOGROCKET_ENVIRONMENT_ID_INIT_PROVIDER: FactoryProvider = {
  deps: [LogrocketInitService],
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: logrocketEnvironmentIdInitFactory,
};
