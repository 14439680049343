export enum ETableDataType {
  BADGE = 'badge',
  CHECKBOX = 'checkbox',
  CUSTOMIZED = 'customized',
  DATE = 'date',
  DECISION_LABEL_CELL = 'decision-label-cell',
  DOT_LABEL = 'dot_label',
  EMPTY = 'empty',
  ICE_CUBE = 'ice-cube',
  ICON = 'icon',
  LINK = 'link',
  MULTIPLE_LABEL_CELL = 'multiple-label-cell',
  NUMBER = 'number',
  PROGRESS_INDICATOR = 'progress-indicator',
  STRING = 'string',
}
