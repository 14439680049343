import { ELoadingType } from '@aca-new/app/shared/models/enums/loading-type.enum';
import { QimaColorService } from '@aca-new/app/shared/services/exported-services/qima-color/qima-color.service';
import { QimaSpinnerService } from '@aca-new/app/shared/services/exported-services/qima-spinner/qima-spinner.service';
import { QimaThreeSquaresLoaderService } from '@aca-new/app/shared/services/exported-services/qima-three-squares-loader/qima-three-squares-loader.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent {
  /**
   * @description
   * The loading type
   * @type {QimaOptionalType<ELoadingType>}
   * @default ELoadingType.SPINNER
   */
  @Input('overlayLoadingType')
  public loadingType: QimaOptionalType<ELoadingType> = ELoadingType.SPINNER;

  /**
   * @description
   * Is loading
   * @type {QimaOptionalType<boolean>}
   * @default false
   */
  @Input('overlayIsLoading')
  public isLoading: QimaOptionalType<boolean> = false;

  @Output('overlayClick')
  public readonly overlayClick: EventEmitter<MouseEvent | Event> = new EventEmitter<MouseEvent | Event>();

  public constructor(
    public readonly overlayService: AppOverlayService,
    public readonly threeSquaresLoaderService: QimaThreeSquaresLoaderService,
    public readonly spinnerService: QimaSpinnerService,
    public readonly qimaColor: QimaColorService
  ) {}

  public onClick(event: Readonly<MouseEvent | Event>): void {
    this._emitClick(event);
  }

  public onEnter(event: Readonly<MouseEvent | Event>): void {
    this._emitClick(event);
  }

  private _emitClick(event: Readonly<MouseEvent | Event>): void {
    this.overlayClick.emit(event);
  }
}
