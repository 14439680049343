import { IDataTableQueryParams } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-query-params.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { TABLE_PAGINATION } from '@aca-new/app/shared/constants/table-pagination.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-abstract-search-table',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class AbstractSearchTableComponent {
  public abstract tableHeaders: ITableHeader[];
  public readonly userId = this._storageService.getItem(EStorageKeys.USER_ID);

  public readonly pageSizes: number[] = [
    TABLE_PAGINATION.pageSize10,
    TABLE_PAGINATION.pageSize20,
    TABLE_PAGINATION.pageSize50,
    TABLE_PAGINATION.pageSize100,
    TABLE_PAGINATION.pageSize200,
  ];

  public readonly queryParams: IDataTableQueryParams = {
    keyword: '',
    pageSize: this.pageSizes[0],
    page: 1,
  };

  public dataTableUrl: string = '';
  public searchValue: string = '';
  public activePage: number = 1;

  protected constructor(protected readonly _storageService: StorageService) {}

  public onPaginationChange(page: number): void {
    this._setDataTableActivePage(page);
    this.setDataTableUrl();
  }

  public onPageSizeChange(pageSize: number): void {
    this.queryParams.pageSize = pageSize;
    this.setDataTableUrl();
  }

  public onSearchEnter(): void {
    this._setDataTableActivePage();
    this.setDataTableUrl();
  }

  public onSearchClear(): void {
    this.queryParams.keyword = '';
    this._setDataTableActivePage();
    this.setDataTableUrl();
  }

  private _setDataTableActivePage(page: number = 1): void {
    this.activePage = page;
    this.queryParams.page = page;
  }

  public abstract setDataTableUrl(): void;
}
