import { CustomizedTableCellDirective } from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/shared/directives/customized-table-cell.directive';
import {
  ICustomizedTableCellConfiguration,
  ICustomizedTableCellData,
} from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/shared/interfaces/customized-table-cell.interface';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { has } from 'lodash/index';

@Component({
  selector: 'app-customized-table-cell',
  templateUrl: './customized-table-cell.component.html',
  styleUrls: ['./customized-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizedTableCellComponent implements OnChanges {
  /**
   * @description
   * cell configuration with component used and data bound
   * @type {ICustomizedTableCellConfiguration<ICustomizedTableCellData>}
   */
  @Input('customizedTableCellConfiguration')
  public cellConfiguration!: ICustomizedTableCellConfiguration<ICustomizedTableCellData>;

  /**
   * @description
   * the width of the cell
   * @type {string}
   * @default 'auto'
   */
  @Input('customizedTableCellWidth')
  public width: string = 'auto';

  @ViewChild(CustomizedTableCellDirective, { static: true })
  private readonly _customizedTableCell!: CustomizedTableCellDirective;

  public ngOnChanges(changes: SimpleChanges): void {
    if (has(changes, 'cellConfiguration')) {
      this._initData();
    }
  }

  private _initData(): void {
    const { viewContainerRef } = this._customizedTableCell;

    viewContainerRef.clear();

    const cellComponentRef = viewContainerRef.createComponent(this.cellConfiguration.component);

    cellComponentRef.instance.data = this.cellConfiguration.data;
  }
}
