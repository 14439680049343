import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startCase',
  pure: true,
})
// angular built-in testing-pipes do not meet the requirements
export class StartCasePipe implements PipeTransform {
  public transform(data: string = ''): string {
    const labels: string[] = data.toLocaleLowerCase().split(' ');

    labels.forEach((label: string, index): void => {
      labels[index] = label.replace(/\b\w/g, (l): string => {
        return l.toUpperCase();
      });
    });

    return labels.join(' ');
  }
}
