import { EmptyContentComponent } from '@aca-new/app/shared/components/empty-content/empty-content.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EmptyContentComponent],
  exports: [EmptyContentComponent],
  imports: [TranslateModule, CommonModule],
})
export class EmptyContentModule {}
