import { LogrocketInitService } from '@aca-new/app/shared/services/third-party-services/logrocket-init/logrocket-init.service';

/**
 * @description
 * Factory that will initialize the LogRocket
 * @param {LogrocketInitService} logRocketInitService Service to initialize LogRocket
 * @returns {() => void} Function that initialize the LogRocket
 */
export function logrocketEnvironmentIdInitFactory(logRocketInitService: LogrocketInitService): () => void {
  return (): void => {
    logRocketInitService.init();
  };
}
