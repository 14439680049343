import { Injectable } from '@angular/core';

declare let ACA_URL: string;
declare let ACA_URL_CN: string;

@Injectable({
  providedIn: 'root',
})
export class AppLocationService {
  public isQimaCn(): boolean {
    return location.origin.includes('qima.cn');
  }

  public navigateToAcaLogin(): void {
    location.replace(`${this.getAcaUrl()}/login`);
  }

  public backToACA(): void {
    location.replace(this.getAcaUrl());
  }

  public getAcaUrl(): string {
    return this.isQimaCn() ? ACA_URL_CN : ACA_URL;
  }
}
