<div class="app-main">
  <app-header></app-header>
  <div class="app-main__container">
    <app-aside></app-aside>
    <div
      class="main-content"
      [class.main-content--collapsed]="isAsideCollapsed"
    >
      <router-outlet [class.hidden]="isAcaFrameVisible"></router-outlet>
      <iframe
        class="aca-frame"
        id="acaFrame"
        title="aca page"
        [src]="acaUrl | byPassSecurityTrust: securityContext.RESOURCE_URL : true"
        [class.hidden]="!isAcaFrameVisible"
      ></iframe>
    </div>
  </div>
</div>
