import { GoogleAnalyticsService } from '@aca-new/app/shared/services/third-party-services/google-analytics/google-analytics.service';

/**
 * @description
 * Factory that will initialize the Google Analytics service
 * @param {GoogleAnalyticsService} service The Google Analytics service to initialize
 * @returns {() => void} Function that initialize the Google Analytics service
 */
export function googleAnalyticsInitFactory(service: GoogleAnalyticsService): () => void {
  return (): void => {
    service.init();
  };
}
