import { INpsSurvey } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/models/interfaces/nps-survey.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class NpsSurveyService {
  public constructor(
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService
  ) {}

  public getIsSurveyVisible$(): Observable<boolean> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<IHttpResponseBody<boolean>>(`${SERVER_URL}/user/${userId}/show-survey`, {
        observe: 'body',
      })
      .pipe(map((response: IHttpResponseBody<boolean>): boolean => response.content || false));
  }

  public saveSurvey$(data: INpsSurvey): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<void>(
        `${SERVER_URL}/user/${userId}/nps-survey`,
        {
          ...data,
        },
        { observe: 'body' }
      )
      .pipe(map((response: void): void => response));
  }
}
