import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IServerTime } from '@aca-new/app/shared/models/interfaces/server-time.interface';
import { ConvertType } from '@aca-new/app/shared/models/types/convert.type';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { Injectable } from '@angular/core';
import { capitalize } from 'lodash/index';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppTimeService {
  public convertTypes = {
    datepicker: { format: 'YYYY-MM-DDTHH:mm:ss' },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ISO8601: { format: '' },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AIFormat: { format: 'DD-MMM-YYYY' },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Unix: { format: 'X' },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    UTCFormat: { format: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]' },
  };

  public constructor(private readonly _httpService: HttpService) {}

  public isExpectedDateFormat(value: moment.MomentInput, format: string): boolean {
    return moment(value, format).format(format) == value;
  }

  public generateServerTime$(): Observable<IServerTime> {
    return this._httpService.httpClient.get<IServerTime>(`${SERVER_URL}/aca-server/generateServerTime`);
  }

  public convertToChinaTime(value: string | number, type: ConvertType, isYesterday: boolean, isAudit?: boolean, sourceFormat?: string): string | number {
    let now;
    let convertTo = '';
    const originalValue = value; // in case value is being changed

    if (!value) {
      return '';
    }

    // return value directly if date is already in the target format added this checking since in ui-calendar this function is called in a loop
    if (this.isExpectedDateFormat(value, this.convertTypes[type].format)) {
      return value;
    }

    if (type) {
      convertTo = this.convertTypes[type].format;
    }

    // only trigger if isAudit
    // if audit, not datepicker, if audit date format
    if (isAudit && type !== 'datepicker' && this.isExpectedDateFormat(value, 'DD-MMM-YYYY')) {
      return value;
    } else if (isAudit && type === 'datepicker' && this.isExpectedDateFormat(value, 'DD-MMM-YYYY')) {
      // audit, datepicker, audit date format
      value = +moment(value, 'DD-MMM-YYYY').format('x');
    } else if (isAudit && this.isExpectedDateFormat(value, 'X') && value.toString().length < 11) {
      // check length since momentjs fails to differentiate unix and unix milisecond
      // X - 1360013296
      // x - 1360013296123
      // x - 1360013296123 - milliseconds
      // only add the zeroes if not millisecond
      // if audit, format date unix no milliseconds
      value = +`${value.toString()}000`;
    }

    // override all preformatting if sourceFormat is provided
    if (sourceFormat) {
      switch (sourceFormat) {
        case 'DD-MMM-YYYY':
          value = +moment(originalValue, 'DD-MMM-YYYY').format('x');
          break;
      }
    }

    // if in datepicker format. remove THH:mm:ss
    if (this.isExpectedDateFormat(value, 'YYYY-MM-DDTHH:mm:ss')) {
      value = moment(value).format('YYYY-MM-DD');
    }

    const a = new Date(value);
    const timezoneOffset = a.getTimezoneOffset();
    const diff = timezoneOffset - -480; // get timezoneOffset
    const offset = -timezoneOffset / 60; // convert to readable UTC (-11, +8);
    const timezoneDiff = offset - 8; // local UTC - +8
    const getTime = a.getTime() - 28800000; // 8 hours to ms
    // if (isYesterday) { // get previous day of value
    //     getTime = a.getTime() - 115200000; // 24+8hrs to ms
    // }
    const b = getTime + diff * 60000; // minutes to ms
    const c = new Date(b + 3600000 * timezoneDiff);

    // var date = moment(c).tz('Asia/Macau').format(convertTo);

    // Convert to ISO8601 Manually
    if (typeof value === 'string') {
      now = new Date(value);
    } else {
      now = c;
    }

    const tzo = -now.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    // eslint-disable-next-line func-style
    const pad = (num: number): string => {
      const norm = Math.abs(Math.floor(num));

      return (norm < 10 ? '0' : '') + norm.toString();
    };
    const ISO8601 = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}T${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}${dif}${pad(
      tzo / 60
    )}:${pad(tzo % 60)}`;
    let date = moment(ISO8601).tz('Asia/Macau').format(convertTo);

    if (isYesterday) {
      date = moment(ISO8601).tz('Asia/Macau').subtract(1, 'days').format(convertTo);
    }

    return date;
  }

  /**
   *
   * @param {string} value value
   * @param {('datepicker'|'ISO8601'|'AIFormat'|'Unix'|'UTCFormat')} type to what format
   * @param {('chinaTime'|'yesterday')} method what kind of conversion
   * @param {boolean} isAudit isAudit
   * @param {object} serverTime serverTime
   * @param {string} sourceFormat from which format
   * @returns {string | number} time
   */
  public manipulateTime(value: string, type: ConvertType, method: 'chinaTime' | 'yesterday', isAudit?: boolean, serverTime?: IServerTime, sourceFormat?: string): string | number {
    // TODO handle shipment date
    console.log(serverTime);

    switch (method) {
      case 'chinaTime':
        return this.convertToChinaTime(value, type, false, isAudit, sourceFormat);
      case 'yesterday':
        return this.convertToChinaTime(value, type, true, isAudit);
    }
  }

  /**
   * check if <inspectionDate - bookingDate = 1>
   * @param {string} inspectionDate inspectionDate
   * @param {IServerTime} serverTime serverTime
   * @returns {boolean} is next day
   */
  public isNextDay(inspectionDate: string, serverTime: IServerTime): boolean {
    const dateFormats = serverTime;

    inspectionDate = this.manipulateTime(inspectionDate, 'AIFormat', 'chinaTime').toString();
    const tomorrowDate = dateFormats.tomorrowAiFormat;
    const nextDate = dateFormats.nextDayAiFormat;
    const { todayHour } = dateFormats;

    return inspectionDate === tomorrowDate || (parseInt(todayHour, 10) >= 16 && inspectionDate === nextDate);
  }

  public convertStringToSortableDate(date: string): Date {
    const dates: string[] = date.split('-');
    const day: number = parseInt(dates[0], 10);
    const year: number = parseInt(dates[2], 10);
    const month: number = new Date(Date.parse(`${dates[1]} ${day},${year}`)).getMonth() + 1;

    return new Date(year, month, day);
  }

  // function accepts aca date format DD-MMM-YYYY/ DD-Mmm-YYYY/ DD-mmm-YYYY
  // returns unitized format of DD-Mmm-YYYY
  public capitalizeDateString(date: string): string {
    const dates: string[] = date.split('-');

    return `${dates[0]}-${capitalize(dates[1])}-${dates[2]}`;
  }
}
