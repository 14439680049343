import { Injectable } from '@angular/core';
import { EQimaButtonBorderRadiusStyle, EQimaButtonIconPosition, EQimaButtonIconStyle, EQimaButtonSize, EQimaButtonStyle, EQimaButtonWidth } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaButtonService {
  public readonly buttonSize = EQimaButtonSize;
  public readonly buttonStyle = EQimaButtonStyle;
  public readonly buttonIconPosition = EQimaButtonIconPosition;
  public readonly buttonWidth = EQimaButtonWidth;
  public readonly buttonIconStyle = EQimaButtonIconStyle;
  public readonly buttonBorderRadiusStyle = EQimaButtonBorderRadiusStyle;
}
