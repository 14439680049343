import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { EQimaColorPool, EQimaDotSize, EQimaIceCubeSize, QimaColorsType, QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-aside-menu-item',
  templateUrl: './aside-menu-item.component.html',
  styleUrls: ['./aside-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AsideMenuItemComponent {
  /**
   * @description
   * The label of the aside-menu item
   * @type {string}
   * @default ''
   */
  @Input('asideMenuItemLabel')
  public label: string = '';

  /**
   * @description
   * The abbreviation of service type
   * @type {string}
   * @default ''
   */
  @Input('asideMenuItemAbbreviation')
  public abbreviation: string = '';

  /**
   * @description
   * Check or not
   * @type {boolean}
   * @default false
   */
  @Input('isAsideMenuItemChecked')
  public isChecked: boolean = false;

  /**
   * @description
   * Show icon or not
   * @type {boolean}
   * @default false
   */
  @Input('isAsideMenuItemIconShow')
  public isIconShow: QimaOptionalType<boolean> = false;

  @Output('asideMenuItemClick')
  public readonly itemClick: EventEmitter<MouseEvent | Event> = new EventEmitter<MouseEvent | Event>();

  public dotColor: QimaColorsType = EQimaColorPool.POOL_600;
  public dotSize: EQimaDotSize = EQimaDotSize.SMALL;
  public iceCubeSize = EQimaIceCubeSize.SMALL;

  public constructor(public readonly qimaIcon: QimaIconService) {}

  public onClick(event: Readonly<MouseEvent | Event>): void {
    this._emitClick(event);
  }

  public onEnter(event: Readonly<Event>): void {
    this._emitClick(event);
  }

  private _emitClick(event: Readonly<MouseEvent | Event>): void {
    this.itemClick.emit(event);
  }
}
