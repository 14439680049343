import { AppRoutingModule } from '@aca-new/app/app-routing.module';
import { AppComponent } from '@aca-new/app/app.component';
import { DocumentVerificationErrorHandlerService } from '@aca-new/app/pages/document-verification/shared/services/document-verification-error-handler.service';
import { MainModule } from '@aca-new/app/pages/main/main.module';
import { ExternalSharedReportHttpAuthInterceptor } from '@aca-new/app/pages/reports/pages/external-shared-report/shared/services/external-shared-report-http-auth-interceptor.service';
import { ExternalSharedReportHttpErrorHandlerService } from '@aca-new/app/pages/reports/pages/external-shared-report/shared/services/external-shared-report-http-error-handler.service';
import { OverlayModule } from '@aca-new/app/shared/components/overlay/overlay.module';
import { dateFactory } from '@aca-new/app/shared/factories/date-factory/date.factory';
import { httpLoaderFactory, localInitProvider } from '@aca-new/app/shared/factories/i18n.factory';
import { translationFactory } from '@aca-new/app/shared/factories/translation.factory';
import { AuthInterceptor } from '@aca-new/app/shared/interceptors/auth.interceptor';
import { ErrorHandlerInterceptor } from '@aca-new/app/shared/interceptors/error-handler.interceptor';
import { APPCUES_ACCOUNT_ID_PROVIDER } from '@aca-new/app/shared/providers/appcues-account-id.provider';
import { BROWSER_WINDOW_PROVIDER } from '@aca-new/app/shared/providers/browser-window.provider';
import { GOOGLE_ANALYTICS_AUTH_PROVIDER } from '@aca-new/app/shared/providers/google-analytics-auth.provider';
import { GOOGLE_ANALYTICS_ID_PROVIDER } from '@aca-new/app/shared/providers/google-analytics-id.provider';
import { GOOGLE_ANALYTICS_INIT_PROVIDER } from '@aca-new/app/shared/providers/google-analytics-init.provider';
import { GOOGLE_ANALYTICS_PREVIEW_PROVIDER } from '@aca-new/app/shared/providers/google-analytics-preview.provider';
import { LOGROCKET_ENVIRONMENT_ID_INIT_PROVIDER } from '@aca-new/app/shared/providers/logrocket-environment-id-init.provider';
import { LOGROCKET_ENVIRONMENT_ID_PROVIDER } from '@aca-new/app/shared/providers/logrocket-environment-id.provider';
import { SERVICE_WORKER_PROVIDER } from '@aca-new/app/shared/providers/service-worker.provider';
import { SHOULD_SESSION_STORAGE_ENCRYPT_PROVIDER } from '@aca-new/app/shared/providers/should-session-storage-encrypt.provider';
import { WINDOW_PROVIDER } from '@aca-new/app/shared/providers/window.provider';
import { AppBrowserDetectService } from '@aca-new/app/shared/services/browser-services/app-browser-detect/app-browser-detect.service';
import { HttpMockInterceptor } from '@aca-new/app/shared/services/http-mock-handler/http-mock-interceptor.service';
import { HttpAuthInterceptor } from '@aca-new/app/shared/services/http-services/http-auth-handler/http-auth-interceptor.service';
import { HttpErrorHandlerService } from '@aca-new/app/shared/services/http-services/http-error-handler/http-error-handler.service';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, FactoryProvider, LOCALE_ID, NgModule, ValueProvider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  EQimaLoggerLevel,
  QIMA_ASSETS_PATH_TOKEN,
  QIMA_DATE_FACTORY_TOKEN,
  QIMA_TRANSLATION_FACTORY_TOKEN,
  QimaDateModule,
  QimaLoggerModule,
  QimaModule,
  QimaSnackbarService,
  QimaTranslationModule,
} from '@qima/ngx-qima';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    QimaModule.forRoot(),
    AppRoutingModule,
    QimaDateModule,
    HttpClientModule,
    QimaTranslationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    // Used by ngx-qima library
    AngularSvgIconModule.forRoot(),
    QimaTranslationModule.forRoot(),
    CookieModule.forRoot(),
    MainModule,
    QimaLoggerModule.forRoot({
      loggerLevel: EQimaLoggerLevel.ERROR,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    OverlayModule,
  ],
  providers: [
    QimaSnackbarService,
    HttpErrorHandlerService,
    DocumentVerificationErrorHandlerService,
    ExternalSharedReportHttpErrorHandlerService,
    HttpAuthInterceptor,
    HttpMockInterceptor,
    ExternalSharedReportHttpAuthInterceptor,
    WINDOW_PROVIDER,
    BROWSER_WINDOW_PROVIDER,
    SHOULD_SESSION_STORAGE_ENCRYPT_PROVIDER,
    SERVICE_WORKER_PROVIDER,
    GOOGLE_ANALYTICS_ID_PROVIDER,
    GOOGLE_ANALYTICS_AUTH_PROVIDER,
    GOOGLE_ANALYTICS_PREVIEW_PROVIDER,
    GOOGLE_ANALYTICS_INIT_PROVIDER,
    APPCUES_ACCOUNT_ID_PROVIDER,
    LOGROCKET_ENVIRONMENT_ID_PROVIDER,
    LOGROCKET_ENVIRONMENT_ID_INIT_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: localInitProvider,
      deps: [TranslateService, AppBrowserDetectService],
      multi: true,
    },
    {
      provide: QIMA_DATE_FACTORY_TOKEN,
      useFactory: dateFactory,
    } as FactoryProvider,
    {
      provide: QIMA_ASSETS_PATH_TOKEN,
      useValue: 'ngx-qima/assets/',
    } as ValueProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMockInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: QIMA_TRANSLATION_FACTORY_TOKEN,
      useFactory: translationFactory,
      deps: [TranslateService, LOCALE_ID],
    } as FactoryProvider,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
