import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { isString } from 'lodash/index';

/**
 * @description
 * Used to define the Google Analytics container ID
 * Based on the environments variable injection in the index file
 * @param {IMyWindow} window The window object containing possibly the "googleAnalyticsId" variable
 * @returns {string} Return the container ID if it exists or null otherwise
 */
export function googleAnalyticsIdFactory(window: IMyWindow): string | null {
  const { googleAnalyticsId } = window;

  if (isString(googleAnalyticsId)) {
    return googleAnalyticsId;
  }

  return null;
}
