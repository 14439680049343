import { AsideService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.service';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { QimaResponsiveService } from '@qima/ngx-qima';
import { filter, Observable, tap } from 'rxjs';

declare let SHOULD_INIT_CHAT_BOX: boolean;

@UntilDestroy()
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, AfterViewInit {
  public isAsideCollapsed: boolean = false;
  public isLoading: boolean = false;
  public isAcaFrameVisible = true;
  public acaUrl: string = '';
  public readonly securityContext = SecurityContext;
  public isMobile: boolean = false;
  public shouldInitChatBox: boolean = this._window.shouldInitChatBox ?? SHOULD_INIT_CHAT_BOX;
  public constructor(
    private readonly _router: Router,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _asideService: AsideService,
    private readonly _translateService: TranslateService,
    private readonly _appLocationService: AppLocationService,
    private readonly _acaMessageService: AcaMessageService,
    private readonly _qimaResponsiveService: QimaResponsiveService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _appOverlayService: AppOverlayService,
    @Inject(WINDOW) private readonly _window: IMyWindow,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public ngOnInit(): void {
    this._watch();
    this._initData();
  }

  public ngAfterViewInit(): void {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();

    if (this._appAuthenticationService.isAuthenticated()) {
      this._initChat(userData);
    }

    const { currentUserSubject$ } = this._appAuthenticationService;

    currentUserSubject$.pipe(untilDestroyed(this)).subscribe((data: IUser): void => {
      this._initChat(data);
    });
  }

  private _initChat(userData: IUser): void {
    if (!this.shouldInitChatBox || this._appUserSettingService.getIsQimaProduce()) {
      return;
    }

    const email = userData.email?.split(';')[0];
    const { login, firstName, lastName, id } = userData;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;

    windowRef.MessageBirdChatWidget?.init();
    windowRef.MessageBirdChatWidget?.show();
    windowRef.MessageBirdChatWidget?.identify(id, {
      email,
      firstName,
      lastName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Login: login,
    });
  }

  private _initData(): void {
    this._updateAcaFrameStatus(this._router.url);
    this._updateAcaFrameUrl();
  }

  private _watch(): void {
    this._asideService.isAsideCollapsed$.pipe(untilDestroyed(this)).subscribe((isAsideCollapsed: boolean): void => {
      this.isAsideCollapsed = isAsideCollapsed;
      this._changeDetectorRef.markForCheck();
    });

    this._appOverlayService.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading: boolean): void => {
      this.isLoading = isLoading;
      this._changeDetectorRef.detectChanges();
    });

    this._qimaResponsiveService
      .isMobile$()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
      });

    if (this._acaMessageService.isAcaFrameAvailable) {
      this._watchRouterChange$().pipe(untilDestroyed(this)).subscribe();
      this._acaMessageService.watchMessageFromAca();
    }
  }

  private _watchRouterChange$(): Observable<Event> {
    return this._router.events.pipe(
      filter((event: Event): boolean => {
        return event instanceof NavigationStart || event instanceof NavigationEnd;
      }),
      tap({
        next: (event: Event): void => {
          if (event instanceof NavigationStart) {
            if (event.navigationTrigger === 'popstate' && !this._acaMessageService.isMyQimaPageAvailable(event.url)) {
              this._acaMessageService.informAcaPathChange(event.url.replace('/aca', ''));
            }
          } else if (event instanceof NavigationEnd) {
            this._updateAcaFrameStatus(event.urlAfterRedirects);
          }
        },
      })
    );
  }

  private _updateAcaFrameStatus(url: string): void {
    const wasAcaFrameVisible = this.isAcaFrameVisible;

    this.isAcaFrameVisible = !this._acaMessageService.isMyQimaPageAvailable(url) && url.startsWith('/aca');

    if (this.isAcaFrameVisible && !wasAcaFrameVisible) {
      this._acaMessageService.postMessageToAca({
        type: EPostMessageType.LANGUAGE_CHANGE,
        data: { language: this._translateService.currentLang },
      });
    }

    this._changeDetectorRef.markForCheck();
  }

  private _updateAcaFrameUrl(): void {
    let validUrl = this._appLocationService.getAcaUrl() + this._acaMessageService.updateRouterUrl(this._router.url);

    if (/step-\w+/.test(validUrl)) {
      /**
       * Why we need to replace the "step-${number}" to "step%3D${number}"?
       * On aca side, the path is 'https://aca.qima.com/${router}step=${number}'
       * On MyQIMA side, the '=' is not available in the url, so we use '-' instead.
       * When we want to navigate to aca, we need to replace the '-' to '%3D' which is encoded from '='.
       */
      const pathParams = /step-\w+/.exec(validUrl)?.[0] as string;
      const validPathParams = pathParams.replace('-', '%3D');

      validUrl = validUrl.replace(pathParams, validPathParams);
    }

    this.acaUrl = validUrl;
  }
}
