import { DialogHeaderComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-header/dialog-header.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaButtonIconModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [DialogHeaderComponent],
  exports: [DialogHeaderComponent],
  imports: [CommonModule, TranslateModule, QimaButtonIconModule],
})
export class DialogHeaderModule {}
