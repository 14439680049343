import { PreviousOrderItemModule } from '@aca-new/app/pages/bookings/pages/previous-order-item/previous-order-item.module';
import { AsideMenuComponent } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-menu/aside-menu.component';
import { AsideMenuItemComponent } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-menu/share/components/aside-menu-item/aside-menu-item.component';
import { SectionTitleModule } from '@aca-new/app/shared/components/section-title/section-title.module';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { ServiceTypeIceCubeStyleModule } from '@aca-new/app/shared/pipes/service-type-ice-cube-style/service-type-ice-cube-style.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaButtonIconModule, QimaDividerModule, QimaDotModule, QimaIceCubeModule, QimaIconModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [AsideMenuComponent, AsideMenuItemComponent],
  exports: [AsideMenuComponent, AsideMenuItemComponent],
  imports: [
    CommonModule,
    SectionTitleModule,
    QimaIconModule,
    QimaButtonIconModule,
    QimaIceCubeModule,
    QimaDividerModule,
    QimaDotModule,
    ServiceTypeIceCubeStyleModule,
    TranslateModule,
    QimaTooltipDirectiveModule,
    PreviousOrderItemModule,
    TrackByUuidDirective,
  ],
})
export class AsideMenuModule {}
