import { ICompanyLogo } from '@aca-new/app/pages/main/shared/components/header/shared/interfaces/companyLogo.interface';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { LANGUAGES } from '@aca-new/app/shared/constants/languages.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { ESupportedLanguage } from '@aca-new/app/shared/models/enums/supported-language.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppBrowserDetectService } from '@aca-new/app/shared/services/browser-services/app-browser-detect/app-browser-detect.service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import localForage from 'localforage';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public readonly isProfileMenuVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isTranslateMenuVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public activeLanguageIndex$: Subject<number> = new Subject<number>();
  public readonly companyLogo$: Subject<string> = new Subject<string>();

  public constructor(
    private readonly _appBrowserDetectService: AppBrowserDetectService,
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {
    void localForage.getItem(EStorageKeys.LOCALE_LANGUAGE).then((data): void => {
      const language = data || this._appBrowserDetectService.getSupportedBrowserLanguage() || ESupportedLanguage.ENGLISH;

      this.activeLanguageIndex$.next(LANGUAGES.findIndex((item): boolean => item.value === language));
    });
  }

  public getCompanyLogo$(): Observable<QimaNullableType<string>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const user: IUser = this._appAuthenticationService.getCurrentUser();

    if (!user?.company?.id) {
      return of(null);
    }

    return this._httpService.httpClient
      .get<ICompanyLogo>(`${SERVER_URL}/user/${userId}/company/${user.company.id}/logo`, { observe: 'response' })
      .pipe(map((response: Readonly<HttpResponse<ICompanyLogo>>): QimaNullableType<string> => response?.body?.image || null));
  }

  public getManagerAvatar$(sicId: string): Observable<QimaNullableType<string>> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<string>>(`${SERVER_URL}/parameter/sic/${sicId}/base64?refresh=true`, { observe: 'response' })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<string>>>): QimaNullableType<string> => response?.body?.content || null));
  }

  public updateIsProfileMenuVisible(isProfileMenuVisible: boolean): void {
    this.isProfileMenuVisible$.next(isProfileMenuVisible);
  }

  public updateIsTranslateMenuVisible(isTranslateMenuVisible: boolean): void {
    this.isTranslateMenuVisible$.next(isTranslateMenuVisible);
  }

  public updateActiveLanguageIndex(index: number): void {
    this.activeLanguageIndex$.next(index);
  }

  public updateCompanyLogo(logo: string): void {
    this.companyLogo$.next(logo);
  }
}
