<div
  class="app-aside-menu-item"
  (click)="onClick($event)"
  (keydown.enter)="onEnter($event)"
  [class.app-aside-menu-item--checked]="isChecked"
  [class.app-aside-menu-item--has-abbreviation]="abbreviation"
>
  <qima-ice-cube
    *ngIf="abbreviation"
    [qimaIceCubeLabel]="abbreviation"
    [qimaIceCubeSize]="iceCubeSize"
    [qimaIceCubeStyle]="abbreviation | serviceTypeIceCubeStyle"
    class="qima-mr-s"
  ></qima-ice-cube>
  <span class="app-aside-menu-item__label">{{ label }}</span>
  <div
    *ngIf="isIconShow"
    class="app-aside-menu-item__icon"
  >
    <qima-icon
      *ngIf="!isChecked"
      [qimaIconName]="qimaIcon.iconName.CHEVRON_RIGHT"
      [qimaIconSize]="qimaIcon.sizeSmall"
    ></qima-icon>
    <qima-dot
      *ngIf="isChecked"
      class="dot-checked"
      [style.background-color]="dotColor"
      [qimaDotSize]="dotSize"
    ></qima-dot>
  </div>
</div>
