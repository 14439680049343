import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public replaceSpaceToUnderline(str: string): string {
    return str.trim().toLocaleLowerCase().replace(/\s+/g, '-');
  }
}
