import { INTERNAL_DOMAINS } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleFaviconResolver implements Resolve<string> {
  public constructor(@Inject(WINDOW) private readonly _window: IMyWindow) {}

  public resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): string | Observable<string> | Promise<string> {
    const faviconElement: HTMLLinkElement = this._window.document.getElementById('favicon') as HTMLLinkElement;
    const domain = this._window.location.hostname;

    if (INTERNAL_DOMAINS.includes(domain)) {
      faviconElement.href = 'favicon.ico';

      return route?.data?.altTitle ? `QIMA | ${route.data.altTitle}` : 'QIMA';
    }

    faviconElement.href = route?.data?.altFavicon || '';

    return route?.data?.altTitle || '';
  }
}
