<div
  *ngIf="cellData && rowData"
  class="decision-label-cell"
  [ngClass]="['decision-label-cell--' + cellData.label | lowercase]"
>
  <div class="decision-label-cell__dot">
    <qima-dot
      [ngClass]="['decision-label-cell__dot--' + cellData.label | lowercase]"
      class="qima-mr-base"
    ></qima-dot>
    <span
      class="decision-label-cell__dot__label"
      [ngClass]="['decision-label-cell__dot__label--' + cellData.label | lowercase]"
      >{{ cellData.label }}
    </span>
  </div>

  <div
    *ngIf="cellData.label !== statusType.pending"
    class="decision-label-cell__approval-reason"
  >
    <div class="decision-label-cell__approval-reason__approved">{{ approvalBy }}</div>
    <qima-icon
      qimaTooltip
      [qimaIconName]="qimaIcon.iconName.MORE_ACTIONS"
      [qimaTooltipStyle]="qimaTooltip.style.DARK"
      [qimaTooltipPosition]="qimaTooltip.position.TOP_RIGHT"
      [qimaTooltipTemplate]="reasonTooltip"
      [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
      [qimaTooltipHasArrow]="true"
    ></qima-icon>
    <ng-template #reasonTooltip>
      <div
        class="decision-label-cell__approval-reason__state"
        [innerHTML]="reasonState | translate"
      ></div>
    </ng-template>
  </div>
</div>
