import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { AppcuesService } from '@aca-new/app/shared/services/third-party-services/appcues/appcues.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-my-qima',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public isLoading: boolean = false;

  public constructor(
    protected readonly _appcuesService: AppcuesService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _appOverlayService: AppOverlayService
  ) {}

  public ngOnInit(): void {
    this._watch();
  }

  private _watch(): void {
    this._appcuesService.load$().pipe(untilDestroyed(this)).subscribe();

    this._appOverlayService.isLoading$.pipe(untilDestroyed(this)).subscribe((isLoading: boolean): void => {
      this.isLoading = isLoading;
      this._changeDetectorRef.detectChanges();
    });
  }
}
