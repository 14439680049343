/* eslint-disable import/no-named-as-default-member */
import { EQimaDateUnit, QimaDateFactory, QimaDateType, QimaOptionalType } from '@qima/ngx-qima';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import { times } from 'lodash/index';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/zh-cn';

dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);

const FIRST_DAY_OF_THE_WEEK: number = 0;
const LAST_DAY_OF_THE_WEEK: number = 6;
const NB_OF_DATES_DISPLAYED: number = 42;

export class DateService implements QimaDateFactory {
  public format(date: Readonly<QimaOptionalType<QimaDateType>>, format: Readonly<string>): string {
    return dayjs(date).format(format);
  }

  public getToday(): QimaDateType {
    return dayjs().toISOString();
  }

  public isValid(date: Readonly<QimaDateType>): boolean {
    return dayjs(date).isValid();
  }

  public isEqual(date1: Readonly<QimaDateType>, date2: Readonly<QimaDateType>, unit: EQimaDateUnit = EQimaDateUnit.MILLISECONDS): boolean {
    return dayjs(date1).isSame(dayjs(date2), unit);
  }

  public getWeekdayNames(): string[] {
    return dayjs.weekdaysShort();
  }

  public getLastDateInWeek(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).day(LAST_DAY_OF_THE_WEEK).toISOString();
  }

  public getFirstDateInWeek(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).day(FIRST_DAY_OF_THE_WEEK).toISOString();
  }

  public getMonthName(date: Readonly<QimaDateType>): string {
    return dayjs.months()[dayjs(date).month()];
  }

  public getYear(date: Readonly<QimaDateType>): number {
    return dayjs(date).year();
  }

  public getDatesInMonth(date: Readonly<QimaDateType>): QimaDateType[] {
    // The first date of the month base on the date.
    const startDateOfCurrentMonth = this.getStartDateOfMonth(date);
    // The first date of previous month base on the date.
    const startDateOfPreviousMonth = this.getPreviousMonthDate(startDateOfCurrentMonth);
    // The first date of next month base on the date.
    const startDateOfNextMonth = this.getNextMonthDate(startDateOfCurrentMonth);
    // The week day of the start day of the current month, between 0 - 6.
    const startDayOfCurrentMonth = dayjs(startDateOfCurrentMonth).toDate().getDay();
    // The amount days in the current month.
    const daysOfCurrentMonth = this.getDaysInMonth(date);
    const daysOfPreviousMonth = this.getDaysInMonth(startDateOfPreviousMonth);
    const monthDates: QimaDateType[] = [];

    times(startDayOfCurrentMonth, (index: Readonly<number>): void => {
      const targetDate = dayjs(startDateOfPreviousMonth)
        .date(daysOfPreviousMonth - startDayOfCurrentMonth + index + 1)
        .toISOString();

      monthDates.push(targetDate);
    });

    times(daysOfCurrentMonth, (index: Readonly<number>): void => {
      const targetDate = dayjs(startDateOfCurrentMonth)
        .date(index + 1)
        .toISOString();

      monthDates.push(targetDate);
    });

    let dateIndex = 0;

    while (monthDates.length < NB_OF_DATES_DISPLAYED) {
      ++dateIndex;
      const targetDate = dayjs(startDateOfNextMonth).date(dateIndex).toISOString();

      monthDates.push(targetDate);
    }

    return monthDates;
  }

  /**
   * @description
   * Get the first date of the month base on parameter
   * @param {Readonly<QimaDateType>} date A date object
   * @returns {QimaDateType} The first date of the month base on parameter
   */
  public getStartDateOfMonth(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).startOf('month').toISOString();
  }

  /**
   * @description
   * Get the last date of the month base on parameter
   * @param {Readonly<QimaDateType>} date A date object
   * @returns {QimaDateType} The last date of the month base on parameter
   */
  public getEndDateOfMonth(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).endOf('month').format('YYYY-MM-DD');
  }

  /**
   * @description
   * Get the same date in the next month base on parameter
   * @param {Readonly<QimaDateType>} date A date object
   * @returns {QimaDateType} The same date in the next month base on parameter
   */
  public getNextMonthDate(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).add(1, 'month').toISOString();
  }

  /**
   * @description
   * Get the same date in the previous month base on parameter
   * @param {Readonly<QimaDateType>} date A date object
   * @returns {QimaDateType} The same date in the previous month base on parameter
   */
  public getPreviousMonthDate(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).subtract(1, 'month').toISOString();
  }

  /**
   * @description
   * Get the last day of the next month based on the parameter date.
   * @param {Readonly<QimaDateType>} date A date object
   * @returns {QimaDateType} The last day of the next month based on the parameter date.
   */
  public getEndOfNextMonth(date: Readonly<QimaDateType>): QimaDateType {
    return dayjs(date).add(1, 'month').endOf('month').format('YYYY-MM-DD');
  }

  public getDaysInMonth(date: Readonly<QimaDateType>): number {
    return dayjs(date).daysInMonth();
  }

  public isDateAfterMaximum(date: Readonly<QimaDateType>, maximum: Readonly<QimaDateType>): boolean {
    return dayjs(date).isAfter(dayjs(maximum), 'day');
  }

  public isDateBeforeMinimum(date: Readonly<QimaDateType>, minimum: Readonly<QimaDateType>): boolean {
    return dayjs(date).isBefore(dayjs(minimum), 'day');
  }
}
