import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGuideComponent<T> {
  public constructor(
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<T>,
    @Inject(WINDOW) private readonly _window: IMyWindow,
    public readonly qimaButton: QimaButtonService
  ) {}

  public downloadPdf(): void {
    this._window.open('https://s3-ap-southeast-1.amazonaws.com/ai-resource/qima/QIMA_OnlineAccountUserManual_en.pdf');
  }

  public close(): void {
    this._dialogRef.close();
  }
}
