<div class="dialog-in-progress">
  <app-dialog-common [dialogCommonTitle]="'COMMON.IN_PROGRESS' | translate">
    <div
      class="content-confirm"
      [innerHTML]="'COMMON.IN_PROGRESS_DESCRIPTION' | translate"
    ></div>
    <div class="foot-confirm">
      <qima-button
        [qimaButtonLabel]="'COMMON.BACK_TO_CLASSICAL_VIEW' | translate"
        (qimaButtonClick)="onBackToACAClick()"
      ></qima-button>
    </div>
  </app-dialog-common>
</div>
