<div
  class="overlay"
  (click)="onClick($event)"
  (keydown.enter)="onEnter($event)"
>
  <ng-container *ngIf="!isLoading">
    <div class="overlay__header"></div>
    <div class="overlay__aside"></div>
    <div class="overlay__content"></div>
  </ng-container>
  <div
    *ngIf="isLoading"
    class="overlay__loading"
  >
    <qima-spinner
      *ngIf="loadingType === overlayService.loadingType.SPINNER"
      [style.color]="qimaColor.pool.POOL_500"
      [qimaSpinnerUnit]="spinnerService.unit.PIXEL"
      [qimaSpinnerSize]="150"
    ></qima-spinner>
    <qima-three-squares-loader
      *ngIf="loadingType === overlayService.loadingType.THREE_SQUARES"
      [qimaThreeSquaresLoaderStyle]="threeSquaresLoaderService.style.POOL_500"
      [qimaThreeSquaresLoaderUnit]="threeSquaresLoaderService.unit.PIXEL"
      [qimaThreeSquaresLoaderSize]="120"
    ></qima-three-squares-loader>
  </div>
</div>
