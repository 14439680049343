<div
  class="translate-menu"
  qimaShadow
  [qimaShadowLevel]="shadowService.shadowLevel.TWO"
>
  <div
    *ngFor="let item of translateMenuItems; let index = index"
    appNgForTrackByUuid
    class="translate-menu__item"
    [class.translate-menu__item--checked]="activeIndex === index"
    (click)="onTranslateMenuItemClick(index)"
    (keydown.enter)="onTranslateMenuItemEnter(index)"
  >
    <div class="translate-menu__item__label-container">
      <span class="translate-menu__item__label-container__text">{{ item.title | translate }}</span>
    </div>
    <qima-dot
      *ngIf="activeIndex === index"
      class="translate-menu__item__dot"
      [style.background-color]="qimaColor.pool.POOL_500"
      [qimaDotSize]="qimaDot.dotSize.SMALL"
    ></qima-dot>
  </div>
</div>
