import { IFormItemConfig } from '@aca-new/app/shared/components/form-item/shared/interfaces/form-item-config.interface';
import { EFormItemVerticalAlign } from '@aca-new/app/shared/components/form-item/shared/models/enums/form-item-vertical-align.enum';
import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { ESize } from '@aca-new/app/shared/models/enums/size.enum';
import { InjectionToken } from '@angular/core';

export const APP_FORM_ITEM_CONFIG_TOKEN: InjectionToken<IFormItemConfig> = new InjectionToken<IFormItemConfig>('The default values used by the inputs of the form item component', {
  factory(): IFormItemConfig {
    /**
     * The fallback values should be identical as the fallback [provider]{@link FormItemModule#forRoot} values
     */
    return {
      inputSize: ESize.NONE,
      inputWidth: 'auto',
      labelWidth: 'auto',
      labelSize: ESize.NONE,
      verticalAlign: EFormItemVerticalAlign.BASELINE,
      orientation: EOrientation.HORIZONTAL,
    };
  },
});
