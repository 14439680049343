import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isString } from 'lodash/index';

/**
 * @description
 * Used to send user session data to appropriate project on logRocket identified by environment id
 * @param {IMyWindow} window The window object containing possibly the "LOGROCKET_ENVIRONMENT_ID" variable
 * @returns {string} Return the environment ID if it exists or null otherwise
 */
export function logRocketEnvironmentIdFactory(window: IMyWindow): QimaOptionalType<string> {
  const logRocketEnvironmentId = window.logrocketEnvironmentId;

  return isString(logRocketEnvironmentId) ? logRocketEnvironmentId : null;
}
