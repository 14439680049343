import { ICurrency } from '@aca-new/app/shared/components/currency-view/shared/models/interfaces/currency.interface';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-currency-view',
  templateUrl: './currency-view.component.html',
  styleUrls: ['./currency-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyViewComponent {
  /**
   * @description
   * The currency
   * @type {QimaOptionalType<ICurrency>}
   * @default undefined
   */
  @Input('currencyViewData')
  public data: QimaOptionalType<ICurrency> = undefined;
}
