export enum EFormItemVerticalAlign {
  // top 14px
  BASELINE = 'baseline',
  // top 34px
  LARGE = 'large',
  // top 8px
  MIDDLE = 'middle',
  // top 0px
  TOP = 'top',
}
