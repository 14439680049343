import { ByPassSecurityTrustPipe } from '@aca-new/app/shared/pipes/pass-security-trust.pipe';
import { TableBadgeLabelPipe } from '@aca-new/app/shared/pipes/table-badge/table-badge-label.pipe';
import { ToBooleanPipe } from '@aca-new/app/shared/pipes/to-boolean.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ToBooleanPipe, TableBadgeLabelPipe, ByPassSecurityTrustPipe],
  exports: [ToBooleanPipe, TableBadgeLabelPipe, ByPassSecurityTrustPipe],
})
export class AppCommonPipeModule {}
