import { IAnalyticTab } from '@aca-new/app/pages/analytics/shared/models/interfaces/analytics.interface';
import { AnalyticsService } from '@aca-new/app/pages/analytics/shared/services/analytics.service';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsHttpService {
  public constructor(
    private readonly _storageService: StorageService,
    private readonly _analyticsService: AnalyticsService,
    private readonly _httpService: HttpService
  ) {}

  public getAnalyticTabs$(): Observable<IAnalyticTab[]> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const analyticTabsSession: string | null = this._storageService.getItem(EStorageKeys.ANALYTICS_TABS);

    if (analyticTabsSession) {
      return of(JSON.parse(analyticTabsSession));
    }

    return this._httpService.httpClient
      .get<IHttpResponseBody<IAnalyticTab[]>>(`${SERVER_URL}/user/${userId}/get-dashboard-tabs`, {
        observe: 'body',
      })
      .pipe(
        map((response: IHttpResponseBody<IAnalyticTab[]>): IAnalyticTab[] => {
          let data = response.content || [];

          data = data
            .filter((item): boolean => item.tabName.trim() !== '')
            .sort((tab1, tab2): number => {
              if (+tab1.tab > +tab2.tab) {
                return 0;
              }

              return -1;
            })
            .map((tab: IAnalyticTab): IAnalyticTab => {
              return { ...tab, tabKey: this._analyticsService.replaceSpaceToUnderline(tab.tabName) };
            });

          this._storageService.setItem(EStorageKeys.ANALYTICS_TABS, JSON.stringify(data));

          return data;
        })
      );
  }
}
