// TODO delete SOLUTION_TYPE or EBusinessSolution,they should only have one
export enum EBusinessSolution {
  AUDITS = 'audits',
  CALLBACKS = 'callbacks',
  CERTIFICATIONS = 'certifications',
  FOOD_CERTIFICATION = 'food certification',
  INSPECTIONS = 'inspections',
  LAB_TESTING = 'lab-testing',
  SELF_ASSESSMENT = 'self-assessment',
  TRAINING = 'training',
  TRANSLATION = 'translation',
  WQS = 'WQS',
}
