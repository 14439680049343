import { TranslateService } from '@ngx-translate/core';
import { EQimaTranslationKey, QimaTranslationFactory } from '@qima/ngx-qima';
import { BehaviorSubject, Observable } from 'rxjs';

export class TranslationService implements QimaTranslationFactory {
  public readonly activeLanguageSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(this._localeId);
  public constructor(
    private readonly _translateService: TranslateService,
    private readonly _localeId: string
  ) {}

  public translate$(translationKey: Readonly<EQimaTranslationKey>, extra?: Readonly<Record<string, unknown>>): Observable<string> {
    // Note the prefix to match the JSON paths
    return this._translateService.get(`ngx-qima.${translationKey}`, extra);
  }
}
