<div class="payment-fail">
  <app-dialog-header (dialogHeaderClose)="close()"> </app-dialog-header>

  <div class="payment-fail__container">
    <span class="payment-fail__container__title">{{ 'PAYMENTS.WENT_WRONG' | translate }}</span>
    <span class="payment-fail__container__result">{{ 'PAYMENTS.TRY_AGAIN_LATER' | translate }}</span>
  </div>

  <qima-button
    class="qima-mt-l"
    [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
    [qimaButtonLabel]="'PAYMENTS.TRY_AGAIN' | translate"
    [qimaButtonSize]="qimaButton.buttonSize.LARGE"
    (qimaButtonClick)="close()"
  ></qima-button>
</div>
