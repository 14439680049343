import { googleAnalyticsPreviewFactory } from '@aca-new/app/shared/factories/google-analytics/google-analytics-preview.factory';
import { GOOGLE_ANALYTICS_PREVIEW } from '@aca-new/app/shared/tokens/google-analytics-preview.token';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const GOOGLE_ANALYTICS_PREVIEW_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  useFactory: googleAnalyticsPreviewFactory,
  provide: GOOGLE_ANALYTICS_PREVIEW,
};
