import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppScrollService {
  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public getTopOffset(controlEl: Readonly<HTMLElement>): number {
    // a suitable adjustment distance after scrolling (can be modified)
    const offset = 80;

    return controlEl.getBoundingClientRect().top + this._window.scrollY - offset;
  }

  public scrollToFirstInvalidControl(el: ElementRef, alternativeSelectors: string[] = []): void {
    const classes = ['.app-form-group__input__content .ng-invalid', '.supplier-information .ng-invalid', '.inspection-details .ng-invalid', ...alternativeSelectors];
    const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(classes.join(','));

    if (!firstInvalidControl) {
      return;
    }

    firstInvalidControl.style.scrollMarginTop = '80px';
    firstInvalidControl.scrollIntoView({ behavior: 'smooth' });
    firstInvalidControl.style.scrollMarginTop = '';
  }

  public scrolledByClassName(selector: string = '.scroll-area', block: ScrollLogicalPosition = 'center'): void {
    this._document.querySelector(selector)?.scrollIntoView({
      block,
      inline: 'nearest',
      behavior: 'smooth',
    });
  }

  public scrolledToBottom(): void {
    this._window.scroll({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
}
