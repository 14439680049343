import { AppSnackbarService } from '@aca-new/app/shared/services/modal-services/app-snackbar/app-snackbar.service';
import { EmailsValidators } from '@aca-new/app/shared/validators/emails.validators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { TranslateService } from '@ngx-translate/core';
import { EQimaSnackbarType, qimaIsOptionalTrueStringBoolean, QimaOptionalType, QimaSimpleChangesType, QimaStringBooleanType } from '@qima/ngx-qima';
import { has, isFunction } from 'lodash/index';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): typeof EmailComponent => EmailComponent),
      multi: true,
    },
  ],
})
export class EmailComponent extends ControlValueAccessor<string> implements OnChanges {
  /**
   * @description
   * Define the placeholder of the email input
   * @type {string}
   * @default ''
   */
  @Input('emailPlaceholder')
  public placeholder: string = '';

  /**
   * @description
   * Change the UI to either green or red based on the user interactions (dirty, unfocused and touched state)
   * This can be very useful if you display a page that needs form validation
   * If you wish to display on the first rendering the validation state,
   * You can simply mark the control as dirty and touched
   * @example
   * formControl.markAsDirty();
   * formControl.markAsTouched();
   * @type {QimaOptionalType<QimaStringBooleanType>}
   * @default false
   */
  @Input('emailHasValidators')
  public __hasValidators: QimaOptionalType<QimaStringBooleanType> = undefined;

  /**
   * @description
   * The value is required or optional
   * @type {QimaOptionalType<QimaStringBooleanType>}
   * @default false
   */
  @Input('emailIsRequired')
  public __isRequired: QimaOptionalType<QimaStringBooleanType> = undefined;

  /**
   * @description
   * Disable or enable the input
   * @type {QimaOptionalType<QimaStringBooleanType>}
   * @default false
   */
  @Input('emailIsDisabled')
  public __isDisabled: QimaOptionalType<QimaStringBooleanType> = undefined;

  public value: string = '';
  public emails: FormControl<string[]> = new FormControl<string[]>();
  public hasValidatorsState: boolean = false;
  public isRequiredState: boolean = false;
  public isDisabledState: boolean = false;

  public constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _appSnackbarService: AppSnackbarService,
    private readonly _translateService: TranslateService
  ) {
    super();
  }

  public ngOnChanges(changes: Readonly<QimaSimpleChangesType<EmailComponent>>): void {
    this._onHasValidatorsChange(changes);
    this._onIsRequiredChange(changes);
    this._onIsDisabledChange(changes);
    this._changeDetectorRef.markForCheck();
  }

  public writeValue(value: Readonly<string>): void {
    this.emails = new FormControl<string[]>(value ? value.replace(/[,|;]/g, ' ').trim().split(' ') : []);

    if (this.isRequiredState) {
      this.emails.addValidators(Validators.required);
    }

    if (this.hasValidatorsState) {
      this.emails.addValidators(EmailsValidators.emails);
    }

    this.emails.markAsDirty();
    this._changeDetectorRef.detectChanges();
    this.emails.updateValueAndValidity();
  }

  public registerOnTouched(fn: () => void): void {
    super.registerOnTouched((): void => {
      fn();
    });
  }

  public registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  public onEmailsChange(emails: Readonly<string[]>): void {
    this.value = emails.join(';');

    if (isFunction(this.onChange)) {
      this.onChange(this.value ?? null);
    }
  }

  public acceptEmail(value: Readonly<string>): boolean {
    return !this.value.includes(value);
  }

  public valueRefused(value: Readonly<string>): void {
    return this._appSnackbarService.showSnackbar(this._translateService.instant('COMMON.EMAIL_DUPLICATION', { email: value }) as string, EQimaSnackbarType.WARNING);
  }

  private _onHasValidatorsChange(changes: Readonly<QimaSimpleChangesType<EmailComponent>>): void {
    if (has(changes, '__hasValidators') && changes.__hasValidators.currentValue !== changes.__hasValidators.previousValue) {
      this.hasValidatorsState = qimaIsOptionalTrueStringBoolean(changes.__hasValidators.currentValue);
    }
  }

  private _onIsRequiredChange(changes: Readonly<QimaSimpleChangesType<EmailComponent>>): void {
    if (has(changes, '__isRequired') && changes.__isRequired.currentValue !== changes.__isRequired.previousValue) {
      this.isRequiredState = qimaIsOptionalTrueStringBoolean(changes.__isRequired.currentValue);
    }
  }

  private _onIsDisabledChange(changes: Readonly<QimaSimpleChangesType<EmailComponent>>): void {
    if (has(changes, '__isDisabled') && changes.__isDisabled.currentValue !== changes.__isDisabled.previousValue) {
      this.isDisabledState = qimaIsOptionalTrueStringBoolean(changes.__isDisabled.currentValue);
    }
  }
}
