<div class="dialog-confirm">
  <app-dialog-common [dialogCommonTitle]="dialogData.title">
    <div
      class="content-confirm"
      [innerHTML]="dialogData.content | translate"
    ></div>
    <div class="foot-confirm">
      <qima-button
        *ngIf="dialogData.hasCancel"
        [qimaButtonLabel]="'COMMON.CANCEL' | translate"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
        (qimaButtonClick)="close()"
      ></qima-button>
      <qima-button
        class="qima-ml-xs"
        [qimaButtonLabel]="dialogData.confirmButtonLabel || 'COMMON.YES' | translate"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonWidth]="qimaButton.buttonWidth.MIN"
        [qimaButtonStyle]="qimaButton.buttonStyle.PRIMARY"
        (qimaButtonClick)="confirm()"
      ></qima-button>
    </div>
  </app-dialog-common>
</div>
