import { CurrencyTableCellModule } from '@aca-new/app/shared/components/currency-table-cell/currency-table-cell.module';
import { EmptyContentModule } from '@aca-new/app/shared/components/empty-content/empty-content.module';
import { CommonTableCellComponent } from '@aca-new/app/shared/components/table/shared/components/common-table-cell/common-table-cell.component';
import { DecisionLabelCellComponent } from '@aca-new/app/shared/components/table/shared/components/common-table-cell/shared/decision-label-cell/decision-label-cell.component';
import { MultipleLabelCellComponent } from '@aca-new/app/shared/components/table/shared/components/common-table-cell/shared/multiple-label-cell/multiple-label-cell.component';
import { ProgressIndicatorWithArrowComponent } from '@aca-new/app/shared/components/table/shared/components/common-table-cell/shared/progress-indicator-with-arrow/progress-indicator-with-arrow.component';
import { CustomizedTableCellComponent } from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/customized-table-cell.component';
import { CustomizedTableCellDirective } from '@aca-new/app/shared/components/table/shared/components/customized-table-cell/shared/directives/customized-table-cell.directive';
import { TableComponent } from '@aca-new/app/shared/components/table/table.component';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { AppCommonPipeModule } from '@aca-new/app/shared/pipes/app-common-pipe.module';
import { AppServiceTypeFullNameModule } from '@aca-new/app/shared/pipes/service-type-full-name/service-type-full-name.module';
import { TableBadgeStylePipe } from '@aca-new/app/shared/pipes/table-badge/table-badge-style.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  QimaAutolinkerPipeModule,
  QimaAutoTooltipDirectiveModule,
  QimaButtonIconModule,
  QimaDotModule,
  QimaIconModule,
  QimaTableBodyCellBadgeModule,
  QimaTableBodyCellCheckboxModule,
  QimaTableBodyCellIceCubeModule,
  QimaTableBodyCellTextModule,
  QimaTableBodyModule,
  QimaTableHeadCellCheckboxModule,
  QimaTableHeadCellModule,
  QimaTableHeadModule,
  QimaTableModule,
  QimaTableRowModule,
  QimaTooltipDirectiveModule,
} from '@qima/ngx-qima';

@NgModule({
  declarations: [
    TableComponent,
    CommonTableCellComponent,
    TableBadgeStylePipe,
    DecisionLabelCellComponent,
    MultipleLabelCellComponent,
    CustomizedTableCellComponent,
    CustomizedTableCellDirective,
  ],
  imports: [
    TranslateModule,
    QimaTableHeadCellModule,
    QimaTableModule,
    QimaTableHeadModule,
    CommonModule,
    QimaTableRowModule,
    QimaTableBodyCellTextModule,
    QimaTableBodyModule,
    QimaTableBodyCellIceCubeModule,
    QimaTooltipDirectiveModule,
    QimaAutolinkerPipeModule,
    QimaTableBodyCellCheckboxModule,
    QimaTableBodyCellBadgeModule,
    QimaDotModule,
    QimaTableHeadCellCheckboxModule,
    QimaIconModule,
    QimaAutoTooltipDirectiveModule,
    CurrencyTableCellModule,
    AppCommonPipeModule,
    EmptyContentModule,
    QimaButtonIconModule,
    AppServiceTypeFullNameModule,
    TrackByUuidDirective,
    ProgressIndicatorWithArrowComponent,
  ],
  exports: [TableComponent, CommonTableCellComponent, CustomizedTableCellComponent, TableBadgeStylePipe],
})
export class TableModule {}
