import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-payment-fail',
  templateUrl: './payment-fail.component.html',
  styleUrls: ['./payment-fail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFailComponent {
  public constructor(
    private readonly _dialogRef: DialogRef<void>,
    public readonly qimaButton: QimaButtonService
  ) {}

  public close(): void {
    this._dialogRef.close();
  }
}
