<app-overlay
  (overlayClick)="onOverlayClick()"
  *ngIf="isAsideMenuVisible"
></app-overlay>
<div
  [@expandCollapse]="isAsideCollapsed ? 'collapsed' : 'expanded'"
  [class.app-aside--collapsed]="isAsideCollapsed"
  class="app-aside"
>
  <div class="app-aside__container">
    <app-book-now
      (bookNowClick)="onBookNowClick()"
      *ngIf="isBookNowVisible"
      [isBookNowChecked]="isAsideMenuVisible"
      [isBookNowCollapsed]="isAsideCollapsed"
    ></app-book-now>
    <ng-container>
      <ng-container
        *ngFor="let item of menuItems | slice: 0 : (isMobile ? 5 : menuItems.length); let index = index"
        appNgForTrackByUuid
      >
        <ng-container>
          <app-aside-item
            (asideItemClick)="onAsideItemClick(index, item)"
            *ngIf="item.visible"
            [iconName]="item.iconName"
            [isAsideItemChecked]="index === asideItemCheckedIndex"
            [isAsideItemCollapsed]="isAsideCollapsed"
            [label]="item.label | translate"
            [ngClass]="item.class || ''"
            [qimaTooltipHasArrow]="true"
            [qimaTooltipIsVisible]="isAsideCollapsed"
            [qimaTooltipPosition]="tooltipPosition"
            [qimaTooltipText]="item.tooltip | translate"
            qimaTooltip
          ></app-aside-item>
        </ng-container>
        <ng-container *ngIf="isSubMenuVisible && item.subItems">
          <div
            [ngClass]="{ subitemCollapsed: isAsideCollapsed, changeSubItemsPosition: isNetworkOnly }"
            class="subitems-dropdown"
          >
            <ng-container
              *ngFor="let subItem of item.subItems; let subIndex = index"
              appNgForTrackByUuid
            >
              <app-aside-item
                (asideItemClick)="onAsideItemClick(subIndex, subItem, item.subItems)"
                [iconName]="subItem.iconName"
                [isAsideItemChecked]="subIndex === subMenuItemCheckedIndex"
                [isAsideItemCollapsed]="isAsideCollapsed"
                [label]="subItem.label | translate"
              >
              </app-aside-item>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <app-aside-item
      (asideItemClick)="onBackToACAClick()"
      *ngIf="isSystemSwitchVisible"
      [iconName]="qimaIcon.iconName.SWITCH"
      [isAsideItemCollapsed]="isAsideCollapsed"
      [label]="'APP_ASIDE.ASIDE_MENU.SYSTEM_SWITCHER_LABEL' | translate"
      [qimaTooltipHasArrow]="true"
      [qimaTooltipIsVisible]="isAsideCollapsed"
      [qimaTooltipPosition]="tooltipPosition"
      [qimaTooltipText]="'APP_ASIDE.ASIDE_MENU.SYSTEM_SWITCHER_LABEL' | translate"
      class="system-switcher"
      qimaTooltip
    ></app-aside-item>
  </div>
</div>
<app-aside-menu
  [asideMenuDynamicBookingMenus]="dynamicBookingMenus"
  [asideMenuIsVisible]="isAsideMenuVisible"
></app-aside-menu>
<app-aside-more
  (asideMoreCloseClick)="onAsideMoreCloseClick()"
  (asideMoreMenuClick)="onAsideMoreMenuClick($event)"
  *ngIf="isAsideMoreVisible"
  [asideMoreMenusItems]="menuItems"
></app-aside-more>
