import { IDataTableData } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class DataTableHttpService {
  public readonly refreshTable$: Subject<void> = new Subject<void>();

  public constructor(private readonly _httpClient: HttpClient) {}
  public refreshTable(): void {
    this.refreshTable$.next();
  }

  public getTableData$(url: string): Observable<QimaOptionalType<IDataTableData>> {
    return this._httpClient
      .get<IDataTableData>(url, { observe: 'response' })
      .pipe(map((response: Readonly<HttpResponse<IDataTableData>>): QimaOptionalType<IDataTableData> => response?.body));
  }
}
