import { BadgeStyleService } from '@aca-new/app/shared/pipes/table-badge/shared/services/badge-style.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableBadgeLabel',
  pure: true,
})
export class TableBadgeLabelPipe implements PipeTransform {
  public constructor(private readonly _badgeStyleService: BadgeStyleService) {}

  public transform(result: string): string {
    return this._badgeStyleService.getBadgeConfig(result, 'label');
  }
}
