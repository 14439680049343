import { IDynamicBookingMenus } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/dynamic-booking-menus.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AsideHttpService {
  public readonly dynamicBookingMenusSubject$: Subject<void> = new Subject<void>();

  public constructor(
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService
  ) {}

  /**
   * @description
   * Propagate an event to notify that the dynamic booking menus have been updated
   * @returns {Observable<void>} - Observable that emits when the dynamic booking menus have been updated
   */
  public dynamicBookingMenu$(): Observable<void> {
    return this.dynamicBookingMenusSubject$.asObservable();
  }

  public getDynamicBookingMenus$(): Observable<IDynamicBookingMenus> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<IHttpResponseBody<IDynamicBookingMenus>>(`${SERVER_URL}/v1.0/service/booking-form/${userId}`, {
        observe: 'body',
      })
      .pipe(map((response: IHttpResponseBody<IDynamicBookingMenus>): IDynamicBookingMenus => response?.content || {}));
  }
}
