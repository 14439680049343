import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionTitleComponent {
  /**
   * @description
   * Set the title
   * @type {string}
   * @default ''
   */
  @Input('sectionTitleTitle')
  public title: string = '';

  /**
   * @description
   * Set the icon
   * @type {string}
   * @default ''
   */
  @Input('sectionTitleIcon')
  public icon: string = '';
}
