import { DialogCommonComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-common/dialog-common.component';
import { DialogHeaderModule } from '@aca-new/app/shared/components/modal/shared/components/dialog-header/dialog-header.module';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DialogCommonComponent],
  exports: [DialogCommonComponent],
  imports: [DialogHeaderModule],
})
export class DialogCommonModule {}
