import { TranslateMenuComponent } from '@aca-new/app/shared/components/translate-menu/translate-menu.component';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaDotModule, QimaShadowDirectiveModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [TranslateMenuComponent],
  exports: [TranslateMenuComponent],
  imports: [CommonModule, QimaShadowDirectiveModule, TranslateModule, QimaDotModule, TrackByUuidDirective],
})
export class TranslateMenuModule {}
