import { IFormItemConfig } from '@aca-new/app/shared/components/form-item/shared/interfaces/form-item-config.interface';
import { EFormItemVerticalAlign } from '@aca-new/app/shared/components/form-item/shared/models/enums/form-item-vertical-align.enum';
import { APP_FORM_ITEM_CONFIG_TOKEN } from '@aca-new/app/shared/components/form-item/shared/tokens/form-item-config.token';
import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { ESize } from '@aca-new/app/shared/models/enums/size.enum';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormItemComponent<T1, T2 extends Record<keyof T2, unknown>> implements OnInit {
  /**
   * @description
   * The form item vertical align
   * @type {QimaOptionalType<EFormItemVerticalAlign>}
   * @default undefined
   */
  @Input('formItemVerticalAlign')
  public verticalAlign: QimaOptionalType<EFormItemVerticalAlign> = this._config.verticalAlign;

  /**
   * @description
   * The label for display
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input('formItemLabel')
  public label: QimaOptionalType<string> = undefined;

  /**
   * @description
   * The label is disabled
   * @type {boolean}
   * @default false
   */
  @Input('formItemLabelIsDisabled')
  public isLabelDisabled: boolean = false;

  /**
   * @description
   * The item has distance or not
   * @type {boolean}
   * @default true
   */
  @Input('formItemHasDistance')
  // TODO need a best way to deal with
  public hasDistance: boolean = true;

  /**
   * @description
   * The label of the tooltip
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input('formItemTooltipLabel')
  public tooltipLabel: QimaOptionalType<string> = undefined;

  /**
   * @description
   * The size of form input size
   * @type {ESize}
   * @default {@link ESize.NONE}
   */
  @Input('formItemInputSize')
  public inputSize: ESize = this._config.inputSize;

  /**
   * @description
   * The placement of form label and form input
   * @type {EOrientation}
   * @default {@link EOrientation.HORIZONTAL}
   */
  @Input('formItemOrientation')
  public orientation: EOrientation = this._config.orientation;

  /**
   * @description
   * Show or hide the required icon
   * @type {boolean}
   * @default false
   */
  @Input('formItemIsRequired')
  public isRequired: boolean = false;

  /**
   * @description
   * Show or hide the optional text
   * @type {boolean}
   * @default false
   */
  @Input('formItemIsOptional')
  public isOptional: boolean = false;

  /**
   * @description
   * The width of the label
   * @type {number | 'auto'}
   * @default 'auto'
   */
  @Input('formItemLabelWidth')
  public labelWidth: number | string = this._config.labelWidth;

  /**
   * @description
   * The width of the input
   * @type {number | 'auto'}
   * @default 'auto'
   */
  @Input('formItemInputWidth')
  public inputWidth: number | string = this._config.inputWidth;

  /**
   * @description
   * The validation messages
   * @type {QimaOptionalType<Record<string, string>>}
   * @default undefined
   */
  @Input('formItemValidationMessages')
  public validationMessages: QimaOptionalType<Record<string, string>> = undefined;

  /**
   * @description
   * The control of form item
   * @type {QimaOptionalType<FormControl>}
   * @default undefined
   */
  @Input('formItemControl')
  public control: QimaOptionalType<FormControl<T1>> = undefined;

  /**
   * @description
   * The form group which contains this control
   * @type {QimaOptionalType<FormGroup<ControlsOf>>}
   * @default undefined
   */
  @Input('formItemFormGroup')
  public formGroup: QimaOptionalType<FormGroup<ControlsOf<T2>>> = undefined;

  public constructor(@Inject(APP_FORM_ITEM_CONFIG_TOKEN) private readonly _config: Readonly<IFormItemConfig>) {}

  public ngOnInit(): void {
    if (!this.verticalAlign) {
      this.verticalAlign = this.isOptional ? EFormItemVerticalAlign.MIDDLE : EFormItemVerticalAlign.BASELINE;
    }
  }
}
