import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { QimaDotService } from '@aca-new/app/shared/services/exported-services/qima-dot/qima-dot.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-common-table-cell',
  templateUrl: './common-table-cell.component.html',
  styleUrls: ['./common-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonTableCellComponent {
  /**
   * @description
   * the data of the cell
   * @type {ITableBodyCell}
   * @default undefined
   */
  @Input('commonTableCellData')
  public cellData: QimaOptionalType<ITableBodyCell> = undefined;

  /**
   * @description
   * the origin data of the cell
   * @type {QimaOptionalType<unknown>}
   * @default undefined
   */
  @Input('commonTableCellOriginData')
  public originData: QimaOptionalType<unknown> = undefined;

  /**
   * @description
   * the width of the cell
   * @type {string}
   * @default 'auto'
   */
  @Input('commonTableCellWidth')
  public width: string = 'auto';

  /**
   * @description
   * the data of the index
   * @type {number}
   * @default 0
   */
  @Input('commonTableCellIndex')
  public cellIndex: number = 0;

  // TODO this output need redesign to solution multi icon click
  @Output('commonTableIconCellClick')
  public readonly iconCellClick: EventEmitter<number> = new EventEmitter<number>();

  public dataType = ETableDataType;

  public constructor(
    public readonly qimaTooltip: QimaTooltipService,
    public readonly qimaDot: QimaDotService
  ) {}

  public onIconCellClick(): void {
    this.iconCellClick.emit(this.cellIndex);
  }
}
