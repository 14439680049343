import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-dialog-in-progress',
  templateUrl: './dialog-in-progress.component.html',
  styleUrls: ['./dialog-in-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogInProgressComponent {
  public constructor(
    private readonly _appLocationService: AppLocationService,
    public readonly qimaButton: QimaButtonService
  ) {}

  public onBackToACAClick(): void {
    this._appLocationService.backToACA();
  }
}
