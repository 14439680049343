import { EExternalSharedReportStorageKeys } from '@aca-new/app/pages/reports/pages/external-shared-report/shared/models/external-shared-report-storage-keys.enum';
import { AI_API_ACCESS_TOKEN_GREY_LABEL } from '@aca-new/app/shared/constants/ai-api-access-token.constants';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable()
export class ExternalSharedReportHttpAuthInterceptor implements HttpInterceptor {
  public constructor(private readonly _cookieStorage: CookieService) {}

  public intercept<T>(request: Readonly<HttpRequest<T>>, next: Readonly<HttpHandler>): Observable<HttpEvent<T>> {
    const authToken = this._cookieStorage.get(EExternalSharedReportStorageKeys.EXTERNAL_REPORT_AUTH_TOKEN);
    const pageToken = this._cookieStorage.get(EExternalSharedReportStorageKeys.EXTERNAL_REPORT_PAGE_TOKEN) ?? '';
    const setHeaders: Record<string, string> = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'ai-api-access-token': AI_API_ACCESS_TOKEN_GREY_LABEL,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'ai-api-external-app-id': pageToken,
    };

    if (authToken) {
      setHeaders['authorization'] = `Bearer ${authToken}`;
    }

    request = request.clone({
      url: request.url.replace(/(?<=user\/)[^/]*(?=\/)/, pageToken),
      setHeaders,
    });

    return next.handle(request);
  }
}
