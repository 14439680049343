import { AsideComponent } from '@aca-new/app/pages/main/shared/components/aside/aside.component';
import { AsideItemComponent } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-item/aside-item.component';
import { AsideMenuModule } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-menu/aside-menu.module';
import { AsideMoreComponent } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-more/aside-more.component';
import { BookNowComponent } from '@aca-new/app/pages/main/shared/components/aside/shared/components/book-now/book-now.component';
import { OverlayModule } from '@aca-new/app/shared/components/overlay/overlay.module';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaDotModule, QimaIconModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [AsideComponent, BookNowComponent, AsideItemComponent],
  exports: [AsideComponent, AsideItemComponent],
  imports: [QimaIconModule, CommonModule, QimaDotModule, AsideMenuModule, OverlayModule, TranslateModule, QimaTooltipDirectiveModule, AsideMoreComponent, TrackByUuidDirective],
})
export class AsideModule {}
