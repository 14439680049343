import { FormValidationMessageComponent } from '@aca-new/app/shared/components/form-validation-message/form-validation-message.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FormValidationMessageComponent],
  exports: [FormValidationMessageComponent],
  imports: [CommonModule, TranslateModule],
})
export class FormValidationMessageModule {}
