import { AI_API_ACCESS_TOKEN } from '@aca-new/app/shared/constants/ai-api-access-token.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@UntilDestroy()
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _cookieStorage: CookieService,
    private readonly _storageService: StorageService
  ) {}

  public intercept<T>(request: Readonly<HttpRequest<T>>, next: Readonly<HttpHandler>): Observable<HttpEvent<T>> {
    const token = this._cookieStorage.get(EStorageKeys.AUTH_TOKEN);
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    if (token && userId) {
      request = request.clone({
        setHeaders: {
          'authorization': `Bearer ${token}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'ai-user-id': userId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'ai-api-access-token': AI_API_ACCESS_TOKEN,
        },
      });
    } else {
      this._appAuthenticationService.logout();
    }

    return next.handle(request);
  }
}
