import { SwCheckForUpdateService } from '@aca-new/app/shared/services/service-worker-services/sw-check-for-update/sw-check-for-update.service';
import { SwReloadForUpdateService } from '@aca-new/app/shared/services/service-worker-services/sw-reload-for-update/sw-reload-for-update.service';

/**
 * @param {SwReloadForUpdateService} swReloadForUpdateService A service observe the event from service worker
 * @param {SwCheckForUpdateService} swCheckForUpdateService A service check the update from service worker
 * @returns {() => void} A function to init the observer
 */
export function serviceWorkerFactory(swReloadForUpdateService: SwReloadForUpdateService, swCheckForUpdateService: SwCheckForUpdateService): () => void {
  return (): void => {
    swCheckForUpdateService.init();
    swReloadForUpdateService.init();
  };
}
