import { Injectable } from '@angular/core';
import { EQimaButtonIconPosition, EQimaIconName, EQimaIconUnit } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class QimaIconService {
  public readonly sizeSmall: number = 13;
  public readonly sizeMedium: number = 16;
  public readonly iconName = EQimaIconName;
  public readonly iconPosition = EQimaButtonIconPosition;
  public readonly iconUnit = EQimaIconUnit;
}
