import { SectionTitleComponent } from '@aca-new/app/shared/components/section-title/section-title.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SectionTitleComponent],
  exports: [SectionTitleComponent],
  imports: [TranslateModule],
})
export class SectionTitleModule {}
