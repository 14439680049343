import { ExternalSharedReportHttpAuthInterceptor } from '@aca-new/app/pages/reports/pages/external-shared-report/shared/services/external-shared-report-http-auth-interceptor.service';
import { PATHS } from '@aca-new/app/shared/constants/url-path.constants';
import { HttpAuthInterceptor } from '@aca-new/app/shared/services/http-services/http-auth-handler/http-auth-interceptor.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _router: Router,
    private readonly _httpAuthInterceptor: HttpAuthInterceptor,
    private readonly _externalReportHttpAuthInterceptor: ExternalSharedReportHttpAuthInterceptor
  ) {}

  public intercept<T>(request: Readonly<HttpRequest<T>>, next: Readonly<HttpHandler>): Observable<HttpEvent<T>> {
    const unauthorizedArray = ['/assets/', '/common-payment', 'COMMON_PAYMENT_BU_MERCHANT_ID_MAPPING'];

    if (this._router.url.startsWith('/pay/')) {
      unauthorizedArray.push('/payment/adyen/');
    }

    if (!request?.url || unauthorizedArray.some((url: string): boolean => request?.url.includes(url))) {
      return next.handle(request);
    }

    return this._getInterceptor().intercept(request, next);
  }

  private _getInterceptor(): HttpInterceptor {
    if (this._router.url.startsWith(PATHS.externalReport)) {
      return this._externalReportHttpAuthInterceptor;
    }

    return this._httpAuthInterceptor;
  }
}
