import { DialogRef } from '../classes/dialog.ref';
import { DIALOG_DATA } from '../tokens/dialog.token';
import { DialogDeleteComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-delete/dialog-delete.component';
import { IDialogDelete } from '@aca-new/app/shared/components/modal/shared/components/dialog-delete/shared/interfaces/dialog-delete.interface';
import { Overlay, ComponentType } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable } from 'rxjs';

export interface IDialogConfig {
  data?: unknown;
  hasBackdrop?: boolean;
  stopCloseWhenClickBackdrop?: boolean;
}

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class DialogService {
  public constructor(
    private readonly _overlay: Overlay,
    private readonly _injector: Injector
  ) {}

  public open<T1, T2>(component: ComponentType<T1>, config?: IDialogConfig, providers?: StaticProvider[]): DialogRef<T2> {
    // Globally centered position strategy
    const positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();
    // Create the overlay with customizable options
    const overlayRef = this._overlay.create({
      positionStrategy,
      hasBackdrop: config?.hasBackdrop ?? true,
      backdropClass: 'overlay-backdrop',
      panelClass: 'overlay-panel',
      disposeOnNavigation: true,
    });

    // close when click backend
    overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        if (config?.stopCloseWhenClickBackdrop) {
          return;
        }

        overlayRef.dispose();
        document.body.style.overflow = '';
      });

    // Create dialogRef to return
    const dialogRef = new DialogRef<T2>(overlayRef);
    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this._injector,
      providers: [{ provide: DialogRef, useValue: dialogRef }, { provide: DIALOG_DATA, useValue: config?.data }, ...(providers ?? [])],
    });
    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);

    overlayRef.attach(portal);
    document.body.style.overflow = 'hidden';

    return dialogRef;
  }

  public confirmDelete$(deleteData: IDialogDelete): Observable<QimaOptionalType<string>> {
    const dialogRef: DialogRef<string> = this.open(DialogDeleteComponent, {
      data: deleteData,
    });

    return dialogRef.afterClosed$();
  }
}
