<div class="app-progress-indicator-with-arrow">
  <qima-progress-indicator
    [qimaProgressIndicatorAmount]="4"
    [qimaProgressIndicatorValue]="value"
    [qimaTooltipHasArrow]="true"
    [qimaTooltipPosition]="qimaTooltip.position.LEFT"
    [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
    [qimaTooltipText]="tooltip || '' | translate"
    [style.width.px]="115"
    qimaTooltip
  ></qima-progress-indicator>
</div>
