import { IAsideMore } from '@aca-new/app/pages/main/shared/components/aside/shared/components/aside-more/shared/models/interfaces/aside-more.interface';
import { IAsideItem } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/aside-item.interface';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaButtonIconService } from '@aca-new/app/shared/services/exported-services/qima-button-icon/qima-button-icon.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaButtonIconModule, QimaIconModule, QimaTooltipDirectiveModule } from '@qima/ngx-qima';

@Component({
  selector: 'app-aside-more',
  templateUrl: './aside-more.component.html',
  styleUrls: ['./aside-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, QimaButtonIconModule, QimaTooltipDirectiveModule, TranslateModule, QimaIconModule, TrackByUuidDirective],
})
export class AsideMoreComponent implements OnInit {
  /**
   * @description
   * Menu items for aside more
   * @type {IAsideItem[]}
   * @default []
   */
  @Input('asideMoreMenusItems')
  public menuItems: IAsideItem[] = [];

  @Output('asideMoreCloseClick')
  public readonly closeClick: EventEmitter<void> = new EventEmitter<void>();

  @Output('asideMoreMenuClick')
  public readonly menuClick: EventEmitter<IAsideMore> = new EventEmitter<IAsideMore>();

  public constructor(
    public readonly qimaButton: QimaButtonService,
    public readonly qimaIcon: QimaIconService,
    public readonly qimaButtonIcon: QimaButtonIconService
  ) {}

  public ngOnInit(): void {
    this._initData();
  }

  public onCloseClick(): void {
    this.closeClick.emit();
  }

  public onMenuClick(index: number, item: IAsideItem, subMenuItems?: IAsideItem[]): void {
    if (item.subItems) {
      item.isExpanded = !item.isExpanded;
    } else {
      this.menuClick.emit({ index, item, subMenuItems });
      this.onCloseClick();
    }
  }

  private _initData(): void {
    this.menuItems = this.menuItems.map((item: IAsideItem): IAsideItem => {
      item.isExpanded = false;

      return item;
    });
  }
}
