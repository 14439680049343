<div class="payment-dialog">
  <app-dialog-header (dialogHeaderClose)="close()">
    <div class="payment-dialog__title">
      {{ 'PAYMENTS.VIEW_ORDER_SUMMARY' | translate }}
    </div>
  </app-dialog-header>

  <div class="payment-dialog__container">
    <table>
      <caption style="display: none">
        payment dialog
      </caption>
      <thead class="payment-dialog__fix-header">
        <th>{{ 'PAYMENTS.DESCRIPTION' | translate }}</th>
        <th>{{ 'PAYMENTS.AMOUNT' | translate }}</th>
      </thead>
      <ng-container
        *ngFor="let fee of orderFees"
        appNgForTrackByUuid
      >
        <ng-container *ngTemplateOutlet="feeItem; context: { fee: this.fee }"></ng-container>
        <ng-container *ngIf="fee.fees && fee.fees.length">
          <ng-container
            *ngFor="let subFee of fee.fees"
            appNgForTrackByUuid
          >
            <ng-container *ngTemplateOutlet="feeItem; context: { fee: subFee }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="totalItem; context: { fee: totalFees }"></ng-container>
    </table>
  </div>

  <qima-button
    (qimaButtonClick)="onProceedToPaymentClick()"
    [qimaButtonIsDisabled]="!orderFees.length"
    [qimaButtonLabel]="'PAYMENTS.PROCEED_TO_PAYMENT' | translate"
    [qimaButtonSize]="qimaButton.buttonSize.LARGE"
    [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
    class="qima-mt-l"
  ></qima-button>

  <app-payment-dialog-footer [paymentDialogFooterOrderIds]="paypalOrderIds"></app-payment-dialog-footer>
</div>

<!-- Template to display each kind of order fees -->
<ng-template
  #feeItem
  let-fee="fee"
>
  <tr
    *ngIf="fee.orderId"
    class="order-fee"
  >
    <td>
      <span class="order-fee__name">{{ fee.itemName }}</span>
      <span class="fee-price">{{ 'PAYMENTS.ITEM_price' | translate }}{{ fee.amountUnit }} {{ fee.unitPrice | number: '1.2-2' }}</span>
      <span class="fee-quantity">{{ 'PAYMENTS.QUANTITY' | translate }}{{ fee.quantity | number: '1.0-1' }}</span>
    </td>
    <td>
      <app-currency-view [currencyViewData]="{ code: fee.amountUnit, value: fee.amount }"></app-currency-view>
    </td>
  </tr>
</ng-template>

<ng-template
  #totalItem
  let-fee="fee"
>
  <tr
    *ngIf="fee"
    class="order-fee order-fee--sticky"
  >
    <td>
      <span class="order-fee__name">{{ 'PAYMENTS.TOTAL' | translate }}</span>
      <div class="order-fee__name__description">
        <div class="fee-price">{{ adyenFees }}</div>
        <qima-icon
          [qimaIconName]="qimaIcon.iconName.INFO"
          [qimaIconSize]="qimaIcon.sizeMedium"
          [qimaTooltipHasArrow]="true"
          [qimaTooltipPosition]="qimaTooltip.position.TOP_LEFT"
          [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
          [qimaTooltipStyle]="qimaTooltip.style.DARK"
          [qimaTooltipText]="'PAYMENTS.PAYMENTS_TOOLTIP' | translate"
          class="info-icon"
          qimaTooltip
        ></qima-icon>
      </div>
    </td>
    <td>
      <app-currency-view [currencyViewData]="{ code: 'PAYMENTS.USD' | translate, value: fee }"></app-currency-view>
    </td>
  </tr>
</ng-template>
