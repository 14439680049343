import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaLoggerService } from '@qima/ngx-qima';
import { Observable, tap } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SwReloadForUpdateService {
  public constructor(
    private readonly _swUpdate: SwUpdate,
    private readonly _qimaLoggerService: QimaLoggerService
  ) {}

  public init(): void {
    this._onSwUpdate$().pipe(untilDestroyed(this)).subscribe();
  }

  private _onSwUpdate$(): Observable<VersionEvent> {
    return this._swUpdate.versionUpdates.pipe(
      tap({
        next: (evt): void => {
          switch (evt.type) {
            case 'VERSION_DETECTED':
              this._qimaLoggerService.info(`📣 Downloading new app version: ${evt.version.hash}`);
              break;
            case 'VERSION_READY':
              this._qimaLoggerService.info(`📣 Current app version: ${evt.currentVersion.hash}`);
              this._qimaLoggerService.info(`🌈 New app version available reload in progress: ${evt.latestVersion.hash}`);
              void this._swUpdate.activateUpdate().then((): void => {
                document.location.reload();
                this._qimaLoggerService.info(`📣 New version is ${evt.latestVersion.hash}`);
              });
              break;
            case 'VERSION_INSTALLATION_FAILED':
              this._qimaLoggerService.error(`📣 Failed to install app version ${evt.version.hash}: ${evt.error}`);
              break;
          }
        },
        error: (err): void => {
          this._qimaLoggerService.error(err);
        },
      })
    );
  }
}
