export enum EBusinessUnit {
  ALS = 'ALS',
  BIOA = 'BIOA',
  CCLAB = 'CCLAB',
  CERTIS = 'CERTIS',
  CIS = 'CIS',
  ETIC = 'ETIC',
  HANSECONTROL = 'HANSECONTROL',
  IBD = 'IBD',
  MONASTERIUM = 'Monasterium',
  NEWTONE = 'Newtone',
  NYCE = 'NYCE',
  PROQC = 'PROQC',
  QIMA = 'AI',
  QIMA_FOOD = 'AFI',
  QIMA_MEXICO = 'GOAL',
  QIMA_PRODUCE = 'PIA',
  QIMAONE = 'QIMAONE',
  QUANTILAB = 'Quantilab',
  RID = 'RID',
  SBE = 'SBE',
  WQS = 'WQS',
}
