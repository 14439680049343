<div class="app-aside-more">
  <div class="app-aside-more__container">
    <div class="app-aside-more__container__header">
      <qima-button-icon
        (qimaButtonIconClick)="onCloseClick()"
        [qimaButtonIconSize]="qimaButton.buttonSize.SMALL"
        [qimaButtonIconStyle]="qimaButtonIcon.buttonIconStyle.TERTIARY_UTILITY"
        [qimaButtonIconName]="qimaIcon.iconName.CLOSE"
      ></qima-button-icon>
    </div>
    <div
      style="overflow: auto"
      *ngFor="let item of menuItems | slice: 5 : menuItems.length; let index = index"
      appNgForTrackByUuid
    >
      <div
        *ngIf="item.visible"
        class="app-aside-more__container__item"
        [ngClass]="{ 'app-aside-more__container__item--chevron-up': item.isExpanded }"
        (click)="onMenuClick(index, item)"
      >
        <ng-container
          class="table-toolbar-top-search"
          *ngTemplateOutlet="info; context: { iconName: item.iconName, label: item.label }"
        ></ng-container>
        <qima-icon
          *ngIf="item.subItems"
          [qimaIconName]="item.isExpanded ? qimaIcon.iconName.CHEVRON_UP : qimaIcon.iconName.CHEVRON_DOWN"
        ></qima-icon>
      </div>
      <div
        *ngIf="item.subItems && item.isExpanded"
        class="app-aside-more__container__sub"
      >
        <ng-container
          *ngFor="let subItem of item.subItems; let index = index"
          appNgForTrackByUuid
        >
          <div
            class="app-aside-more__container__item"
            (click)="onMenuClick(index, subItem, item.subItems)"
          >
            <ng-container
              class="table-toolbar-top-search"
              *ngTemplateOutlet="info; context: { iconName: subItem.iconName, label: subItem.label }"
            ></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template
  #info
  let-iconName="iconName"
  let-label="label"
>
  <div class="app-flex">
    <qima-icon [qimaIconName]="iconName"></qima-icon>
    <span class="qima-ml-s">{{ label | translate }}</span>
  </div>
</ng-template>
