import { WelcomeAccountInfoComponent } from '@aca-new/app/pages/home/pages/welcome-account-info/welcome-account-info.component';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEmpty } from 'lodash/index';

@UntilDestroy()
@Injectable()
export class EntryGuard implements CanActivate {
  public constructor(
    private readonly _dialogService: DialogService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _appAuthenticationService: AppAuthenticationService,

    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {}

  public canActivate(): boolean | Promise<boolean> {
    this._initWelcomeAccountInfoDialog();

    return true;
  }

  private _initWelcomeAccountInfoDialog(): void {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();

    if (isEmpty(userData)) {
      return;
    }

    if (!userData.company?.hearFrom && !this._appUserSettingService.getIsQimaProduce()) {
      const dialogRef = this._dialogService.open(WelcomeAccountInfoComponent, { stopCloseWhenClickBackdrop: true });

      dialogRef
        .afterClosed$()
        .pipe(untilDestroyed(this))
        .subscribe((): void => {
          this._window.location.reload();
        });
    }
  }
}
