import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AsideService {
  public readonly asideMenuVisible$: Subject<boolean> = new Subject<boolean>();
  public readonly asideItemCheckedIndex$: Subject<number> = new Subject<number>();
  public readonly isAsideCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly collapseMenuClick$: Subject<void> = new Subject<void>();

  public updateAsideMenuVisible(isVisible: boolean): void {
    this.asideMenuVisible$.next(isVisible);
  }

  public updateAsideItemCheckedIndex(index: number): void {
    this.asideItemCheckedIndex$.next(index);
  }

  public updateIsAsideCollapsed(isAsideCollapsed: boolean): void {
    this.isAsideCollapsed$.next(isAsideCollapsed);
  }

  public onCollapseMenuClick(): void {
    this.collapseMenuClick$.next();
  }
}
