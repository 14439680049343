import { BadgeStyleService } from '@aca-new/app/shared/pipes/table-badge/shared/services/badge-style.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EQimaBadgeStyle } from '@qima/ngx-qima';

@Pipe({
  name: 'tableBadgeStyle',
  pure: true,
})
export class TableBadgeStylePipe implements PipeTransform {
  public constructor(private readonly _badgeStyleService: BadgeStyleService) {}

  public transform(result: string): EQimaBadgeStyle {
    return this._badgeStyleService.getBadgeConfig(result, 'style') as EQimaBadgeStyle;
  }
}
