export enum EMarketingCategoryKey {
  ADS = 'Ads',
  INDUSTRIES = 'Industries',
  INTERNET_SEARCH = 'InternetSearch',
  RECOMMEND = 'Reccomend',
  SIC_WHERE_HEAR = 'SICWhereHear',
  SOCIAL_MEDIA = 'SocialMedia',
  TRADE_SHOW = 'Tradeshow',
  TRADE_SHOW_COUNTRIES = 'TradeShowCountries',
  TRADE_SHOWS = 'Tradeshows',
  WHERE_HEAR = 'WhereHear',
}
