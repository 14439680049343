import { serviceWorkerFactory } from '@aca-new/app/shared/factories/service-worker.factory';
import { SwCheckForUpdateService } from '@aca-new/app/shared/services/service-worker-services/sw-check-for-update/sw-check-for-update.service';
import { SwReloadForUpdateService } from '@aca-new/app/shared/services/service-worker-services/sw-reload-for-update/sw-reload-for-update.service';
import { APP_BOOTSTRAP_LISTENER, FactoryProvider } from '@angular/core';

export const SERVICE_WORKER_PROVIDER: FactoryProvider = {
  deps: [SwCheckForUpdateService, SwReloadForUpdateService],
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: serviceWorkerFactory,
  multi: true,
};
