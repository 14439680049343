import { EBusinessSolution } from '@aca-new/app/shared/models/enums/business-solution.enum';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';

export const DEFAULT_BUSINESS_SOLUTIONS: ISelect[] = [
  {
    label: 'APP_ASIDE.ASIDE_MENU.PRODUCT_INSPECTIONS',
    value: EBusinessSolution.INSPECTIONS,
  },
  {
    label: 'APP_ASIDE.ASIDE_MENU.TESTING',
    value: EBusinessSolution.LAB_TESTING,
  },
  {
    label: 'APP_ASIDE.ASIDE_MENU.CERTIFICATIONS_AND_VOC',
    value: EBusinessSolution.CERTIFICATIONS,
  },
  {
    label: 'APP_ASIDE.ASIDE_MENU.SUPPLIER_AUDITS',
    value: EBusinessSolution.AUDITS,
  },
];
