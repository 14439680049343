import { IPaymentData } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-data.interface';
import {
  IPaymentMethod,
  IPaymentMethodBody,
} from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-method.interface';
import { IPaymentSubmitData } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/shared/models/interfaces/payment-submit-data.interface';
import { IPaymentPay } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/payment.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { PAYMENT_HEADERS_MAP } from '@aca-new/app/shared/constants/payment-headers.constants';
import { EPaymentType } from '@aca-new/app/shared/models/enums/payment-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { HttpResponseBodyNullableType } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentService {
  public readonly paymentSubmitData$: Subject<IPaymentSubmitData> = new Subject<IPaymentSubmitData>();
  private readonly _customPaymentTokenKey = 'QIMA-Custom-Payment-Token';

  public constructor(
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService
  ) {}

  public payment$(data: IPaymentData, paymentType?: EPaymentType): Observable<HttpResponseBodyNullableType<IPaymentPay>> {
    return this._httpService.httpClient
      .post<HttpResponseBodyNullableType<IPaymentPay>>(`${SERVER_URL}/payment/adyen/pay/`, data, {
        observe: 'body',
        headers: this._headerHandler(paymentType),
      })
      .pipe(map((response: HttpResponseBodyNullableType<IPaymentPay>): HttpResponseBodyNullableType<IPaymentPay> => response));
  }

  public getAdyenPaymentMethods$(data: IPaymentMethodBody, paymentType?: EPaymentType): Observable<HttpResponseBodyNullableType<IPaymentMethod>> {
    return this._httpService.httpClient
      .post<HttpResponseBodyNullableType<IPaymentMethod>>(`${SERVER_URL}/payment/adyen/methods/`, data, {
        observe: 'body',
        headers: paymentType ? PAYMENT_HEADERS_MAP[paymentType] : undefined,
      })
      .pipe(map((response: HttpResponseBodyNullableType<IPaymentMethod>): HttpResponseBodyNullableType<IPaymentMethod> => response));
  }

  private _headerHandler(paymentType?: EPaymentType): HttpHeaders | undefined {
    let tempHeaders: HttpHeaders | undefined = paymentType ? PAYMENT_HEADERS_MAP[paymentType] : undefined;
    const token = this._storageService.getItem(EStorageKeys.CUSTOM_PAYMENT_TOKEN);

    if (token) {
      if (tempHeaders) {
        tempHeaders = tempHeaders.append(this._customPaymentTokenKey, token);
      } else {
        tempHeaders = new HttpHeaders().set(this._customPaymentTokenKey, token);
      }
    }

    return tempHeaders;
  }
}
