import { BOOK_FROM_PREVIOUS_ORDER_HEADERS } from '@aca-new/app/pages/bookings/pages/previous-order-item/components/book-from-previous-order/shared/constants/book-from-previous-order.constants';
import { IBookFormPreviousOrder } from '@aca-new/app/pages/bookings/pages/previous-order-item/components/book-from-previous-order/shared/models/interfaces/book-form-previous-order.interface';
import { AbstractSearchTableComponent } from '@aca-new/app/shared/components/abstract-search-table/abstract-search-table.component';
import { DataTableComponent } from '@aca-new/app/shared/components/data-table/data-table.component';
import { ESortOrder } from '@aca-new/app/shared/components/data-table/shared/models/enums/sort-order.enum';
import { IDataTableQueryParams } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-query-params.interface';
import { IDataTableTransformCell } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-transform-cell.interface';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { ITableSort } from '@aca-new/app/shared/components/table/shared/interfaces/table-sort.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { SOLUTION_TYPE } from '@aca-new/app/shared/constants/solution-type.constants';
import { TABLE_PAGINATION } from '@aca-new/app/shared/constants/table-pagination.constants';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { IUrl } from '@aca-new/app/shared/models/interfaces/url.interface';
import { ServiceTypeIceCubeStylePipe } from '@aca-new/app/shared/pipes/service-type-ice-cube-style/service-type-ice-cube-style.pipe';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaInputService } from '@aca-new/app/shared/services/exported-services/qima-input/qima-input.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-book-from-previous-order',
  templateUrl: './book-from-previous-order.component.html',
  styleUrls: ['./book-from-previous-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BookFromPreviousOrderComponent extends AbstractSearchTableComponent implements OnInit {
  @ViewChild(DataTableComponent) public dataTable: DataTableComponent | undefined;
  public tableHeaders: ITableHeader[] = [];
  public dataTableSelectType: string = '';
  public selectedRow: QimaOptionalType<ITableBodyRow> = undefined;
  public serviceType: EServiceType = EServiceType.PSI;
  public isStep2Visible: boolean = false;

  public readonly pageSizes: number[] = [TABLE_PAGINATION.pageSize5, TABLE_PAGINATION.pageSize10, TABLE_PAGINATION.pageSize20];

  public readonly dataTableUrls: Record<string, IUrl> = {
    [SOLUTION_TYPE.inspections]: {
      url: `${SERVER_URL}/user/${this.userId}/psi-orders?`,
      params: 'businessType=AI&page-size=5&page=1&sortType=desc&sortField=bookingDate',
    },
  };

  public transformIceCube = {
    [ETableDataType.ICE_CUBE]: (data: IDataTableTransformCell): void => {
      data.cellData.iceCubeStyle = this._serviceTypeIceCubeStylePipe.transform(data.cellData.label || '');
    },
  };

  public queryParams: IDataTableQueryParams = {
    keyword: '',
    pageSize: this.pageSizes[0],
    page: 1,
    sortType: ESortOrder.DESC,
    sortField: 'bookingDate',
  };

  public serviceTypes: ISelect[] = [
    {
      value: 'PSI',
      label: 'APP_ASIDE.ASIDE_MENU.PRE_SHIPMENT_INSPECTION',
    },
    {
      value: 'CLC',
      label: 'APP_ASIDE.ASIDE_MENU.CONTAINER_LOADING_CHECK',
    },
    {
      value: 'DUPRO',
      label: 'APP_ASIDE.ASIDE_MENU.DURING_PRODUCTION_INSPECTION',
    },
  ];

  public constructor(
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<QimaOptionalType<IBookFormPreviousOrder>>,
    private readonly _serviceTypeIceCubeStylePipe: ServiceTypeIceCubeStylePipe,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaInput: QimaInputService,
    public readonly qimaIcon: QimaIconService,
    storageService: StorageService
  ) {
    super(storageService);
  }

  public ngOnInit(): void {
    this._initData();
  }

  public resetTable(): void {
    this.selectedRow = undefined;
    this.dataTable?.resetTable();
    this._changeDetectorRef.markForCheck();
  }

  public onBusinessSolutionChange(type: Readonly<string>): void {
    this.dataTableSelectType = type;
    this.dataTableUrl = `${this.dataTableUrls[type].url}${this.dataTableUrls[type].params}`;
    this.tableHeaders = BOOK_FROM_PREVIOUS_ORDER_HEADERS[type];
  }

  public setDataTableUrl(): void {
    const currentUrls = { ...this.dataTableUrls[this.dataTableSelectType] };
    const searchParams = new URLSearchParams(currentUrls.params);

    searchParams.set('keyword', this.queryParams.keyword.trim());
    searchParams.set('page', this.queryParams.page.toString());
    searchParams.set('page-size', this.queryParams.pageSize.toString());
    searchParams.set('sortType', this.queryParams.sortType ?? '');
    searchParams.set('sortField', this.queryParams.sortField ?? '');
    this.dataTableUrl = currentUrls.url + searchParams.toString();
    this.resetTable();
  }

  public onSortClick(sortEvent: ITableSort): void {
    this.queryParams.sortType = sortEvent.isSortAsc ? ESortOrder.ASC : ESortOrder.DESC;
    this.queryParams.sortField = sortEvent.sortKey;
    this.setDataTableUrl();
  }

  public onTableRowClick(data: ITableBodyRow): void {
    this.selectedRow = data;
    this.isStep2Visible = true;
  }

  public close(): void {
    this._dialogRef.close();
  }

  public onSelectClick(): void {
    if (!this.selectedRow?.originData) {
      return;
    }

    this._storageService.setItem(EStorageKeys.IS_BOOKING_FROM_PREVIOUS, 'true');

    this._dialogRef.close({
      orderId: (this.selectedRow.originData as IBookFormPreviousOrder).orderId,
      serviceType: this.serviceType.toLocaleLowerCase(),
    });
  }

  private _initData(): void {
    this.dataTableSelectType = SOLUTION_TYPE.inspections;
    this.tableHeaders = BOOK_FROM_PREVIOUS_ORDER_HEADERS.inspections;
    this.dataTableUrl = `${this.dataTableUrls[SOLUTION_TYPE.inspections].url}${this.dataTableUrls[SOLUTION_TYPE.inspections].params}`;
  }
}
