import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { ICity } from '@aca-new/app/shared/models/interfaces/city.interface';
import { ICountry } from '@aca-new/app/shared/models/interfaces/country.interface';
import { IGeoPlanet } from '@aca-new/app/shared/models/interfaces/geo-planet.interface';
import { HttpResponseBodyNullableType, IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { IOffice } from '@aca-new/app/shared/models/interfaces/office.interface';
import { IParameter } from '@aca-new/app/shared/models/interfaces/parameter.interface';
import { IProductCategory, IProductFamily, IProductTree, IProductType, IProductTypeWithChecklist } from '@aca-new/app/shared/models/interfaces/product-tree.interface';
import { ISampleLevel } from '@aca-new/app/shared/models/interfaces/sample-level.interface';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { find, findIndex } from 'lodash/index';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppParamsService {
  public constructor(
    private readonly _storageService: StorageService,
    private readonly _httpService: HttpService
  ) {}

  public getSampleLevel$(type: string, isStandard: boolean | undefined = undefined): Observable<ISelect[]> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<ISampleLevel>>(`${SERVER_URL}/parameter/v2/sample-levels?refresh=true&type=${type}`, {
        observe: 'body',
      })
      .pipe(
        map((response: IHttpResponseBody<ISampleLevel>): ISelect[] => {
          let levels: ISelect[] = [];

          if (response?.content?.general) {
            levels = response.content.general;
          }

          if (response?.content?.fabric) {
            find(response.content.fabric, (d): void => {
              if (d.value == '0') {
                d.value = 'Standard';
                d.label = 'Standard';
              }
            });

            levels = response?.content?.fabric;
          }

          if (response?.content?.eyewear) {
            levels = response.content.eyewear;

            if (isStandard !== undefined) {
              const deleteCount: number[] = isStandard ? [0, 1, 2, 3, 4, 5, 6] : [7, 8];

              for (let i = deleteCount.length - 1; i >= 0; i--) {
                levels.splice(deleteCount[i], 1);
              }
            }
          }

          return levels;
        })
      );
  }

  public getProductTree$(): Observable<HttpResponseBodyNullableType<IProductTree>> {
    const productTree: string | null = this._storageService.getItem(EStorageKeys.PRODUCT_TREE);

    if (productTree) {
      return of(JSON.parse(productTree));
    }

    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<IProductTree>>(`${SERVER_URL}/parameter/v2/product-tree`, {
        observe: 'body',
      })
      .pipe(
        map((response: Readonly<HttpResponseBodyNullableType<IProductTree>>): HttpResponseBodyNullableType<IProductTree> => {
          this._storageService.setItem(EStorageKeys.PRODUCT_TREE, JSON.stringify(response));

          return response;
        })
      );
  }

  public initProductTree(): void {
    this.getProductTree$().pipe(untilDestroyed(this)).subscribe();
  }

  public getProductCategories$(): Observable<QimaNullableType<IProductCategory[]>> {
    const url = `${SERVER_URL}/parameter/v2/product-categories`;
    const productCategories: string | null = this._storageService.getItem(EStorageKeys.PRODUCT_CATEGORIES);

    if (productCategories) {
      return of(JSON.parse(productCategories));
    }

    return this._httpService.httpClient.get<IHttpResponseBody<QimaNullableType<IProductCategory[]>>>(url, { observe: 'response' }).pipe(
      map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IProductCategory[]>>>>): QimaNullableType<IProductCategory[]> => {
        if (response?.body?.content) {
          this._storageService.setItem(EStorageKeys.PRODUCT_CATEGORIES, JSON.stringify(response.body.content));

          return response.body.content;
        }

        return null;
      })
    );
  }

  public getProductFamilies$(): Observable<QimaNullableType<IProductFamily[]>> {
    const url = `${SERVER_URL}/parameter/v2/product-families`;

    return this._httpService.httpClient.get<IHttpResponseBody<QimaNullableType<IProductFamily[]>>>(url, { observe: 'response' }).pipe(
      map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IProductFamily[]>>>>): QimaNullableType<IProductFamily[]> => {
        const families = response?.body?.content || null;

        if (families) {
          families.splice(
            findIndex(families, (i): boolean => {
              // remove old eye wear product type
              return i.id === 'bigCat3_s2' && i.categoryId === 'bigCat3';
            }),
            1
          );
        }

        return families;
      })
    );
  }

  public getProductTypes$(): Observable<HttpResponseBodyNullableType<IHttpResponseBody<IProductType[]>>> {
    const url = `${SERVER_URL}/parameter/v2/product-types`;

    return this._httpService.httpClient
      .get(url, { observe: 'body' })
      .pipe(map((types: HttpResponseBodyNullableType<IHttpResponseBody<IProductType[]>>): HttpResponseBodyNullableType<IHttpResponseBody<IProductType[]>> => types));
  }

  public getProductTypesWithChecklist$(): Observable<QimaNullableType<IProductTypeWithChecklist>> {
    const url = `${SERVER_URL}/parameter/v2/product-types-with-checklist`;
    const productTypesWithChecklists: string | null = this._storageService.getItem(EStorageKeys.PRODUCT_TYPE_WITH_CHECKLIST);

    if (productTypesWithChecklists) {
      return of(JSON.parse(productTypesWithChecklists));
    }

    return this._httpService.httpClient.get<IHttpResponseBody<QimaNullableType<IProductTypeWithChecklist>>>(url, { observe: 'response' }).pipe(
      map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IProductTypeWithChecklist>>>>): QimaNullableType<IProductTypeWithChecklist> => {
        if (response?.body?.content) {
          this._storageService.setItem(EStorageKeys.PRODUCT_TYPE_WITH_CHECKLIST, JSON.stringify(response.body.content));

          return response.body.content;
        }

        return null;
      })
    );
  }

  public getCountries$(search: string): Observable<HttpResponseBodyNullableType<ICountry[]>> {
    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<ICountry[]>>(`${SERVER_URL}/parameter/v2/searchCountries?name=${search}&rows=50`, {
        observe: 'body',
      })
      .pipe(map((response: Readonly<HttpResponseBodyNullableType<ICountry[]>>): HttpResponseBodyNullableType<ICountry[]> => response));
  }

  public getParameterByClassification$(classification: string): Observable<QimaNullableType<IParameter[]>> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<IParameter[]>>>(`${SERVER_URL}/parameter/v2/constant-mapping/${classification}`, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IParameter[]>>>>): QimaNullableType<IParameter[]> => response?.body?.content || null));
  }

  public getAllCountries$(): Observable<QimaNullableType<ICountry[]>> {
    const url = `${SERVER_URL}/parameter/v2/countries?refresh=false`;

    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<ICountry[]>>>(url, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<ICountry[]>>>>): QimaNullableType<ICountry[]> => response?.body?.content || null));
  }

  public getCites$(country: string, search: string): Observable<HttpResponseBodyNullableType<ICity[]>> {
    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<ICity[]>>(`${SERVER_URL}/parameter/v2/country/${country}/searchCities?name=${search}&rows=50`, {
        observe: 'body',
      })
      .pipe(map((response: Readonly<HttpResponseBodyNullableType<ICity[]>>): HttpResponseBodyNullableType<ICity[]> => response));
  }

  public getOffices$(): Observable<IOffice[]> {
    return this._httpService.httpClient
      .get<IOffice[]>(`${SERVER_URL}/parameter/ai-offices`, {
        observe: 'body',
      })
      .pipe(map((response: IOffice[]): IOffice[] => response));
  }

  public getCountryOrRegions$(): Observable<QimaNullableType<ISelect[]>> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<ISelect[]>>>(`${SERVER_URL}/parameter/v2/countries-with-calling-country-code?refresh=false`, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<ISelect[]>>>>): QimaNullableType<ISelect[]> => response?.body?.content || null));
  }

  public getGeoPlanet$(id: string): Observable<QimaNullableType<IGeoPlanet>> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<IGeoPlanet>>>(`${SERVER_URL}/parameter/v2/geo-planet/${id}`, { observe: 'body' })
      .pipe(map((response): QimaNullableType<IGeoPlanet> => response?.content ?? null));
  }
}
