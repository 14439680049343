import { SECRET_KEY } from '@aca-new/app/shared/constants/secret-key.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { SHOULD_SESSION_STORAGE_ENCRYPT } from '@aca-new/app/shared/tokens/should-session-storage-encrypt.token';
import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // TODO: StorageService should be a tool-like service and should not be related to a specific business
  // TODO: The insensitive data should be provided by the project, then injected into this service
  private readonly _insensitiveData = [EStorageKeys.PRODUCT_CATEGORIES, EStorageKeys.PRODUCT_TREE];
  public constructor(@Inject(SHOULD_SESSION_STORAGE_ENCRYPT) private readonly _shouldSessionStorageEncrypt: boolean) {}

  public getItem(key: EStorageKeys): string {
    const value = sessionStorage.getItem(key) ?? '';

    if (this._shouldDataEncrypt(key)) {
      try {
        return CryptoJS.AES.decrypt(value, SECRET_KEY, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error(error);

        return value;
      }
    }

    return value;
  }

  public setItem(key: EStorageKeys, value: string): void {
    if (this._shouldDataEncrypt(key)) {
      const encryptedValue: string = CryptoJS.AES.encrypt(value, SECRET_KEY, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();

      sessionStorage.setItem(key, encryptedValue);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  public removeItem(key: EStorageKeys): void {
    sessionStorage.removeItem(key);
  }

  private _shouldDataEncrypt(key: EStorageKeys): boolean {
    return this._shouldSessionStorageEncrypt && !this._insensitiveData.includes(key);
  }
}
