import { googleAnalyticsInitFactory } from '@aca-new/app/shared/factories/google-analytics/google-analytics-init.factory';
import { GoogleAnalyticsService } from '@aca-new/app/shared/services/third-party-services/google-analytics/google-analytics.service';
import { APP_BOOTSTRAP_LISTENER, FactoryProvider } from '@angular/core';

export const GOOGLE_ANALYTICS_INIT_PROVIDER: FactoryProvider = {
  deps: [GoogleAnalyticsService],
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: googleAnalyticsInitFactory,
};
