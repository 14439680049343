<div
  *ngIf="isVisible"
  [class.app-aside-menu--collapsed]="isAsideCollapsed"
  [class.app-aside-menu--expanded]="isExpanded"
  class="app-aside-menu"
>
  <div class="app-aside-menu__content">
    <div class="app-aside-menu__content__header">
      <app-section-title
        [sectionTitleIcon]="serviceIcon"
        [sectionTitleTitle]="title | translate"
      ></app-section-title>
      <qima-button-icon
        (qimaButtonIconClick)="onCloseClick()"
        [qimaButtonIconName]="closeIconName"
        [qimaButtonIconSize]="closeButtonSize"
        [qimaButtonIconStyle]="closeButtonIconStyle"
      ></qima-button-icon>
    </div>
    <div class="app-aside-menu__content__service">
      <div class="app-aside-menu__content__service__left">
        <app-aside-menu-item
          (asideMenuItemClick)="onAsideMenuBusinessSolutionClick(index)"
          *ngFor="let item of businessSolutions; let index = index"
          appNgForTrackByUuid
          [asideMenuItemLabel]="item.label | translate"
          [class.app-aside-menu__content__service__left__aside-menu]="!isAcaFrameAvailable"
          [isAsideMenuItemChecked]="index === asideMenuBusinessSolutionIndex"
          [isAsideMenuItemIconShow]="true"
        ></app-aside-menu-item>

        <qima-divider
          [qimaDividerOrientation]="dividerOrientation"
          class="qima-mt-xs qima-mb-xs"
        ></qima-divider>

        <app-previous-order-item></app-previous-order-item>
      </div>
      <qima-divider
        *ngIf="isExpanded"
        [qimaDividerOrientation]="qimaDivider.orientation.VERTICAL"
        [qimaDividerStyle]="dividerStyle"
      ></qima-divider>
      <div
        *ngIf="isExpanded"
        class="app-aside-menu__content__service__right"
      >
        <app-aside-menu-item
          (asideMenuItemClick)="onAsideMenuServiceTypeClick(index, item)"
          *ngFor="let item of serviceTypesActive; let index = index"
          appNgForTrackByUuid
          [asideMenuItemAbbreviation]="item.abbreviation || ''"
          [asideMenuItemLabel]="item.label | translate"
          [class.app-aside-menu__content__service__right__aside-menu--disabled]="!item.path"
          [isAsideMenuItemChecked]="index === asideMenuServiceTypeIndex"
        ></app-aside-menu-item>
      </div>
    </div>
  </div>
</div>
