import { IFile } from '@aca-new/app/pages/book/pages/confirmation/shared/components/confirmation-order-summary/shared/interfaces/file.interface';
import { NpsSurveyComponent } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/nps-survey.component';
import { ENpsSurveySource } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/enums/nps-survey-source.enum';
import { NpsSurveyService } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/services/nps-survey.service';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { HttpResponseBodyNullableType } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { AppStringService } from '@aca-new/app/shared/services/exported-services/app-string/app-string.service';
import { AppFileDownloadService } from '@aca-new/app/shared/services/file-services/app-file-download.service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _appLocationService: AppLocationService,
    private readonly _appStringService: AppStringService,
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService,
    private readonly _overlayService: AppOverlayService,
    private readonly _appFileDownloadService: AppFileDownloadService,
    private readonly _npsSurveyService: NpsSurveyService,
    private readonly _dialog: DialogService
  ) {}

  public payByPaypal(orderId: string, paymentPopup: Window | null): void {
    orderId = this._appStringService.commaToAndFunction(orderId);
    const url = `${this._appLocationService.getAcaUrl()}/paypal-payment/${orderId}`;

    if (!paymentPopup) {
      this._openPopUpWindow(url, null, null);
    } else {
      paymentPopup.location = url;
    }
  }

  public logVisitHistory$(): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.post<void>(`${SERVER_URL}/parameter/v2/history?userId=${userId}`, {
      sourceId: 'PAYMENT_EMAIL_TRACKER',
      historyType: 'PAYMENT_EMAIL_TRACKER',
      historyWhen: new Date().toISOString(),
      historyWho: JSON.parse(this._storageService.getItem(EStorageKeys.USER_DATA)).company.name,
      historyAction: 'Jump to unpaid page via reminder email',
    });
  }

  public getProformaInvoiceInfo$(invoiceNumber: string): Observable<HttpResponseBodyNullableType<IFile>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<IFile>>(`${SERVER_URL}/user/${userId}/proforma-invoice-info?invoiceNo=${invoiceNumber}`, {
        observe: 'body',
      })
      .pipe(map((response: HttpResponseBodyNullableType<IFile>): HttpResponseBodyNullableType<IFile> => response));
  }

  public getProformaInvoiceInfo(invoiceNumber: string, rowData?: Record<string, unknown>): void {
    if (!invoiceNumber) {
      return;
    }

    this._overlayService.updateIsLoading(true);
    this.getProformaInvoiceInfo$(invoiceNumber)
      .pipe(untilDestroyed(this))
      .subscribe((res): void => {
        if (res?.content) {
          this._appFileDownloadService.downloadDirectly(res.content.fileName, res.content.id);
        }

        this._overlayService.updateIsLoading(false);
      });
    const serviceType = rowData?.['serviceTypeText'] || 'Invoice';
    const orderNo = rowData?.['rfqNumber'];

    // TODO: search the method and move this to  npmSurveyService
    this._npsSurveyService
      .getIsSurveyVisible$()
      .pipe(untilDestroyed(this))
      .subscribe((canOpen: boolean): void => {
        if (!canOpen) {
          return;
        }

        this._dialog.open(NpsSurveyComponent, {
          data: { orderNo, serviceType, npsFrom: ENpsSurveySource.DOWNLOAD_INVOICE },
          stopCloseWhenClickBackdrop: true,
        });
      });
  }

  private _openPopUpWindow(url: string | null, w: number | null, h: number | null): Window | null {
    const window = this._window;

    w = w || 800;
    h = h || 700;
    url = url || '';
    // Fixes dual-screen position Most browsers Firefox
    const dualScreenLeft = window.screenLeft;
    const dualScreenTop = window.screenTop;
    let { width } = screen;

    if (window.innerWidth) {
      width = window.innerWidth;
    } else if (document.documentElement.clientWidth) {
      width = document.documentElement.clientWidth;
    }

    let { height } = screen;

    if (window.innerHeight) {
      height = window.innerHeight;
    } else if (document.documentElement.clientHeight) {
      height = document.documentElement.clientHeight;
    }

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    return window.open(url, 'AIPayment', `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`);
  }
}
