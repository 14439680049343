import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaLoggerService } from '@qima/ngx-qima';
import { interval, concat, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

const ONE_HOUR = 60 * 60 * 1000;

/**
 * @description
 * Allow the app to stabilize first, before starting polling for updates with `interval()`.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SwCheckForUpdateService {
  public constructor(
    private readonly _applicationRef: ApplicationRef,
    private readonly _injector: Injector,
    private readonly _swUpdate: SwUpdate,
    private readonly _qimaLoggerService: QimaLoggerService
  ) {
    this._applicationRef = this._injector.get(ApplicationRef);
  }

  public init(): void {
    this._onSwCheckForUpdate$().pipe(untilDestroyed(this)).subscribe();
  }

  private _onSwCheckForUpdate$(): Observable<number | boolean> {
    const appIsStable$ = this._applicationRef.isStable.pipe(first((isStable: boolean): boolean => isStable));
    const everyHours$ = interval(ONE_HOUR);
    const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHours$);

    return everyHoursOnceAppIsStable$.pipe(
      tap({
        next: (): void => {
          if (this._swUpdate.isEnabled) {
            void this._swUpdate.checkForUpdate();
          }
        },
        error: (err: Error): void => {
          this._qimaLoggerService.error(err);
        },
      })
    );
  }
}
