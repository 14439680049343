import { QimaColorService } from '@aca-new/app/shared/services/exported-services/qima-color/qima-color.service';
import { QimaDotService } from '@aca-new/app/shared/services/exported-services/qima-dot/qima-dot.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaIconName, QimaOptionalType, QimaResponsiveService } from '@qima/ngx-qima';

@UntilDestroy()
@Component({
  selector: 'app-aside-item',
  templateUrl: './aside-item.component.html',
  styleUrls: ['./aside-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AsideItemComponent implements OnInit {
  /**
   * @description
   * The name of the icon to display
   * @type {EQimaIconName}
   * @default undefined
   */
  @Input('iconName')
  public iconName: QimaOptionalType<EQimaIconName> = undefined;

  /**
   * @description
   * The label of the aside common item
   * @type {string}
   * @default ''
   */
  @Input('label')
  public label: string = '';

  /**
   * @description
   * Check or not
   * @type {boolean}
   * @default false
   */
  @Input('isAsideItemChecked')
  public isChecked: boolean = false;

  /**
   * @description
   * Collapsed or not
   * @type {boolean}
   * @default false
   */
  @Input('isAsideItemCollapsed')
  public isCollapsed: boolean = false;

  @Output('asideItemClick')
  public readonly asideItemClick: EventEmitter<MouseEvent | Event> = new EventEmitter<MouseEvent | Event>();

  public isMobile: boolean = false;
  public constructor(
    public readonly qimaDot: QimaDotService,
    public readonly qimaColor: QimaColorService,
    private readonly _qimaResponsiveService: QimaResponsiveService,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._watch();
  }

  public onClick(event: Readonly<MouseEvent>): void {
    this._emitClick(event);
  }

  public onEnter(event: Readonly<Event>): void {
    this._emitClick(event);
  }

  private _emitClick(event: Readonly<MouseEvent | Event>): void {
    this.asideItemClick.emit(event);
  }

  private _watch(): void {
    this._qimaResponsiveService
      .isMobile$()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile: boolean): void => {
        this.isMobile = isMobile;
        this._changeDetectorRef.markForCheck();
      });
  }
}
