<div class="super-master-selector">
  <qima-input-select
    [ngModel]="currentCompany"
    [qimaInputSelectStyle]="selectStyle"
    [qimaInputSelectHasClearButton]="false"
  >
    <qima-menu [qimaMenuMaxHeight]="500">
      <qima-menu-item-text
        *ngFor="let item of masterCompanies"
        appNgForTrackByUuid
        [qimaMenuItemTextLabel]="item.label | translate | startCase"
        [qimaMenuItemTextValue]="item.value"
        (qimaMenuItemTextClick)="onCompanyClick(item.value)"
      ></qima-menu-item-text>
    </qima-menu>
  </qima-input-select>
</div>
