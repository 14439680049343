<div class="dialog-delete">
  <app-dialog-common [dialogCommonTitle]="dialogData.title">
    <div
      class="content-delete"
      [innerHTML]="dialogData.content | translate"
    ></div>
    <div class="foot-delete">
      <qima-button
        *ngIf="!dialogData.hideLeftButton"
        [qimaButtonLabel]="'COMMON.CANCEL' | translate"
        [qimaButtonSize]="qimaButton.buttonSize.LARGE"
        [qimaButtonStyle]="qimaButton.buttonStyle.SECONDARY"
        (qimaButtonClick)="close()"
      ></qima-button>
      <div class="dialog-delete__width">
        <qima-button
          class="qima-ml-xs"
          [qimaButtonWidth]="qimaButton.buttonWidth.FULL"
          [qimaButtonLabel]="dialogData.deleteTitle || 'COMMON.DELETE' | translate"
          [qimaButtonSize]="qimaButton.buttonSize.LARGE"
          [qimaButtonStyle]="dialogData.buttonStyle || qimaButton.buttonStyle.PRIMARY_DESTRUCTIVE"
          (qimaButtonClick)="delete()"
        ></qima-button>
      </div>
    </div>
  </app-dialog-common>
</div>
