<div class="app-header">
  <div class="app-header__logo">
    <qima-button-icon
      qimaTooltip
      [qimaTooltipText]="tooltipMessage | translate"
      [qimaTooltipIsVisible]="true"
      [qimaTooltipHasArrow]="true"
      [qimaTooltipPosition]="tooltipPosition"
      [qimaButtonIconSize]="qimaButton.buttonSize.SMALL"
      [qimaButtonIconName]="qimaIcon.iconName.MENU"
      [qimaButtonIconStyle]="qimaButtonIcon.buttonIconStyle.TERTIARY_UTILITY"
      (qimaButtonIconClick)="onMenuClick()"
    >
    </qima-button-icon>
    <img
      [src]="qimaLogo"
      alt="qima logo"
      class="qima-logo"
      (click)="onQimaLogoClick()"
    />
  </div>
  <div class="app-header__tool-bar">
    <ul>
      <li class="hide-media-sm">
        <div class="app-header__tool-bar__company">
          <qima-avatar
            *ngIf="managerAvatar"
            [qimaAvatarSize]="qimaAvatar.avatarSize.BASE"
            [qimaAvatarImage]="managerAvatar"
          ></qima-avatar>
          <qima-avatar-fake
            *ngIf="!managerAvatar"
            [qimaAvatarFakeSize]="qimaAvatar.avatarSize.BASE"
          ></qima-avatar-fake>
          <div class="app-header__tool-bar__company__manager">
            <span class="manager-label"> {{ 'APP_HEADER.ACCOUNT.YOUR_ACCT_MGR' | translate }}: </span>
            <span class="manager-name">{{ currentUser?.sic }}</span>
          </div>
        </div>
      </li>
      <li class="app-header__tool-bar__divider hide-media-sm">
        <qima-divider [qimaDividerOrientation]="qimaDivider.orientation.VERTICAL"></qima-divider>
      </li>
      <li>
        <div class="app-header__tool-bar__buttons">
          <qima-button-icon
            *ngIf="false"
            class="qima-mr-s"
            (qimaButtonIconClick)="openUserGuide()"
            [qimaButtonIconSize]="qimaButton.buttonSize.SMALL"
            [qimaButtonIconName]="qimaIcon.iconName.INFORMATION"
            [qimaButtonIconStyle]="qimaButtonIcon.buttonIconStyle.TERTIARY_UTILITY"
          >
          </qima-button-icon>
          <qima-button-icon
            cdkOverlayOrigin
            #menuTranslateOrigin="cdkOverlayOrigin"
            qimaTooltip
            [qimaTooltipPosition]="qimaTooltip.position.BOTTOM_RIGHT"
            [qimaTooltipText]="'APP_HEADER.ACCOUNT.LANGUAGE_SELECTOR_TIP' | translate"
            [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
            [qimaTooltipHasArrow]="true"
            [qimaTooltipStyle]="qimaTooltip.style.DARK"
            (qimaButtonIconClick)="openTranslate($event)"
            [qimaButtonIconSize]="qimaButton.buttonSize.SMALL"
            [qimaButtonIconName]="qimaIcon.iconName.LANGUAGE_WORLD"
            [qimaButtonIconStyle]="qimaButtonIcon.buttonIconStyle.TERTIARY_UTILITY"
          >
          </qima-button-icon>
        </div>
      </li>
      <li class="app-header__tool-bar__divider">
        <qima-divider [qimaDividerOrientation]="qimaDivider.orientation.VERTICAL"></qima-divider>
      </li>
      <li>
        <div
          #menuProfileOrigin="cdkOverlayOrigin"
          cdkOverlayOrigin
          class="app-header__tool-bar__account"
          qimaTooltip
          [qimaTooltipPosition]="qimaTooltip.position.BOTTOM_RIGHT"
          [qimaTooltipText]="'APP_HEADER.ACCOUNT.PROFILE_TIP' | translate"
          [qimaTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
          [qimaTooltipHasArrow]="true"
          [qimaTooltipStyle]="qimaTooltip.style.DARK"
          (click)="openProfile($event)"
          [class.app-header__tool-bar__account--focus]="isProfileMenuVisible"
        >
          <qima-icon [qimaIconName]="qimaIcon.iconName.USER"></qima-icon>
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOffsetY]="8"
  [cdkConnectedOverlayWidth]="280"
  [cdkConnectedOverlayOrigin]="menuProfileOrigin"
  [cdkConnectedOverlayOpen]="isProfileMenuVisible"
  [cdkConnectedOverlayPanelClass]="'account-menu'"
  [cdkConnectedOverlayBackdropClass]="'header-backdrop'"
  [cdkConnectedOverlayHasBackdrop]="true"
  (overlayOutsideClick)="onProfileOverlayOutsideClick()"
  (detach)="onProfileOverlayDetach()"
>
  <app-account-menu></app-account-menu>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOffsetY]="8"
  [cdkConnectedOverlayWidth]="180"
  [cdkConnectedOverlayOrigin]="menuTranslateOrigin"
  [cdkConnectedOverlayOpen]="isTranslateMenuVisible"
  [cdkConnectedOverlayBackdropClass]="'header-backdrop'"
  [cdkConnectedOverlayHasBackdrop]="true"
  (overlayOutsideClick)="onTranslateOverlayOutsideClick()"
  (detach)="onTranslateOverlayDetach()"
>
  <app-translate-menu></app-translate-menu>
</ng-template>
