import { OverlayRef } from '@angular/cdk/overlay';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Subject, Observable } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef<T> {
  private readonly _afterClosedSubject$: Subject<QimaOptionalType<T>> = new Subject<QimaOptionalType<T>>();

  public constructor(private readonly _overlayRef: OverlayRef) {}

  public close(result?: T): void {
    this._overlayRef.dispose();
    this._afterClosedSubject$.next(result);
    this._afterClosedSubject$.complete();
    document.body.style.overflow = '';
  }

  public afterClosed$(): Observable<QimaOptionalType<T>> {
    return this._afterClosedSubject$.asObservable();
  }
}
