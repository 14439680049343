import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-dialog-common',
  templateUrl: './dialog-common.component.html',
  styleUrls: ['./dialog-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DialogCommonComponent<T> {
  /**
   * @description
   * The title of the dialog
   * @type {QimaOptionalType<string>}
   * @default undefined
   */
  @Input('dialogCommonTitle')
  public title: QimaOptionalType<string> = undefined;

  public constructor(@Inject(DialogRef) private readonly _dialogRef: DialogRef<T>) {}

  public close(): void {
    this._dialogRef.close();
  }
}
