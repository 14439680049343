<div class="dialog-header">
  <ng-container *ngIf="title">
    <div class="dialog-header__title">{{ title | translate }}</div>
  </ng-container>
  <ng-content></ng-content>
</div>
<qima-button-icon
  class="dialog-header__close"
  (qimaButtonIconClick)="onClick()"
  [qimaButtonIconStyle]="qimaButton.buttonIconStyle.TERTIARY"
  [qimaButtonIconName]="qimaIcon.iconName.CLOSE"
  [qimaButtonIconSize]="qimaButton.buttonSize.SMALL"
></qima-button-icon>
