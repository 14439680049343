import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ENVIRONMENT } from '@aca-new/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';

declare let ENV_TYPE: 'dev' | 'pp' | 'prod';

@Injectable({
  providedIn: 'root',
})
export class AppAuthenticationService {
  public readonly currentUserSubject$: Subject<IUser> = new Subject<IUser>();
  private readonly _acaUrl = this._appLocationService.getAcaUrl();
  private _isLogout = false;

  public constructor(
    private readonly _appLocationService: AppLocationService,
    private readonly _cookieStorage: CookieService,
    private readonly _storageService: StorageService,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public updateUserData(data: Readonly<IUser>): void {
    this.currentUserSubject$.next(data);
  }

  public clearAuthenticationData(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;

    windowRef.Appcues?.reset();
    this._storageService.removeItem(EStorageKeys.USER_DATA);
    this._storageService.removeItem(EStorageKeys.USER_ID);
    this._cookieStorage.remove(EStorageKeys.AUTH_TOKEN);
    this._cookieStorage.remove(EStorageKeys.SESSION_ID);
  }

  public navigateToLogin(): void {
    if (this._isLogout) {
      this.logout();

      return;
    }

    const acaLoginUrl = `/login?redirect=${encodeURIComponent(`${location.origin}/login?token={token}&userId={userId}&sessionId={sessionId}`)}`;

    location.assign(this._appLocationService.getAcaUrl() + acaLoginUrl + encodeURIComponent(`&redirectUrl=${location.pathname + location.search}`));
  }

  public logout(): void {
    if (this._window.location.origin.includes('localhost')) {
      this._window.location.replace(`${this._acaUrl}/login`);
    } else {
      this._window.location.replace(`${ENVIRONMENT[ENV_TYPE].MARKETING_WEBSITE_URL}/logout`);
    }

    this.clearAuthenticationData();
    this._isLogout = true;
  }

  public isAuthenticated(): boolean {
    return this._storageService.getItem(EStorageKeys.USER_DATA) !== null;
  }

  public getCurrentUser(): IUser {
    return JSON.parse(this._storageService.getItem(EStorageKeys.USER_DATA) || '{}');
  }

  public getCompanyId(): string {
    const userData = this.getCurrentUser();

    return userData.company?.id || '';
  }
}
