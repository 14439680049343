import { EmailComponent } from '@aca-new/app/shared/components/email/email.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { QimaInputMultipleEmailModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [EmailComponent],
  exports: [EmailComponent],
  imports: [QimaInputMultipleEmailModule, ReactiveFormsModule],
})
export class EmailModule {}
