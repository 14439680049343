import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EQimaPosition, EQimaSnackbarType, IQimaSnackbarParams, QimaSnackbarService } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class AppSnackbarService {
  public constructor(
    private readonly _qimaSnackbarService: QimaSnackbarService,
    private readonly _translateService: TranslateService
  ) {}

  public showSnackbar(
    message: string,
    type: EQimaSnackbarType = EQimaSnackbarType.ERROR,
    duration: number = 5000,
    position: EQimaPosition = EQimaPosition.TOP,
    buttonClick?: (event: Event) => void,
    buttonLabel?: string
  ): void {
    const settings: Partial<IQimaSnackbarParams> = {
      message: this._translateService.instant(message),
      type,
      position,
      duration,
    };

    if (buttonClick) {
      settings.buttonClick = buttonClick;
      settings.buttonLabel = this._translateService.instant(buttonLabel || 'COMMON.OK');
    }

    this._qimaSnackbarService.open(settings);
  }
}
