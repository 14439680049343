import { PaymentMethodComponent } from '@aca-new/app/pages/payments/pages/payments-content/shared/components/payment-method/payment-method.component';
import { ILabTestingInvoices, ILabTestingOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/lab-testing-invoice.interface';
import { IOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/order-fee.interface';
import { OrderFeeService } from '@aca-new/app/pages/payments/pages/payments-content/shared/services/order-fee/order-fee.service';
import { IPaymentResponse } from '@aca-new/app/pages/payments/shared/models/interfaces/payment-response.interface';
import { PaymentsService } from '@aca-new/app/pages/payments/shared/services/payments.service';
import { PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { ITableBodyRow } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { CONDITIONS_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EServiceType } from '@aca-new/app/shared/models/enums/service-type.enum';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable, tap } from 'rxjs';

// TODO find the response and define
interface IOrderPrice {
  cost: number;
  id: string;
  orderId?: string;
  rfqNumber: string;
}

interface IPaymentDialogData {
  isLT?: boolean;
  orderId?: string;
  orderIds?: string[];
  orders?: ITableBodyRow[];
  serviceType?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  providers: [OrderFeeService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDialogComponent implements OnInit {
  public orderFees: IOrderFee[] = [];
  public totalFees: number = 0;
  public adyenFees: string = '';
  public paypalOrderIds: string[] = [];
  private readonly _orderIdToRefMap: Record<string, PageItemType> = {};
  public constructor(
    @Inject(DIALOG_DATA) private readonly _orderPayDialogData: IPaymentDialogData,
    private readonly _paymentsService: PaymentsService,
    private readonly _dialogRef: DialogRef<void>,
    private readonly _dialog: DialogService,
    private readonly _orderFeeService: OrderFeeService,
    private readonly _overlayService: AppOverlayService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _translateService: TranslateService,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaIcon: QimaIconService,
    public readonly qimaTooltip: QimaTooltipService,
    @Inject(WINDOW) private readonly _window: IMyWindow
  ) {}

  public ngOnInit(): void {
    this._initData();
  }

  public close(): void {
    this._dialogRef.close();
  }

  public onProceedToPaymentClick(): void {
    let { serviceType } = this._orderPayDialogData;
    let orderNo: string | undefined = '';

    if (!serviceType && this._orderPayDialogData.orders?.length) {
      // eslint-disable-next-line prefer-destructuring
      serviceType = (this._orderPayDialogData.orders[0].originData as unknown as IPaymentResponse).serviceType;
    }

    if (this._orderPayDialogData.isLT && this._orderPayDialogData.orders) {
      orderNo = (this._orderPayDialogData.orders[0].originData as unknown as IPaymentResponse).labOrderNo;
      serviceType = EServiceType.LT;
    }

    this._dialog.open(PaymentMethodComponent, {
      data: {
        totalFees: this.totalFees,
        orderFees: this.orderFees,
        serviceType,
        orderNo,
      },
    });

    this.close();
  }

  public onTermsAndConditionsClick(): void {
    this._window.open(CONDITIONS_URL, '_blank');
  }

  public onPaypalClick(): void {
    if (this.paypalOrderIds.length) {
      this._paymentsService.payByPaypal(this.paypalOrderIds.join('&'), null);
    }
  }

  private _initData(): void {
    this._initPaypalOrderIds();
    this._getOrderFees();
  }

  private _initPaypalOrderIds(): void {
    const { orders, orderId, isLT } = this._orderPayDialogData;

    if (orders?.length) {
      this.paypalOrderIds = orders.map((item): string => {
        const orderData = item.originData as IOrderPrice;

        if (isLT) {
          return orderData.id;
        }

        this._orderIdToRefMap[orderData.id] = orderData.rfqNumber;

        return orderData.orderId ?? orderData.id;
      });
    } else if (orderId) {
      this.paypalOrderIds = [orderId];
    }
  }

  private _getOrderFees(): void {
    this._overlayService.updateIsLoading(true);

    this._watchOrderFees$()
      .pipe(untilDestroyed(this))
      .subscribe((): void => {
        this._setTotalFees();
        this._overlayService.updateIsLoading(false);
        this._changeDetectorRef.markForCheck();
      });
  }

  private _setTotalFees(): void {
    this.orderFees.forEach((fee: IOrderFee): void => {
      this.totalFees += parseFloat(fee.amount);

      if (!fee.orderId) {
        this.adyenFees = this._translateService.instant('PAYMENTS.ADYEN_FEES', { adyenFees: fee.amount });
      }

      fee.fees?.forEach((subFee: IOrderFee): void => {
        this.totalFees += parseFloat(subFee.amount);
      });
    });
  }

  private _watchOrderFees$(): Observable<QimaOptionalType<IOrderFee[] | ILabTestingInvoices>> {
    return this._orderPayDialogData.isLT ? this._watchLTOrderFees$() : this._watchCommonOrderFees$();
  }

  private _watchLTOrderFees$(): Observable<QimaOptionalType<ILabTestingInvoices>> {
    return this._orderFeeService.getLTOrderFees$(this.paypalOrderIds).pipe(
      tap({
        next: (invoices: QimaOptionalType<ILabTestingInvoices>): void => {
          if (!invoices?.invoiceList?.length) {
            return;
          }

          this.orderFees = invoices.invoiceList.map((item: ILabTestingOrderFee, index: number): IOrderFee => {
            if (item.invoiceNo && item.id && item.currency) {
              const itemName = `${index + 1}/ Lab Testing Invoice: ${item.invoiceNo}`;

              return {
                orderId: item.id,
                invoiceNo: item.invoiceNo,
                officeId: item.officeId,
                itemName,
                quantity: '1',
                amount: item.amountRemaining.toString(),
                unitPrice: item.amountRemaining.toString(),
                amountUnit: item.currency,
              };
            }

            return {} as IOrderFee;
          });

          this.orderFees.push({
            orderId: null,
            invoiceNo: undefined,
            officeId: undefined,
            itemName: 'ADYEN fees',
            quantity: '1',
            amount: invoices.processingFees.toFixed(2).toString(),
            unitPrice: invoices.processingFees.toFixed(2).toString(),
            amountUnit: invoices.invoiceList[0].currency,
          });
        },
      })
    );
  }

  private _watchCommonOrderFees$(): Observable<QimaOptionalType<IOrderFee[]>> {
    return this._orderFeeService.getOrderFees$(this.paypalOrderIds).pipe(
      tap({
        next: (orderFees: QimaOptionalType<IOrderFee[]>): void => {
          if (!orderFees) {
            return;
          }

          this.orderFees = orderFees.map((item: IOrderFee): IOrderFee => {
            item.adyenRefNumber = item.orderId ? (this._orderIdToRefMap[item.orderId] as string) : null;

            return item;
          });
        },
      })
    );
  }
}
