import { SuperMasterSelectorComponent } from '@aca-new/app/shared/components/data-table/shared/components/super-master-selector/super-master-selector.component';
import { TrackByUuidDirective } from '@aca-new/app/shared/directives/track-by-uuid.directive';
import { StartCasePipe } from '@aca-new/app/shared/pipes/start-case/start-case.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaInputSelectModule, QimaMenuItemTextModule, QimaMenuModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [SuperMasterSelectorComponent, StartCasePipe],
  imports: [QimaInputSelectModule, CommonModule, QimaMenuItemTextModule, QimaMenuModule, TranslateModule, FormsModule, TrackByUuidDirective],
  exports: [SuperMasterSelectorComponent],
})
export class SuperMasterSelectorModule {}
