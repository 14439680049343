import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EQimaPosition } from '@qima/ngx-qima';

@Component({
  selector: 'app-book-now',
  templateUrl: './book-now.component.html',
  styleUrls: ['./book-now.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookNowComponent {
  /**
   * @description
   * Check or not
   * @type {boolean}
   * @default false
   */
  @Input('isBookNowChecked')
  public isChecked: boolean = false;

  /**
   * @description
   * Collapse or not
   * @type {boolean}
   * @default false
   */
  @Input('isBookNowCollapsed')
  public isCollapsed: boolean = false;

  @Output('bookNowClick')
  public readonly bookNowClick: EventEmitter<MouseEvent | Event> = new EventEmitter<MouseEvent | Event>();

  public tooltipPosition: EQimaPosition = EQimaPosition.RIGHT;

  public constructor(public readonly qimaIcon: QimaIconService) {}

  public onClick(event: Readonly<MouseEvent>): void {
    this._emitClick(event);
  }

  public onEnter(event: Readonly<Event>): void {
    this._emitClick(event);
  }

  private _emitClick(event: Readonly<MouseEvent | Event>): void {
    this.bookNowClick.emit(event);
  }
}
