import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyContentComponent {
  /**
   * @description
   * Image
   * @type {string}
   * @default IMAGES.emptyContentTable
   */
  @Input('emptyContentImage')
  public image: string = IMAGES.emptyContentTable;

  /**
   * @description
   * Message
   * @type {string}
   * @default ''
   */
  @Input('emptyContentMessage')
  public message: string = '';
}
