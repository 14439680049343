import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';

/**
 * @description
 * Used to encrypt the session storage on prod environment
 * @param {IMyWindow} window The window object containing possibly the "SHOULD_SESSION_STORAGE_ENCRYPT" variable
 * @returns {boolean} Return true if it is prod environment or false otherwise
 */
export function shouldSessionStorageEncryptFactory(window: IMyWindow): boolean {
  return window.shouldSessionStorageEncrypt ?? false;
}
