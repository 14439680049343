/**
 * @description
 * instead , with &
 * @param {string} string if the given value is true
 * @returns {string} Return string with &
 */
export function commaToAndFunction(string: string): string {
  if (string) {
    return string.split(',').join('&');
  }

  return string;
}
