import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { ESize } from '@aca-new/app/shared/models/enums/size.enum';
import { AppFormService } from '@aca-new/app/shared/services/exported-services/app-form/app-form.service';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlsOf, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent<T1, T2 extends Record<keyof T2, unknown>> {
  /**
   * @description
   * The form group has distance or not
   * @type {boolean}
   * @default true
   */
  @Input('formGroupHasDistance')
  public hasDistance: boolean = true;

  /**
   * @description
   * The size of form group
   * @type {ESize}
   * @default {@link ESize.NONE}
   */
  @Input('formGroupSize')
  public size: ESize = ESize.NONE;

  /**
   * @description
   * The placement of form label and form input
   * @type {EOrientation}
   * @default {@link EOrientation.HORIZONTAL}
   */
  @Input('formGroupOrientation')
  public orientation: EOrientation = EOrientation.HORIZONTAL;

  /**
   * @description
   * The width of the label
   * @type {number | 'auto'}
   * @default 'auto'
   */
  @Input('formGroupLabelWidth')
  public labelWidth: number | string = 'auto';

  /**
   * @description
   * The width of the input
   * @type {number | 'auto'}
   * @default 'auto'
   */
  @Input('formGroupInputWidth')
  public inputWidth: number | string = 'auto';

  /**
   * @description
   * The validation messages
   * @type {QimaOptionalType<Record<string, string>>}
   * @default undefined
   */
  @Input('formGroupValidationMessages')
  public validationMessages: QimaOptionalType<Record<string, string>> = undefined;

  /**
   * @description
   * The control of form item
   * @type {QimaOptionalType<FormControl>}
   * @default undefined
   */
  @Input('formGroupControl')
  public control: QimaOptionalType<FormControl<T1>> = undefined;

  /**
   * @description
   * The form group which contains this control
   * @type {QimaOptionalType<FormGroup<ControlsOf>>}
   * @default undefined
   */
  @Input('formGroupFormGroup')
  public formGroup: QimaOptionalType<FormGroup<ControlsOf<T2>>> = undefined;

  public constructor(public readonly appForm: AppFormService) {}
}
