import { EFormItemVerticalAlign } from '@aca-new/app/shared/components/form-item/shared/models/enums/form-item-vertical-align.enum';
import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { ESize } from '@aca-new/app/shared/models/enums/size.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppFormService {
  public readonly formItemInputSize = ESize;
  public readonly formItemOrientation = EOrientation;
  public readonly formItemVerticalAlign = EFormItemVerticalAlign;
}
