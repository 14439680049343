import { WindowRef } from '@aca-new/app/shared/classes/window-ref.class';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserWindowRef extends WindowRef {
  public get nativeWindow(): Window | Record<string, void> {
    // eslint-disable-next-line no-restricted-globals
    return window;
  }
}
