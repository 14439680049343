import { FormGroupModule } from '@aca-new/app/shared/components/form-group/form-group.module';
import { FormItemComponent } from '@aca-new/app/shared/components/form-item/form-item.component';
import { IFormItemConfig } from '@aca-new/app/shared/components/form-item/shared/interfaces/form-item-config.interface';
import { EFormItemVerticalAlign } from '@aca-new/app/shared/components/form-item/shared/models/enums/form-item-vertical-align.enum';
import { APP_FORM_ITEM_CONFIG_TOKEN } from '@aca-new/app/shared/components/form-item/shared/tokens/form-item-config.token';
import { FormLabelModule } from '@aca-new/app/shared/components/form-label/form-label.module';
import { EOrientation } from '@aca-new/app/shared/models/enums/orientation.enum';
import { ESize } from '@aca-new/app/shared/models/enums/size.enum';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FormItemComponent],
  exports: [FormItemComponent],
  imports: [CommonModule, FormGroupModule, FormLabelModule, TranslateModule],
})
export class FormItemModule {
  public static forRoot(config: Readonly<Partial<IFormItemConfig>>): ModuleWithProviders<FormItemModule> {
    return {
      ngModule: FormItemModule,
      providers: [
        {
          provide: APP_FORM_ITEM_CONFIG_TOKEN,
          useFactory(): IFormItemConfig {
            /**
             * The fallback values should be identical as the default [token]{@link APP_FORM_ITEM_CONFIG_TOKEN} values
             */
            return {
              inputSize: config.inputSize ?? ESize.NONE,
              inputWidth: config.inputWidth ?? 'auto',
              labelWidth: config.labelWidth ?? 'auto',
              labelSize: config.labelSize ?? ESize.NONE,
              verticalAlign: config.verticalAlign ?? EFormItemVerticalAlign.BASELINE,
              orientation: config.orientation ?? EOrientation.HORIZONTAL,
            };
          },
        },
      ],
    };
  }
}
