import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DIALOG_DATA } from '@aca-new/app/shared/components/modal/shared/tokens/dialog.token';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentSuccessComponent {
  public constructor(
    private readonly _dialogRef: DialogRef<void>,
    public readonly qimaButton: QimaButtonService,
    private readonly _router: Router,
    @Inject(DIALOG_DATA) public readonly ids: string
  ) {}

  public close(): void {
    this._dialogRef.close();

    if (!this._router.url.includes('/aca/')) {
      void this._router.navigate(['/payments/paid']);
    } else {
      // no need to navigate
    }
  }
}
