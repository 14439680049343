import { IDraft } from '@aca-new/app/pages/book/shared/models/interfaces/draft.interface';
import { IPreviousOrders } from '@aca-new/app/pages/bookings/shared/models/interfaces/bookings.interface';
import { SuperMasterSelectorService } from '@aca-new/app/shared/components/data-table/shared/components/super-master-selector/shared/service/super-master-selector.service';
import { IDataTableQueryParams } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-query-params.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { ITableResponse } from '@aca-new/app/shared/models/interfaces/table-response.interface';
import { IUrl } from '@aca-new/app/shared/models/interfaces/url.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BookingsService {
  public constructor(
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService,
    private readonly _superMasterSelectorService: SuperMasterSelectorService
  ) {}

  public getPreviousOrders$(url: string): Observable<IPreviousOrders[]> {
    return this._httpService.httpClient
      .get<IHttpResponseBody<ITableResponse<IPreviousOrders[]>>>(url.replace('{userId}', this._storageService.getItem(EStorageKeys.USER_ID) as string), {
        observe: 'response',
      })
      .pipe(
        map((response: Readonly<HttpResponse<IHttpResponseBody<ITableResponse<IPreviousOrders[]>>>>): IPreviousOrders[] => {
          return response?.body?.content?.pageItems || [];
        })
      );
  }

  public createDraftFromPreviousOrder$(orderId: string, serviceType: string = 'psi'): Observable<QimaNullableType<IDraft>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<QimaNullableType<IDraft>>(`${SERVER_URL}/user/${userId}/draft/previous-psi-order/${orderId}?service-type=${serviceType}`, {
        observe: 'body',
      })
      .pipe(map((response: QimaNullableType<IDraft>): QimaNullableType<IDraft> => response));
  }

  public getParamsUrl(urlObj: IUrl, params: IDataTableQueryParams, superMasterUserId: string): string {
    const searchParams = new URLSearchParams(urlObj.params);

    searchParams.set('keyword', params.keyword.trim());
    searchParams.set('page', params.page.toString());
    searchParams.set('page-size', params.pageSize.toString());
    searchParams.set('sortType', params.sortType ?? '');
    searchParams.set('sortField', params.sortField ?? '');

    return this._superMasterSelectorService.transformUrl(urlObj.url + searchParams.toString(), superMasterUserId);
  }
}
