import { FormGroupComponent } from '@aca-new/app/shared/components/form-group/form-group.component';
import { FormValidationMessageModule } from '@aca-new/app/shared/components/form-validation-message/form-validation-message.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [FormGroupComponent],
  exports: [FormGroupComponent],
  imports: [FormValidationMessageModule, CommonModule],
})
export class FormGroupModule {}
