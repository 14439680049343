import { CurrencyTableCellComponent } from '@aca-new/app/shared/components/currency-table-cell/currency-table-cell.component';
import { CurrencyViewModule } from '@aca-new/app/shared/components/currency-view/currency-view.module';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CurrencyTableCellComponent],
  imports: [CurrencyViewModule],
  exports: [CurrencyTableCellComponent],
})
export class CurrencyTableCellModule {}
