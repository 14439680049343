import { OrderFeeService } from '@aca-new/app/pages/payments/pages/payments-content/shared/services/order-fee/order-fee.service';
import { PaymentsService } from '@aca-new/app/pages/payments/shared/services/payments.service';
import { CONDITIONS_URL } from '@aca-new/app/shared/constants/app.constants';
import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-payment-dialog-footer',
  templateUrl: './payment-dialog-footer.component.html',
  styleUrls: ['./payment-dialog-footer.component.scss'],
  providers: [OrderFeeService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDialogFooterComponent {
  /**
   * @description
   * Order ids
   * @type {string[]}
   * @default []
   */
  @Input('paymentDialogFooterOrderIds')
  public orderIds: string[] = [];

  @Output('paymentDialogFooterPayPalClick')
  public readonly payPalClick: EventEmitter<void> = new EventEmitter<void>();

  public readonly visaSecureCode: string = IMAGES.visaSecureCode;
  public isAlipayEnabled: boolean = false;
  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _paymentsService: PaymentsService
  ) {}

  public onTermsAndConditionsClick(): void {
    this._window.open(CONDITIONS_URL, '_blank');
  }

  public onPaypalClick(): void {
    if (this.orderIds.length) {
      this._paymentsService.payByPaypal(this.orderIds.join('&'), null);
      this.payPalClick.emit();
    }
  }
}
